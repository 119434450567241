export default{
    "lang": "English",
    "home": {
        "main_explain": "Leading The World Through Digital Technology, Creating Value With Industry-Targeted Services Accelerate Digital Transformation For Each Our Clients Through Leading-Edge Ideas And Nimble Execution.",
        "contact_btn": "Get in touch",
        "read_me_btn": "Read more",
        "second_tit": "Empowering The Digital Age Creating Value Together",
        "second_main": "Imagine a world in which language and cultural barriers no longer exist – where understanding is universal and everyone is empowered.",
        "second_expand": "We will help you serve the members of your community at their moment of need, regardless of language, culture, or ability.",
        "second_learn_more": "Contact us to learn more",
        "second_main_tips_one": "Localizing, translating content",
        "second_main_tips_two": "Managing translation projects",
        "second_main_tips_three": "Improving translation quality",
        "second_main_tips_four": "Boosting team productivity",
        "second_main_tips_five": "Powering experience by AI",
        "second_main_tips_six": "Localizing apps and software",
        "third_tit": "Excel in the global marketplace with our translation services",
        "third_main": "We enable our clients to reach new markets globally by connecting with their audiences and providing the best possible customer experience—in any language. We’re a partner that can take you from planning to execution through our services, technology, consulting, and industry expertise.",
        "third_btn": "Contact us to learn more",
        "four_tit": "How can we help you be successful?",
        "four_main": "Our tailor-made solutions help you reach these new markets quickly, efficiently, effectively, and in a way that maximizes gains for your business.",
        "four_main_one_tit": "LOCALIZING, TRANSLATING CONTENT",
        "four_main_one_content": "Scale your global content and maximize its local impact with help from our intelligent technologies.",
        "four_main_two_tit": "MANAGING TRANSLATION PROJECTS",
        "four_main_two_content": "Easily scale, streamline and automate localization process with translation management platform.",
        "four_main_three_tit": "IMPROVING TRANSLATION QUALITY",
        "four_main_three_content": "Protect brand reputation with linguistic checks that guarantee content is clear and error-free.",
        "four_main_four_tit": "LOCALIZING APPS AND SOFTWARE",
        "four_main_four_content": "Make sure products are perfectly adapted and flawlessly presented to every market with our complete range of localization services.",
        "four_main_five_tit": "BOOSTING TEAM PRODUCTIVITY",
        "four_main_five_content": "Give translators and project managers the tools to work smarter with the most trusted computer-assisted translation (CAT) software.",
        "four_main_six_tit": "POWERING EXPERIENCE BY AI",
        "four_main_six_content": "Answer customer questions at a fraction of the cost and fast enough to turn into relevant and timely actions with artificial intelligence.",
        "five_tit": "Explore enterprise services today.",
        "five_main": "Let's achieve your most ambitious goals - together.",
        "six_tit": "Who is using EMLIONS?",
        "six_main": "Content and data transformation solutions at global scale recognized by customers, translators, and industry organizations. ",
        "six_main_find": "Find out more."
    },
    "contact_btn": "Contact Us",
    "translation": {
        "nav": "Translation",
        "menu_doc": "Document Translation",
        "menu_localization": "Localization Services",
        "menu_tools": "Integrated Translation Tools",
        "menu_machine": "Machine Translation",
        "menu_elearning": "eLearning Services",
        "menu_website": "Website Translation",
        "menu_languages": "Translation Languages"
    },
    "other_language_services": {
        "nav": "Other Language Services",
        "menu_clarification": "Document Clarification",
        "menu_simplification": "Document Simplification",
        "menu_audio": "Audio Transcription",
        "menu_alternative": "Alternative Format Transcription"
    },
    "about": {
        "title_fir": "About Us",
        "title_sed": "IT service provider",
        "title_explain_fir": "A trusted partner of 157 Fortune 500 clients.",
        "title_explain_sec": "Our strength is our people, and we are more than just a translation company. We're a family of committed individuals dedicated to being the world's leading enabler of global communications.",
        "title_num_one": "Fortune 500 clients",
        "title_num_two": "years of experience",
        "title_num_three": "profession translators",
        "second_title": "We bring you powerful advantages to navigate your digital transformation",
        "second_one_tit": "Global business layout and operation service capabilities",
        "second_one_main": "EMLIONS is home 3,500 professionals worldwide, distributed in 50 delivery centers in 28 cities. We are committed to providing offerings in over 200 languages, 7x24 technical support, and service delivery to clients worldwide.",
        "second_two_tit": "Accelerating innovation through a wealth of industry experience",
        "second_two_main": "With 25 years of industry experience, Emlions provides clients worldwide with services covering digital consulting, experience interaction, technology implementation, and operations. By leveraging rich experience in sectors including high-tech, finance,manufacturing, telecom, and internet, Emlions has established long-term cooperative relationships with 157 Fortune 500 companies.",
        "second_three_tit": "Fueling quality upgrades with proven security and quality management",
        "second_three_main": "Since Emlions passed ISO 9001 quality management system standards in 2000, we have also successively passed CMMI L5, ISO 20000, and ISO 27001 certifications. We always meet and exceed client expectations in terms of the security and quality of facilities,personnel, and procedures with quality control systems that reach international standards.",
        "second_four_tit": "Empowering the digital age and creating value for clients",
        "second_four_main": "Emlions is dedicated to innovating client experience-oriented products and services, as well as integrated digital marketing to accelerate the deployment of digital capabilities, and to providing clients with superior digital services and helping them navigate the digital transformation by integrating strategic consulting, creativity, and experience design, as well as through technical means such as mobile, IoT, cloud computing, and big data."
    },
    "solutions": {
        "nav": "SOLUTIONS",
        "tit_fir": "Industry",
        "tit_sec": "Solutions",
        "main_fir": "As expert communicators, we know that context is everything. That’s why we have dedicated teams with deep experience in almost every industry sector.",
        "main_sec": "This tailored expertise gives you peace of mind that your global content is both effective and compliant with specific formatting, terminology and regulatory requirements..",
        "get_in_touch": "Get in touch",
        "read_more": "Read more",
        "sec_tit": "How can we help you be successful?",
        "sec_main": "Our tailor-made solutions help you reach these new markets quickly, efficiently, effectively, and in a way that maximizes gains for your business.",
        "learn_more": "Learn more",
        "sec_one_tit": "Life Sciences",
        "sec_one_main": "Gain greater process efficiency, reduce translation costs, and speed up go-to-market with our specialized language, COA and content solutions across life sciences, medical research, and contract research organizations (CROs).",
        "sec_two_tit": "Legal",
        "sec_two_main": "We can help you solve multilingual content challenges across all legal practices so you can keep pace with a rapidly expanding translation workload, whether you are managing e-discovery data, mergers and acquisitions (M&amp;A) fillings, IP documents, contracts and agreements,marketing materials and more.",
        "sec_three_tit": "Government Solutions",
        "sec_three_main": "Meet government requirements for multilingual content with our professional language and content management solutions for public sector, intelligence, defense and law enforcement agencies.",
        "sec_four_tit": "Financial Services",
        "sec_four_main": "Our content and language solutions support the specific needs of financial service organizations so you can create the best possible relationships with your customers in any language, globally.",
        "sec_five_tit": "Managed Care",
        "sec_five_main": "Confidently communicate across both language and ability barriers. Our solutions and expertise help you meet your translation and compliance needs year-round, not just during Annual Enrollment Period (AEP).",
        "sec_six_tit": "Aerospace and Defense",
        "sec_six_main": "Meet the rigorous demands of both commercial aircraft and defense equipment, and enhance the productivity and efficiency throughout the product lifecycle—including post-delivery sustainment—with our publishing suite for S1000D.",
        "sec_sevent_tit": "Industrial Manufacturing",
        "sec_sevent_main": "Our comprehensive language and content solutions can help you meet the needs of the entire value chain by adopting mature agile software practices, while lowering costs and increasing efficiency.",
        "sec_eight_tit": "High-Tech Software",
        "sec_eight_main": "Respond to market demands and increased product velocity with our specialized language and content solutions for enterprise software and hardware, IT services, social media, games, online applications, multimedia, productivity applications, cloud computing, and mobile devices.",
        "sec_nine_tit": "Retail and eCommerce",
        "sec_nine_main": "Emlions language services and technologies set retailers up for global success with eCommerce localization strategies that cater to the needs of your target audience.",
        "sec_ten_tit": "Travel, Leisure and Hospitality",
        "sec_ten_main": "Our end-to-end multilingual content solutions for suppliers, distributors, and retailers alike, will help you create, translate, and deliver large databases of inventory content, while lowering costs, maintaining consumer satisfaction, and complying with consumer data protection regulations.",
        "sec_elevent_tit": "Automotive",
        "sec_elevent_main": "Manage your multilingual marketing, product, and service content across all digital channels, for prospects, customers, the connected vehicle, and a globally distributed aftersales organization.",
        "sec_twelve_tit": "Chemical Globalization Services",
        "sec_twelve_main": "Our chemical industry clients trust us to provide translation, interpreting and IP services through highly specialized linguists and technical experts with industry expertise and awareness of international standards and regulations.",
        "sec_Thirteen_tit": "Multilingual Translation in Energy Sector",
        "sec_Thirteen_main": "Businesses in the energy industry are faced with accelerating global demand and new players in increasingly competitive markets. Global IP support and effective, consistent multilingual communication are key.",
        "sec_fourteen_tit": "Insurance Translation Services",
        "sec_fourteen_main": "Our subject-matter expertise guarantees that information yields results. We use only specialist translators and interpreters, many of whom are former practitioners, brokers, underwriters, risk managers and members of the Chartered Institute of Insurance.",
        "sec_fifteen_tit": "Media Localization Services",
        "sec_fifteen_main": "At Emlions, our comprehensive approach to global content,localization and technology helps you stay relevant in the digital world.",
        "sec_sixteen_tit": "Telecom Localization Services",
        "sec_sixteen_main": "Today’s highly competitive telecom landscape is pushing companies to expand not just the scale and scope of their offerings, but also their global reach."
    },
    "doc_translation": {
        "tit": "DOCUMENT TRANSLATION",
        "main": "Build Stronger Customer Relationships in 290+ Languages",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Let Our Experts Solve All Your Translation Needs",
        "sec_one": "Your translation projects aren’t limited to one language, channel, or timeline. Get the expertise you need to communicate clearly at any scale and transform your language challenges into opportunities—with creative solutions that improve efficiency and foster meaningful relationships.",
        "sec_two": "Our 3,500+ expert linguists provide high-quality, high-touch translations customized for your intended use and audience. They also put every project through rigorous translation, copyediting, and proofreading to ensure the final deliverable exceeds your standards.",
        "trird_one": "Expert linguists on staff",
        "third_two": "Languages available for translation",
        "third_three": "Content quality assured",
        "four_tit": "Translate Any Type of Content in 290+ Languages",
        "four_one_tit": "Document Translation",
        "four_one_contents": "Letters, forms, emails, technical manuals, online content, CMS output, marketing collateral, and HR policies and procedures.",
        "four_two_tit": "Websites",
        "four_two_contents": "HTML, ASP, Scripts, Java-based apps, and database-driven interfaces.",
        "four_three_tit": "Multimedia",
        "four_three_contents": "Flash engineering, voiceover, and impact movies.",
        "four_four_tit": "Training Materials",
        "four_four_contents": "Tutorials, templates, eLearning applications, and ERP.",
        "four_five_tit": "Software",
        "four_five_contents": "GUI, database content, wireless devices, web applications, and business process systems.",
        "four_six_tit": "Help and Content Management Systems",
        "four_six_contents": "WinHelp, HTML Help, WebHelp, JavaHelp, Oracle Help, FlashHelp, AppleHelp, HubSpot, WordPress, Drupal, and proprietary systems.",
        "five_tit": "Translation Across Industries",
        "five_one_tit": "Business:",
        "five_one_contents": "Product information, customer communication, and employee materials",
        "five_two_tit": "Healthcare:",
        "five_two_contents": "Vital documents, discharge instructions, notices of eligibility, and patient forms",
        "five_three_tit": "Financial Services:",
        "five_three_contents": "Loan documents, contracts, mortgage papers, financial applications, account statements, and credit reports",
        "five_five_tit": "Insurance:",
        "five_five_contents": "Claims forms, policy information, and accident reports",
        "five_six_tit": "Government:",
        "five_six_contents": "Notices of rights, consent forms, complaints, letters requiring response, applications, and public outreach",
        "six_tit": "The EMLions Translation Difference",
        "six_one_tit": "Human Translators",
        "six_one_contents": "We use a human touch and cultural understanding to ensure every job conveys the true meaning of your original content.",
        "six_two_tit": "Customization",
        "six_two_contents": "We adapt to and tailor each project by considering your needs, deadlines, and workflows.",
        "six_three_tit": "100% Quality Assurance",
        "six_three_contents": "Each project is translated, copyedited, and proofread by at least two professional linguists.",
        "six_four_tit": "On-Time Delivery",
        "six_four_contents": "Our on-time delivery rate for document translation is 99.5%.",
        "sevent_tit": "Find Out More About Document Translation Services",
        "sevent_contents": "Learn how to get help delivering consistent, high-quality content in any language. You can read our Translation and Localization brochure or request a quote from our experts.",
        "download_btn": "DOWNLOAD NOW"
    },
    "local_services": {
        "tit": "LOCALIZATION SERVICES",
        "main": "Build Stronger Relationships in 290+ Languages",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Increase Localization Quality and Reduce Costs",
        "sec_one": "There’s no limit to how many people your digital content can reach, but most customers prefer to buy products that communicate in their language. Ensure you deliver your message consistently worldwide by working with a partner with the expertise and scale to handle all your content needs and projects of any scale.",
        "sec_two": "Our Translation & Localization services are handled by 3,500+ expert linguists ready to produce consistent, high-quality deliverables in more than 290 languages. We’ll localize your print or digital content for a specific  locale’s language and cultural sensitivities so that it’s a natural experience for your target audience. Plus, to ensure successful on-time delivery, we embrace comprehensive, end-to-end quality-control procedures for every project.",
        "third_one": "Expert linguists on staff",
        "third_two": "Languages available for translation",
        "third_three": "Translation accuracy rate",
        "four_tit": "LOCALIZATION SERVICES",
        "four_main": "Better Serve Your Customers, Patients, and Constituents",
        "four_main_exp": "Our professionals can localize any type of print or digital content, including:",
        "four_main_exp_one": "Websites, apps, and software",
        "four_main_exp_two": "Videos content, subtitles, graphics, and voiceover",
        "four_main_exp_three": "Documents and marketing materials",
        "four_main_exp_four": "eLearning and team member training",
        "four_main_exp_five": "Regulatory and compliance documentation",
        "four_main_exp_six": "Video games and more",
        "five_tit": "CMS CONNECTORS",
        "five_main": "Automate Your file transfers",
        "five_main_exp": "EMLions has reimagined translation from our clients’ perspective, embedding multilingual automation directly in content systems and business applications enabling our clients to easily send any content to us. Choose from any of our 50+ out-of-the-box connectors to increase your file-transferring efficiency and publishing workflows.",
        "five_btn": "LEARN MORE",
        "six_tit": "WEBSITE PROXY",
        "six_main": "Easily Translate Your Website",
        "six_main_exp": "Streamline the process of producing and managing translated versions of your English website or eCommerce platform. With EMLions® Website Proxy, translated versions of your site are always in sync with the English version. Plus, if you lack the resources to host a translated site, Website Proxy helps you mirror your content and display it in your language of choice.",
        "six_btn": "LEARN MORE",
        "sevent_tit": "Find Out More About Integrated Translation Tools",
        "sevent_main": "Deliver consistent, high-quality content in any language without sacrificing accuracy. You can read our Translation & Localization brochure or choose a time to speak with our experts about how to solve your translation challenges.",
        "sevent_download_btn": "DOWNLOAD NOW",
        "sevent_schedule_btn": "SCHEDULE CONSULTATION",
        "eigth_tit": "EMLions Localization Services",
        "eigth_one_tit": "HUMAN TRANSLATORS",
        "eigth_one_contents": "We use a human touch and cultural understanding to ensure every job conveys the meaning of your original content.",
        "eigth_two_tit": "CUSTOMIZATION",
        "eigth_two_contents": "We provide cutting-edge technology and linguistic expertise to adapt to your evolving project needs.",
        "eigth_three_tit": "100% QUALITY ASSURANCE",
        "eigth_three_contents": "Each project is translated, copyedited, and proofread by at least two linguists.",
        "eigth_four_tit": "ON-TIME DELIVERY",
        "eigth_four_contents": "We have never missed a go-live date."
    },
    "integrated_translation": {
        "tit": "INTEGRATED TRANSLATION TOOLS",
        "main": "Accelerate Translation Without Sacrificing Accuracy",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "sec_tit": "Put Translation Technology at Your Fingertips",
        "sec_one": "Whether you’re expanding your marketing programs around the globe, translating confidential patient information, or offering educational materials in new languages, you need high-quality translations that fit your need and budget. Using the right translation technology can streamline many of the steps of the process, enabling you to produce accurate materials on a shorter timeline and at a lower cost.",
        "sec_two": "We offer translation technology that makes the process as straightforward, easy, and secure as possible. Use our end-to-end encrypted EMLions® Translation Portal to quickly submit projects for our 3,500+ expert linguists. You can also download your translations, see project status, and access reporting online. The result is accurate, consistent content for your audience, and peace of mind for you and your staff.",
        "third_one": "Languages available for translation",
        "third_two": "Translation accuracy rate",
        "third_three": "On-time delivery for translation projects",
        "four_tit": "CMS CONNECTORS",
        "four_main": "Automate Your file transfers",
        "four_main_exp": "EMLions has reimagined translation from our clients’ perspective, embedding multilingual automation directly in content systems and business applications enabling our clients to easily send any content to us. Choose from any of our 50+ out-of-the-box connectors to increase your file-transferring efficiency and publishing workflows.",
        "four_btn": "LEARN MORE",
        "five_tit": "WEBSITE PROXY",
        "five_main": "Easily Translate Your Website",
        "five_main_exp": "Streamline the process of producing and managing translated versions of your English website or eCommerce platform. With EMLions® Website Proxy, translated versions of your site are always in sync with the English version. Plus, if you lack the resources to host a translated site, Website Proxy helps you mirror your content and display it in your language of choice.",
        "five_btn": "LEARN MORE",
        "six_tit": "MACHINE TRANSLATION",
        "six_main": "Get Translations in Minutes",
        "six_main_exp": "Translate large-scale content as quickly as possible. With EMLions® Machine Translation, our AI-driven “best fit” technology provides easy to understand content in no time. And when you combine your content with our ISO-certified post-editing process, you can rest assured all your communications and deliverables will be accurate.",
        "six_btn": "LEARN MORE",
        "sevent_tit": "Find Out More About Integrated Translation Tools",
        "sevent_main": "Deliver consistent, high-quality content in any language without sacrificing accuracy. You can read our Translation & Localization brochure or choose a time to speak with our experts about how to solve your translation challenges.",
        "sevent_download_btn": "DOWNLOAD NOW",
        "sevent_schedule_btn": "SCHEDULE CONSULTATION",
        "eigth_tit": "Integrated Translation Tools",
        "eight_one_tit": "ADVANCED TECHNOLOGY",
        "eigth_one_contents": "We lead the industry by engineering new localization solutions.",
        "eight_two_tit": "CUSTOMIZATION",
        "eigth_two_contents": "We provide cutting-edge technology and linguistic expertise to adapt to your evolving project needs.",
        "eight_three_tit": "100% QUALITY ASSURANCE",
        "eigth_three_contents": "Our integrated team of industry specialists ensures the highest quality results.",
        "eight_four_tit": "ON-TIME DELIVERY",
        "eigth_four_contents": "Our on-time delivery rate is 99.5%."
    },
    "machine_translation": {
        "tit": "MACHINE TRANSLATION",
        "main": "Get Your Content Translated In Seconds",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Save Time and Money by Automating Translations",
        "sec_one": "Gain total control over the timeline and cost of your translation projects. Machine translation uses specialized engines to translate content instantly. For the fastest possible results, you can fully automate the process or follow a hybrid workflow in which one of our 3,500+ professional linguists supports your project.",
        "sec_two": "Our Machine Translation uses artificial intelligence to increase the accuracy of your translations. You can customize your post-editing and review processes based on your project needs, budget, and timeline.",
        "third_one": "Languages available for translation",
        "third_two": "AI-driven “best fit” engines",
        "third_three": "Linguists available for post-editing",
        "four_tit": "QUICK-TURN TRANSLATION",
        "four_main": "Is Machine Translation Right for You?",
        "four_main_exp": "Machine translation works best for translating large amounts of content on a tight timeline. It’s ideal for low-liability risk content, such as customer service communications and blog articles. If you’re working with legal, medical, or scientific documents, or content that requires deeper sentiment analysis, you’ll get better results from traditional translation services.",
        "five_tit": "TECHNOLOGY",
        "five_main": "Increase Accuracy with Powerful Technology",
        "five_main_exp": "EMLions® Machine Translation uses more than 40 leading engines to maximize accuracy. It automatically seeks the best options for your languages and content type to ensure the best output for your message. Using our hybrid workflow along with your company's translation memory and glossaries, we can increase the accuracy and help you control costs.",
        "six_tit": "EMLions Machine Translation Tools",
        "six_one_tit": "ADVANCED TECHNOLOGY",
        "six_one_main": "Our AI-based translation hub offers best-fit machine translation to your content.",
        "six_two_tit": "CUSTOMIZATION",
        "six_two_main": "Select the machine translation workflow that best fits your content need.",
        "six_three_tit": "SECURITY",
        "six_three_main": "Your valuable and proprietary content remains within our secure environment.",
        "six_four_tit": "QUICK TURNAROUND TIME",
        "six_four_main": "Complete your projects instantly or faster with our MTPE workflow.",
        "sevent_tit": "Find Out More About Translation and Localization",
        "sevent_main": "Learn more about delivering consistent, high-quality content in any language and for any culture. You can read our Translation & Localization brochure or choose a time to speak with our experts about solving your translation challenges.",
        "sevent_download_btn": "DOWNLOAD NOW",
        "sevent_schedule_btn": "SCHEDULE CONSULTATION"
    },
    "elearning_translation": {
        "tit": "ELEARNING SERVICES",
        "main": "Deliver eLearning Content in More than 290 Languages",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Boost the Return for Your Training Investment",
        "sec_one": "Organizations worldwide are embracing eLearning as an efficient and cost-effective way to deliver educational and training content to their dispersed workforce and customers. However, companies that fail to translate and localize their eLearning content correctly may limit the effectiveness of their training and its ROI.",
        "sec_two": "Prepare your eLearning programs for a global audience by working with our 3,500+ expert linguists. You can count on consistent, high-quality deliverables. Plus, access our end-to-end encrypted portal to upload materials, track progress, and retrieve translations—ensuring efficiency and total security on every project.",
        "third_one": "Expert linguists on staff",
        "third_two": "Languages available for translation",
        "third_three": "Translation accuracy rate",
        "four_tit": "ELEARNING SERVICES",
        "four_main": "Count on 100% Content Quality Assured",
        "four_main_exp": "As the industry leader in language services, we manage thousands of translation and localization projects each year in 290+ languages. Our decades of experience supporting remote training content have cultivated our expertise in devising highly effective and accessible eLearning programs. Our content quality is 100% assured, and 99.5% of our clients rate our translation and localization services as, “Good or better”.",
        "five_tit": "TECHNOLOGY",
        "five_main": "eLearning Applications and Support",
        "five_main_exp": "93% of Fortune 100 companies count on us to translate and localize specialized content—including eLearning courses, training materials, webinars, eBooks, onboarding, and systems and product training. Plus, our expert team specializes in ERP training for SAP, Oracle, Salesforce, and other platforms.",
        "six_tit": "GLOBAL EXPERTISE",
        "six_main": "Deploy Industry-Specific Training Worldwide",
        "six_main_exp": "We’ll ensure your organization’s unique vocabulary and industry-specific terminology are understood globally in 290+ languages. Our 3,500+ expert linguists have deep experience translating a wide range of eLearning content in industries, including:",
        "six_main_one": "Healthcare",
        "six_main_two": "IT and SaaS",
        "six_main_three": "Retail and eCommerce",
        "six_main_four": "Education",
        "six_main_five": "Financial Services",
        "six_main_six": "Pharmaceutical",
        "six_main_sevent": "Manufacturing",
        "six_main_eight": "Construction and more",
        "sevent_tit": "Find Out More About eLearning",
        "sevent_main": "Learn more about delivering consistent, high-quality eLearning content in 290+ languages. You can read our Translation & Localization brochure or choose a time to speak with our experts about how to solve your eLearning challenges.",
        "sevent_download_btn": "DOWNLOAD NOW",
        "sevent_schedule_btn": "SCHEDULE CONSULTATION",
        "eight_tit": "eLearning Services",
        "eigth_one_tit": "FULL SERVICE",
        "eigth_one_contents": "We reproduce your learning content end-to-end to activate your global audiences.",
        "eight_two_tit": "CUSTOMIZATION",
        "eigth_two_contents": "We provide cutting-edge technology and linguistic expertise to adapt to your evolving project needs.",
        "eight_three_tit": "100% QUALITY ASSURANCE",
        "eigth_three_contents": "Our integrated team of industry specialists ensures the highest quality results.",
        "eight_four_tit": "ON-TIME DELIVERY",
        "eigth_four_contents": "We have never missed a go-live date."
    },
    "website_translation": {
        "tit": "WEBSITE TRANSLATION",
        "main": "Localize Your Website for Global Audiences",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Translate Your Website in 290+ Languages",
        "sec_one": "Your website content is constantly evolving, and so is your consumers’ need to see it in the language they understand. Keeping your site updated with the latest content can be a daunting task in and of itself. When you also need to translate it into multiple languages, site maintenance can become a major challenge that impacts staff productivity and organizational profitability.",
        "sec_two": "With EMLions® Website Proxy you can:",
        "sec_three": "Translate documents, marketing materials, forms, communications, and how-to guides",
        "sec_four": "Localize websites, hardware, software, in-game content, and eLearning",
        "sec_five": "Automate translation and localization of your content",
        "third_one": "Expert linguists on staff",
        "third_two": "Translation accuracy rate",
        "third_three": "Content quality assured",
        "four_tit": "CMS CONNECTORS",
        "four_main": "Automate Your File Transfers",
        "four_main_exp": "EMLions has reimagined translation from our clients’ perspective, embedding multilingual automation directly in content systems and business applications enabling our clients to easily send any content to us. Choose from any of our 50+ out-of-the-box connectors to increase your file-transferring efficiency and publishing workflows.",
        "four_btn": "LEARN MORE",
        "five_tit": "MACHINE TRANSLATION",
        "five_main": "Is It Right for You?",
        "five_main_exp": "Machine translation works best for translating large amounts of content on a tight timeline. It’s ideal for low-liability risk content, such as customer service communications and blog articles. If you’re working with legal, medical, or scientific documents, or content that requires deeper sentiment analysis, you’ll get better results from traditional translation services.",
        "five_btn": "LEARN MORE",
        "six_tit": "EMLions Website Translation",
        "six_one_tit": "ADVANCED TECHNOLOGY",
        "six_one_contents": "We provide cutting-edge technology and linguistic expertise to adapt to your evolving website project needs.",
        "six_two_tit": "CUSTOMIZATION",
        "six_two_contents": "We provide cutting-edge technology and linguistic expertise to adapt to your evolving website project needs.",
        "six_three_tit": "100% QUALITY ASSURANCE",
        "six_three_contents": "Flexible approach to any website complexity and budget.",
        "six_four_tit": "ON-TIME DELIVERY",
        "six_four_contents": "Our integrated team of industry specialists ensures the highest quality results.",
        "sevent_tit": "Find Out More About Translation and Localization",
        "sevent_main": "Learn more about delivering consistent, high-quality website content in any language and for any culture. You can read our Translation & Localization brochure or choose a time to speak with our experts about solving your translation challenges."
    },
    "translation_languages": {
        "tit": "TRANSLATION LANGUAGES",
        "main": "290+ Translation And Localization Languages Available",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Find Out More About Integrated Translation Tools",
        "sec_main": "Deliver consistent, high-quality content in any language without sacrificing accuracy. You can read our Translation & Localization brochure or choose a time to speak with our experts about how to solve your translation challenges.",
        "sec_download_btn": "DOWNLOAD NOW",
        "lang": {
            "Achi": "Achi",
            "Achinese": "Achinese",
            "Acholi": "Acholi",
            "Afar": "Afar",
            "Afghani": "Afghani",
            "Afrikaans": "Afrikaans",
            "Akan": "Akan",
            "Akateko": "Akateko",
            "Albanian": "Albanian",
            "Amharic": "Amharic",
            "Anuak": "Anuak",
            "Apache": "Apache",
            "Arabic": "Arabic",
            "Arabic_Iraq": "Arabic (Iraq)",
            "Arabic_Juba": "Arabic (Juba)",
            "Arabic_Saudi_Arabia": "Arabic (Saudi Arabia)",
            "Arabic_Sudan": "Arabic (Sudan)",
            "Arabic_Yemen": "Arabic (Yemen)",
            "Arakanese": "Arakanese",
            "Armenian": "Armenian",
            "Assamese": "Assamese",
            "Assyrian": "Assyrian",
            "Azeri": "Azeri",
            "Azeri_Cyrillic": "Azeri (Cyrillic)",
            "Azeri_Latin": "Azeri (Latin)",
            "Bajan": "Bajan",
            "Bajuni": "Bajuni",
            "Bambara": "Bambara",
            "Basque": "Basque",
            "Bassa": "Bassa",
            "Behdini": "Behdini",
            "Beja": "Beja",
            "Belarusian": "Belarusian",
            "Belgian": "Belgian",
            "Bemba": "Bemba",
            "Bengali": "Bengali",
            "Berber": "Berber",
            "Bhojpuri": "Bhojpuri",
            "Bislama": "Bislama",
            "Blackfoot_Canada": "Blackfoot(Canada)",
            "Bosnian": "Bosnian",
            "Bosnian_Cyrillic": "Bosnian(Cyrillic)",
            "Bosnian_Latin": "Bosnian(Latin)",
            "Braille": "Braille",
            "Bulgarian": "Bulgarian",
            "Burmese": "Burmese",
            "Cambodian": "Cambodian",
            "Cambodian_Pidgin_English": "Cambodian Pidgin English",
            "Cantonese": "Cantonese",
            "Cape_Verdean_Creole": "Cape Verdean Creole",
            "Catalan": "Catalan",
            "Cebuano": "Cebuano",
            "Central_Alaskan_Yupik": "Central Alaskan Yupik",
            "Chaldean": "Chaldean",
            "Chamorro": "Chamorro",
            "Chavacano": "Chavacano",
            "Cherokee": "Cherokee",
            "Chichewa": "Chichewa",
            "Chin": "Chin",
            "Chinese_Simplified": "Chinese (Simplified)",
            "Chinese_Traditional": "Chinese (Traditional)",
            "Chinese_Traditional_HK": "Chinese (Traditional-Hong Kong)",
            "Choctaw": "Choctaw",
            "Chui_Chow": "Chui Chow",
            "Chuj": "Chuj",
            "Chuukese": "Chuukese",
            "Cree_Canada": "Cree (Canada)",
            "Croatian": "Croatian",
            "Crow": "Crow",
            "Czech": "Czech",
            "Dakota": "Dakota",
            "Danish": "Danish",
            "Dari": "Dari",
            "Dene": "Dene",
            "Dinka": "Dinka",
            "Dutch": "Dutch",
            "Dutch_Belgium": "Dutch (Belgium)",
            "Dyula": "Dyula",
            "Dzongkha": "Dzongkha",
            "Eastern_Mari": "Eastern Mari",
            "Edo": "Edo",
            "English_Australia": "English (Australia)",
            "English_Belize": "English (Belize)",
            "English_Canada": "English (Canada)",
            "English_Ireland": "English (Ireland)",
            "English_Jamaica": "English (Jamaica)",
            "English_Liberia": "English (Liberia)",
            "English_New_Zealand": "English (New Zealand)",
            "English_Trinidad_Tobago": "English (Trinidad & Tobago)",
            "English_UK": "English (UK)",
            "English_US": "English (US)",
            "English_Zimbabwe": "English (Zimbabwe)",
            "Estonian": "Estonian",
            "Ewe": "Ewe",
            "Falam": "Falam",
            "Fante": "Fante",
            "Farsi": "Farsi",
            "Fijian": "Fijian",
            "Filipino": "Filipino",
            "Finnish": "Finnish",
            "Flemish": "Flemish",
            "Formosan": "Formosan",
            "French_Belgium": "French (Belgium)",
            "French_Burkina_Faso": "French (Burkina Faso)",
            "French_Burundi": "French (Burundi)",
            "French_Cameroon": "French (Cameroon)",
            "French_Canada": "French (Canada)",
            "French_Congo": "French (Congo)",
            "French_France": "French (France)",
            "French_Guinea": "French (Guinea)",
            "French_Ivory_Coast": "French (Ivory Coast)",
            "French_Madagascar": "French (Madagascar)",
            "French_Morocco": "French (Morocco)",
            "French_Switzerland": "French (Switzerland)",
            "French_Tunisia": "French (Tunisia)",
            "French_West_Africa": "French West Africa",
            "Frisian": "Frisian",
            "Fukienese": "Fukienese",
            "Fula": "Fula",
            "Fulani": "Fulani",
            "Fulfulde": "Fulfulde",
            "Fuzhou": "Fuzhou",
            "Ga": "Ga",
            "Gaddang": "Gaddang",
            "Galician": "Galician",
            "Garifuna": "Garifuna",
            "Georgian": "Georgian",
            "German": "German",
            "German_Austria": "German (Austria)",
            "German_Belgium": "German (Belgium)",
            "German_Liechtenstein": "German (Liechtenstein)",
            "German_Pennsylvania": "German (Pennsylvania)",
            "German_Switzerland": "German (Switzerland)",
            "Greek": "Greek",
            "Guarani": "Guarani",
            "Gujarati": "Gujarati",
            "Guyanese": "Guyanese",
            "Haitian_Creole": "Haitian Creole",
            "Hakka": "Hakka",
            "Hakka_China": "Hakka (Guangdong)",
            "Hakka_Taiwan": "Hakka (Taiwan)",
            "Harari": "Harari",
            "Haryanvi": "Haryanvi",
            "Hausa": "Hausa",
            "Hawaiian": "Hawaiian",
            "Hebrew": "Hebrew",
            "Hindi": "Hindi",
            "Hmong": "Hmong",
            "Hokkien": "Hokkien",
            "Hopi": "Hopi",
            "Hunanese": "Hunanese",
            "Hungarian": "Hungarian",
            "Ibanag": "Ibanag",
            "Ibibio": "Ibibio",
            "Ibo": "Ibo",
            "Icelandic": "Icelandic",
            "Igbo": "Igbo",
            "Ilocano": "Ilocano",
            "Ilonggo": "Ilonggo",
            "Indonesian_Bahasa_Indonesia": "Indonesian (Bahasa Indonesia)",
            "Inuktitut": "Inuktitut",
            "Inupiak": "Inupiak",
            "Iraqi": "Iraqi",
            "Irish_Gaelic": "Irish Gaelic",
            "Italian": "Italian",
            "Italian_Switzerland": "Italian (Switzerland)",
            "Ixil": "Ixil",
            "Jakartanese": "Jakartanese",
            "Jamaican_Patois": "Jamaican Patois",
            "Japanese": "Japanese",
            "Jarai": "Jarai",
            "Javanese": "Javanese",
            "Jju": "Jju",
            "Kachin": "Kachin",
            "Kanjobal": "Kanjobal",
            "Kannada": "Kannada",
            "Kaqchikel": "Kaqchikel",
            "Karen": "Karen",
            "Karenni": "Karenni",
            "Karenni_Kayah_Li": "Karenni (Kayah Li)",
            "Karenni_Latin": "Karenni (Latin)",
            "Karenni_Myanmar": "Karenni (Myanmar)",
            "Kashmiri": "Kashmiri",
            "Kayah": "Kayah",
            "Kayah_Latin": "Kayah(Latin)",
            "Kayah_Myanmar": "Kayah (Myanmar)",
            "Kazakh": "Kazakh",
            "Kekchi": "Kekchi",
            "Khmer": "Khmer",
            "Kibembe": "Kibembe",
            "Kifulero": "Kifulero",
            "Kikuyu": "Kikuyu",
            "Kinyamulenge": "Kinyamulenge",
            "Kinyarwanda": "Kinyarwanda",
            "Kirundi": "Kirundi",
            "Kiswahili": "Kiswahili",
            "Kituba": "Kituba",
            "Kizigua": "Kizigua",
            "Konkani": "Konkani",
            "Korean": "Korean",
            "Kosovan": "Kosovan",
            "Kosraean": "Kosraean",
            "Kpelle": "Kpelle",
            "Krahn": "Krahn",
            "Krio": "Krio",
            "Kunama": "Kunama",
            "Kurdish": "Kurdish",
            "Kurdish_Arabic": "Kurdish (Arabic)",
            "Kurdish_Cyrillic": "Kurdish (Cyrillic)",
            "Kurdish_Latin": "Kurdish (Latin)",
            "Kurmanji": "Kurmanji",
            "Kwakwala": "Kwakwala",
            "Kwanyama": "Kwanyama",
            "Kyrgyz": "Kyrgyz",
            "Lai_Hakha": "Lai Hakha",
            "Lakota": "Lakota",
            "Lao": "Lao",
            "Latin": "Latin",
            "Latvian": "Latvian",
            "Lingala": "Lingala",
            "Lithuanian": "Lithuanian",
            "Luganda": "Luganda",
            "Luo": "Luo",
            "Luxembourgish": "Luxembourgish",
            "Maay_Maay": "Maay Maay",
            "Macedonian": "Macedonian",
            "Madi_Uganda": "Madi (Uganda)",
            "Malagasy": "Malagasy",
            "Malay": "Malay",
            "Malay_Jawi": "Malay (Jawi)",
            "Malayalam": "Malayalam",
            "Maltese": "Maltese",
            "Mam": "Mam",
            "Mandarin": "Mandarin",
            "Mandingo": "Mandingo",
            "Mandinka": "Mandinka",
            "Mandinka_Arabic": "Mandinka (Arabic)",
            "Mandinka_Latin": "Mandinka (Latin)",
            "Manipuri": "Manipuri",
            "Mankon": "Mankon",
            "Maori": "Maori",
            "Marathi": "Marathi",
            "Marshallese": "Marshallese",
            "Mauritian_Creole": "Mauritian Creole",
            "Memoni": "Memoni",
            "Mende": "Mende",
            "Mi_kmaq": "Mi'kmaq",
            "Michif": "Michif",
            "Mien": "Mien",
            "Mina": "Mina",
            "Mixteco": "Mixteco",
            "Mnong": "Mnong",
            "Mon": "Mon",
            "Mongolian": "Mongolian",
            "Mongolian_Cyrillic": "Mongolian Cyrillic",
            "Montenegrin": "Montenegrin",
            "Montenegrin_Cyrillic": "Montenegrin (Cyrillic)",
            "Montenegrin_Latin": "Montenegrin (Latin)",
            "Mossi": "Mossi",
            "Nahuatl": "Nahuatl",
            "Navajo": "Navajo",
            "Nepali": "Nepali",
            "Nigerian_Pidgin_English": "Nigerian Pidgin English",
            "Nordic": "Nordic",
            "Norwegian": "Norwegian",
            "Nuer": "Nuer",
            "Ojibway": "Ojibway",
            "Oriya": "Oriya",
            "Oromo": "Oromo",
            "Palauan": "Palauan",
            "Pampangan": "Pampangan",
            "Papiamento": "Papiamento",
            "Pashai": "Pashai",
            "Pashto": "Pashto",
            "Patois": "Patois",
            "Persian": "Persian",
            "Pidgin_English": "Pidgin English",
            "Pima": "Pima",
            "Pinyin": "Pinyin",
            "Pohnpeian": "Pohnpeian",
            "Polish": "Polish",
            "Portuguese_Brazil": "Portuguese (Brazil)",
            "Portuguese_Guinea_Bissau": "Portuguese (Guinea-Bissau)",
            "Portuguese_Portugal": "Portuguese (Portugal)",
            "Punjabi": "Punjabi",
            "Pushtu": "Pushtu",
            "Quechua": "Quechua",
            "Queens_English_UK": "Queens English (UK)",
            "Quiche": "Quiche",
            "Rade": "Rade",
            "Rohingya_Arabic": "Rohingya (Arabic)",
            "Rohingya_Latin": "Rohingya (Latin)",
            "Romani": "Romani",
            "Romanian": "Romanian",
            "Romansh": "Romansh",
            "Russian": "Russian",
            "Saint_Lucian_Creole": "Saint Lucian Creole",
            "Samoan": "Samoan",
            "Sango": "Sango",
            "Sanskrit": "Sanskrit",
            "Saraiki": "Saraiki",
            "Scottish_Gaelic": "Scottish Gaelic",
            "Serbian": "Serbian",
            "Serbian_Cyrillic": "Serbian (Cyrillic)",
            "Serbian_Latin": "Serbian (Latin)",
            "Sesotho": "Sesotho",
            "Setswana": "Setswana",
            "Shanghainese": "Shanghainese",
            "Shona": "Shona",
            "Shuswap": "Shuswap",
            "Siberian_Yup_ik": "Siberian Yup’ik",
            "Sicilian": "Sicilian",
            "Sidaama": "Sidaama",
            "Sindhi": "Sindhi",
            "Sinhala": "Sinhala",
            "Siswati": "Siswati",
            "Slovak": "Slovak",
            "Slovenian": "Slovenian",
            "Somali": "Somali",
            "Soninke": "Soninke",
            "Sorani": "Sorani",
            "Spanish_Argentina": "Spanish (Argentina)",
            "Spanish_Chile": "Spanish (Chile)",
            "Spanish_Colombia": "Spanish (Colombia)",
            "Spanish_Ecuador": "Spanish (Ecuador)",
            "Spanish_International": "Spanish (International)",
            "Spanish_Latin_America": "Spanish (Latin America)",
            "Spanish_Mexico": "Spanish (Mexico)",
            "Spanish_Panama": "Spanish (Panama)",
            "Spanish_Puerto_Rico": "Spanish (Puerto Rico)",
            "Spanish_Spain": "Spanish (Spain)",
            "Spanish_US": "Spanish (US)",
            "Spanish_Venezuela": "Spanish (Venezuela)",
            "Stoney_Canada": "Stoney (Canada)",
            "Sudanese": "Sudanese",
            "Sundanese": "Sundanese",
            "Swahili": "Swahili",
            "Swedish": "Swedish",
            "Swedish_Finland": "Swedish (Finland)",
            "Sylheti": "Sylheti",
            "Syriac": "Syriac",
            "Szechuan": "Szechuan",
            "Tagalog": "Tagalog",
            "Tahitian": "Tahitian",
            "Taiwanese": "Taiwanese",
            "Tajik": "Tajik",
            "Tamil": "Tamil",
            "Tatar": "Tatar",
            "Tedim_Chin": "Tedim_Chin",
            "Telugu": "Telugu",
            "Temne": "Temne",
            "Tetum": "Tetum",
            "Thai": "Thai",
            "Tibetan": "Tibetan",
            "Tigre": "Tigre",
            "Tigrinya": "Tigrinya",
            "Tlingit": "Tlingit",
            "Toishanese": "Toishanese",
            "Tongan": "Tongan",
            "Trique": "Trique",
            "Tsuut_ina": "Tsuut'ina",
            "Turkish": "Turkish",
            "Turkmen": "Turkmen",
            "Twi": "Twi",
            "Uighur": "Uighur",
            "Ukrainian": "Ukrainian",
            "Ulster_Scots": "Ulster-Scots",
            "Urdu": "Urdu",
            "Uzbek": "Uzbek",
            "Uzbek_Cyrillic": "Uzbek (Cyrillic)",
            "Uzbek_Latin": "Uzbek (Latin)",
            "Vietnamese": "Vietnamese",
            "Visayan": "Visayan",
            "Welsh": "Welsh",
            "Wolof": "Wolof",
            "Xhosa": "Xhosa",
            "Yapese": "Yapese",
            "Yaqui": "Yaqui",
            "Yavapai": "Yavapai",
            "Yiddish_other": "Yiddish (other)",
            "Yiddish_US": "Yiddish (US)",
            "Yoruba": "Yoruba",
            "Yucateco": "Yucateco",
            "Yupik": "Yupik",
            "Zarma": "Zarma",
            "Zou": "Zou",
            "Zulu": "Zulu"
        },
        "third_info_one": "Additional languages and dialects may be available. If you have a question regarding language availability",
        "third_info_two": "please contact us at"
    },
    "document_clarification": {
        "tit": "DOCUMENT CLARIFICATION",
        "main": "Language Access Through All Touchpoints with EMLions Clarity",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "sec_one": "EMLions is the world’s largest language access company. In addition to offering translation solutions in 290+ languages, our Clarity team also ensures that communications are, first and foremost, simple, clear, and comprehensible in “plain English.”",
        "sec_two": "We are proud to bridge the gap in communication in all corners of the world. Still, we want to consider how English speakers also need better access to the information they consume in English. Considering the average person in the U.S has the reading skills of a 7th grader, using plain English is imperative for clear, more accessible, and concise communication. This is why we see “plain English” as a valuable language unto itself.",
        "third_tit": "EMLIONS CLARITY",
        "third_main": "Clear Communication. Simple Communication.",
        "third_main_one": "Documents that are too long, poorly organized, and rife with jargon and insider language are difficult or impossible to understand in any language, no matter how expertly they may be translated.",
        "third_main_two": "On the other hand, when digital and print documents are purged of nonessential content and written and designed for crystal clear clarity to intended audiences, they will be easy to understand in every language. On its own can offer an opportunity to increase connection and trust between you and your clients.",
        "third_main_three": "EMLions Clarity services are entirely different than other plain language writing services. Instead of tackling a handful of individual documents in a vacuum, EMLions® reengineers entire documents systems and helps you produce leaner, more transparent, and cost-effective content."
    },
    "document_simplification": {
        "tit": "DOCUMENT SIMPLIFICATION",
        "main": "Reach Your Audience With Plain Language and Full Clarity",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "What Is Document Simplification?",
        "sec_main": "Customers, patients, and constituents appreciate and respond to simple, direct communications. When they understand what you’re saying the first time, they’re more likely to come back for more. This is what our document simplification services can help you achieve. We offer Macrosimplification and Microsimplification for improving comprehension and reducing translation spend.",
        "macro": "MACROSIMPLIFICATION",
        "third_one_tit": "Simplify an Entire Document System",
        "third_one_main": "Fully reengineer your document system for leaner, more effective content that increases satisfaction by improving readability and understanding across touch points. Refining your document system can also result in cost savings and better performance.",
        "third_two_tit": "Tackle Hundreds of Documents at Once",
        "third_two_main_one": "Streamline large print and digital document systems",
        "third_two_main_two": "Revamp complex content for clarity and ease of use",
        "third_two_main_three": "Right-size document libraries",
        "third_two_main_four": "Boost communication processes and effectiveness",
        "third_three_tit": "Save Money and Meet Compliance Standards",
        "third_three_main": "Macrosimplification projects can pay for themselves with increased quality and reduced production and printing costs. They also improve the customer, patient, and constituent experience, build satisfaction, and help ensure you meet compliance requirements.",
        "third_four_tit": "How to Tell If You Need It",
        "third_four_main": "Speak with one of our representatives about Macrosimplification if your organization struggles with:",
        "third_four_main_one": "Outdated, inconsistent information",
        "third_four_main_two": "Redundant and excessive content",
        "third_four_main_three": "Difficulty reaching specific audiences",
        "third_four_main_four": "Confusing communications that lead to customer calls",
        "third_four_main_five": "A lack of effective governance and quality control",
        "micro": "MICROSIMPLIFICATION",
        "four_one_tit": "Streamline Individual Documents and Communications",
        "four_one_main": "Microsimplification is invaluable for any organization. The process includes:",
        "four_one_main_one": "Content analysis and recommendations for improvement",
        "four_one_main_two": "Removal of unnecessary and excessive information",
        "four_one_main_three": "Use of Plain English for core information",
        "four_one_main_four": "Creation of new copy that’s easy to read and appealing",
        "four_two_tit": "Deliver a Better Customer Experience",
        "four_two_main": "Microsimplification of marketing materials can dramatically increase their effectiveness and help ensure you meet your industry’s compliance standards. Simple, clear materials have a greater chance of converting prospects into customers and building loyalty over the long term.",
        "four_three_tit": "Improve Outcomes and Reduce Costs",
        "four_three_main": "Microsimplification is especially useful for organizations offering complex services such as insurance, financial services, or medical care. A simplified form, bill, or highly visible brochure can have an outsized impact on your outcomes and costs.",
        "five_tit": "Let’s Talk Solutions",
        "five_main": "We’re here with expert language assistance designed to meet your needs and budget. Request a quote now or speak with one of our representatives to review your language access challenges and discuss solutions.",
        "schedule_btn": "SCHEDULE CONSULTATION"
    },
    "audio_transcription": {
        "tit": "AUDIO TRANSCRIPTION",
        "main": "Turn Your Audio Recordings Into Valuable Assets",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Use Audio Transcription to Boost Your Business",
        "sec_one": "Today’s organizations collect vast amounts of information not only in print and digital communications but also in their audio and video recordings. These assets contain valuable information that can improve customer service, staff training, and marketing outreach.",
        "sec_two": "We can take the work out of transcribing your audio and video recordings. We provide audio-to-text transcription, voiceover, and translation in more than 290 languages. Rest assured, our 3,500+ expert linguists will complete your transcription projects with high-quality output, thorough proofreading, and on-time delivery.",
        "transcription": "TRANSCRIPTION",
        "third_one_tit": "Improve Performance and Productivity",
        "third_one_main": "Many companies record customer service calls for quality control, but listening to them can be time-consuming and a drag on productivity. Your recordings can give you new insights into how well you serve your customers, patients, and constituents. Plus, transcription can give your call center managers a faster way to review staff performance and intervene with coaching promptly.",
        "third_two_tit": "Increase Accessibility for All",
        "third_two_main": "Transcribing audio recordings can make your content more accessible to Deaf or Hard of Hearing customers and employees. If your organization produces audio for marketing, customer support, or employee training purposes, turning these recordings into print or digital content will make them accessible to everyone you serve.",
        "third_three_tit": "Reach a Global Audience",
        "third_three_main": "Audiences around the world are hungry for effective content. So, don’t stop at turning your recording into print and digital communications. Go further by engaging our 3,500+ expert linguists, who can translate a single audio transcription into 290+ languages—radically multiplying the impact of every recording you’ve made.",
        "captioning": "CAPTIONING",
        "four_one_tit": "Meet the Growing Demand for Video",
        "four_one_main": "Video content now drives over 65% of all internet traffic, and many viewers prefer videos with captions. Some are Deaf or Hard of Hearing (DHH), while others like watching videos in public without sound. When you add captions to your video content, you can meet the needs of millions of people.",
        "four_two_tit": "Better Serve Diverse Populations",
        "four_two_main": "Captioning video content helps ensure you",
        "four_two_main_one": "Increase accessibility for DHH populations or those with limited-English proficiency",
        "four_two_main_two": "Boost engagement by improving the chances viewers will watch your content until the end",
        "four_two_main_three": "Appeal to new audiences and growing demographics",
        "four_three_tit": "Count on Our Expert Linguists",
        "four_three_main": "Our professionals are experts in captioning. We’ve helped thousands of businesses, healthcare organizations, government agencies, and more expand their reach while being more inclusive and accessible to the populations they serve.",
        "five_tit": "Let’s Talk Solutions",
        "five_main": "We’re ready to deliver expert audio transcription services that meet your needs and budget. You can read our Translation & Localization brochure or choose a time to speak with our experts about solving your language access challenges.",
        "five_download_btn": "DOWNLOAD NOW"
    },
    "alternative_format": {
        "tit": "ALTERNATIVE FORMAT TRANSCRIPTION",
        "main": "Build Relationships with Visually Impaired Populations",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Ensure Equal Access to Your Products and Services",
        "sec_one": "32+ million U.S. adults report they have experienced significant vision loss or blindness. They could be your customers, patients, constituents, or employees. When you meet their needs by presenting written materials in Braille, Large Print, or other alternative formats, you ensure equal access and enable better outcomes.",
        "sec_two": "We can transcribe your content for maximum impact with Blind and Visually Impaired people. Our advanced technology allows us to complete your projects quickly and cost-effectively, while maintaining the highest levels of quality.",
        "third_tit": "BRAILLE & LARGE PRINT",
        "third_main": "Meet the Needs of Visually Impaired Populations",
        "third_main_exp": "We can help you provide written materials in Braille and Large Print editions in a wide range of languages. Plus, Large Print can enhance your digital materials and website. Count on high-quality output backed by our team of professional editors.",
        "four_tit": "AUDIO TRANSCRIPTION",
        "four_main": "Transcribe Audio to Improve Your Services and Business",
        "four_main_exp": "Your audio recordings likely contain essential information that can improve your customer service and staff training. Plus, they act as the foundation for new marketing materials. We’ll take the work out of transcribing recordings with audio-to-text transcription, voiceover, and translation in 290+ languages.",
        "four_btn": "LEARN MORE",
        "five_tit": "Let’s Talk Solutions",
        "five_main": "We deliver high-quality alternative format transcription services tailored to your project and budget. Speak with one of our experts about solving your language access challenges and ensuring accessibility for all.",
        "six_tit": "Improve Accessibility with Advanced Translation Technology",
        "six_one_tit": "AUDIO TRANSCRIPTION",
        "six_one_contents": "Better serve Blind and Visually Impaired people with audio versions of your written content and information.",
        "six_two_tit": "DAISY",
        "six_two_contents": "Increase the usability and accessibility of your content with a digital accessible information system (DAISY) format export.",
        "six_three_tit": "MOON",
        "six_three_contents": "Transcribe your content into Moon to make it more usable and impactful for Visually Impaired audiences.",
        "six_four_tit": "E-TEXT",
        "six_four_contents": "Convert your content to E-Text to enable software to magnify it, read it aloud, and reproduce it on a refreshable Braille display.",
        "six_five_tit": "EASY READ",
        "six_five_contents": "Combine text and images to deliver more consumable content for people with learning disabilities."
    },
    "translate": {
        "tit": "TRANSLATION SERVICES",
        "main": "Build Stronger Relationships Through Total Understanding",
        "main_exp": "Get the expertise you need to communicate clearly at any scale.",
        "schedule_btn": "SCHEDULE CONSULTATION",
        "sec_tit": "Increase Translation Quality in 290+ Languages",
        "sec_one": "67+ million U.S. residents speak a language other than English at home. Conversing with limited English proficient (LEP) individuals in their language of choice is not just good practice, it’s the key to growth. Research shows that 92% of people will purchase products sold in their preferred language and 91% will make repeat purchases.",
        "sec_two": "Most translation vendors are limited in headcount, expertise, and quality, forcing you to look elsewhere for certain projects. Rather than working with a network of niche services, partner with a single provider with 25+ years experience, 3500+ expert linguists, and proven quality standards backed by multiple ISO certifications.",
        "sec_three": "EMLions® Translation & Localization experts support all types of content and sizes of projects in 290+ languages. Our innovative technology creates workflow efficiencies while facilitating the fastest possible service. EMLions is ready to help you:",
        "sec_four": "Translate documents, marketing materials, forms, communications, and how-to guides",
        "sec_five": "Localize websites, hardware, software, in-game content, and eLearning",
        "sec_six": "Automate translation and localization of your content",
        "third_one": "Professional linguists waiting to serve your organization",
        "third_two": "EMLions’s content translation accuracy rate",
        "third_three": "EMLions’s on-time delivery rate for translation projects",
        "four_one_tit": "Document Translation",
        "four_one_contents": "Get high-quality document translations customized for your intended use, audience, and shelf life.",
        "four_two_tit": "Localization Services",
        "four_two_contents": "Localize print and digital content for a specific locale’s language and cultural sensitivities so that it’s a natural experience for your target.",
        "four_three_tit": "Integrated Translation Tools",
        "four_three_contents": "Get faster results with our innovative technology that eliminates translation barriers and reduces human error.",
        "four_four_tit": "Machine Translation",
        "four_four_contents": "EMLions’s AI-driven “best fit” technology provides fast turnarounds for large-scale, low-liability content, at the lowest cost.",
        "four_five_tit": "eLearning Services",
        "four_five_contents": "Repurpose your eLearning content into different languages to increase organizational productivity.",
        "four_six_tit": "Website Translation",
        "four_six_contents": "Website Proxy provides a hands-off way to produce and manage translated versions of your English website, or keep the English version and the translated versions always in sync.",
        "four_sevent_tit": "Translation Languages",
        "four_sevent_contents": "Find out more about the expertise and quality our translators offer in 290+ languages.",
        "five_tit": "Let’s Talk Solutions",
        "five_main": "Our professional linguists are eager to provide translation and localization services that fit your budget. Request an estimate now for your next project or speak with one of our EMLions Translation Solutions experts.",
        "quote_btn": "REQUEST QUOTE"
    },
    "other_translate": {
        "tit": "OTHER LANGUAGE SERVICES",
        "main": "Make Every Word Count",
        "main_exp": "Help your organization communicate clearly and concisely to maximize the impact of all your documents and content.",
        "quote_btn": "REQUEST QUOTE",
        "sec_tit": "Communicate Effectively With Plain Language and More",
        "sec_one": "The average American adult reads at a seventh-grade level. Most readers become lost when they face complex content, unfamiliar terminology, and industry jargon. If your organization uses overly complicated language, you’ll run the risk of creating a poor customer experience, causing confusion, and driving up costs.",
        "sec_two": "As the world’s leading language-access partner, we offer translation solutions in 290+ languages. Today’s gold standard for effective communication is Plain English which we consider a language unto itself. We can help you simplify your documents, so you get your message across and appeal to all your audiences.",
        "third_one_tit": "Document Clarification",
        "third_one_contents": "We rewrite public-facing materials so they’re easier to understand and less expensive to translate into other languages.",
        "third_two_tit": "Document Simplification",
        "third_two_contents": "We identify core content that’s useful to all audiences and simplify it for greater impact.",
        "third_three_tit": "Audio Transcription",
        "third_three_contents": "We can transcribe your videos and translate them to your language of choice. Plus, source and record voiceover artists.",
        "third_four_tit": "Alternative Format Transcription",
        "third_four_contents": "We transcribe content to Braille, including English and Spanish.",
        "four_tit": "Let’s Talk Solutions",
        "four_main": "We’re here with expert language assistance designed to meet your needs and budget. Request a quote now or speak with one of our representatives to review your language access challenges and discuss solutions.",
        "schedule_btn": "SCHEDULE CONSULTATION"
    },
    "footer": {
        "interpretation": "Interpretation",
        "testing_and_training": "Testing & Training",
        "industries": "Industries",
        "careers": "Careers",
        "resources": "Resources",
        "customer_service": "Customer Service",
        "contact_info": "Contact us at",
        "corporate_info": "© EMLions Solutions 2024. All rights reserved.",
        "sitemap": "Sitemap",
        "privacy_policy": "Privacy Policy",
        "cookie_policy": "Cookie Policy",
        "global_ethics_hotline": "Global Ethics Hotline"
    }
}