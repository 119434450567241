import Vue from 'vue'
import App from './App.vue'
import $ from 'jquery'
import router from './router'
import ElementUI from 'element-ui'
import 'amfe-flexible'
import '@/assets/styles/main.css'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from './i18n'
Vue.config.productionTip = false
Vue.prototype.$ = $
window.$ = $
window.jQuery = $

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
})

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
