export default{
    "lang": "Français",
    "home": {
        "main_explain": "Mener le monde grâce à la technologie numérique, créer de la valeur avec des services ciblés Accélérer la transformation numérique pour chacun de nos clients grâce à des idées d'avant-garde et à une exécution rapide.",
        "contact_btn": "Prenez contact avec nous",
        "read_me_btn": "En savoir plus",
        "second_tit": "Donner du pouvoir à l'ère numérique Créer de la valeur ensemble",
        "second_main": "Imaginez un monde où les barrières linguistiques et culturelles n'existent plus - où la compréhension est universelle et où tout le monde est habilité.",
        "second_expand": "Nous vous aiderons à servir les membres de votre communauté au moment où ils en ont besoin, indépendamment de leur langue, de leur culture ou de leurs capacités.",
        "second_learn_more": "Contactez-nous pour en savoir plus",
        "second_main_tips_one": "Localisation, traduction de contenu",
        "second_main_tips_two": "Gestion de projets de traduction",
        "second_main_tips_three": "Améliorer la qualité des traductions",
        "second_main_tips_four": "Stimuler la productivité des équipes",
        "second_main_tips_five": "Améliorer l'expérience grâce à l'IA",
        "second_main_tips_six": "Localisation d'applications et de logiciels",
        "third_tit": "Exceller sur le marché mondial grâce à nos services de traduction",
        "third_main": "Nous permettons à nos clients d'atteindre de nouveaux marchés à l'échelle mondiale en se connectant à leur public et en offrant la meilleure expérience client possible, quelle que soit la langue. Nous sommes un partenaire qui peut vous accompagner de la planification à l'exécution grâce à nos services, notre technologie, nos conseils et notre expertise sectorielle.",
        "third_btn": "Contactez-nous pour en savoir plus",
        "four_tit": "Comment pouvons-nous vous aider à réussir ?",
        "four_main": "Nos solutions sur mesure vous aident à atteindre ces nouveaux marchés rapidement, efficacement et de manière à maximiser les gains pour votre entreprise.",
        "four_main_one_tit": "LOCALISATION, TRADUCTION DE CONTENU",
        "four_main_one_content": "Développez votre contenu global et maximisez son impact local avec l'aide de nos technologies intelligentes.",
        "four_main_two_tit": "GESTION DES PROJETS DE TRADUCTION",
        "four_main_two_content": "La plateforme de gestion des traductions permet d'adapter, de rationaliser et d'automatiser facilement le processus de localisation.",
        "four_main_three_tit": "AMÉLIORER LA QUALITÉ DES TRADUCTIONS",
        "four_main_three_content": "Protégez la réputation de votre marque grâce à des vérifications linguistiques qui garantissent un contenu clair et sans erreur.",
        "four_main_four_tit": "LOCALISATION D'APPLICATIONS ET DE LOGICIELS",
        "four_main_four_content": "Assurez-vous que les produits sont parfaitement adaptés et parfaitement présentés sur chaque marché grâce à notre gamme complète de services de localisation.",
        "four_main_five_tit": "STIMULER LA PRODUCTIVITÉ DES ÉQUIPES",
        "four_main_five_content": "Donnez aux traducteurs et aux chefs de projet les outils nécessaires pour travailler plus intelligemment grâce au logiciel de traduction assistée par ordinateur (TAO) le plus réputé.",
        "four_main_six_tit": "AMÉLIORER L'EXPÉRIENCE GRÂCE À L'INTELLIGENCE ARTIFICIELLE",
        "four_main_six_content": "Répondez aux questions des clients pour une fraction du coût et suffisamment rapidement pour les transformer en actions pertinentes et opportunes grâce à l'intelligence artificielle.",
        "five_tit": "Explorez les services d'entreprise dès aujourd'hui.",
        "five_main": "Ensemble, réalisons vos objectifs les plus ambitieux.",
        "six_tit": "Qui utilise EMLIONS ?",
        "six_main": "Des solutions de transformation de contenu et de données à l'échelle mondiale reconnues par les clients, les traducteurs et les organisations industrielles.",
        "six_main_find": "Pour en savoir plus."
    },
    "contact_btn": "Nous contacter",
    "translation": {
        "nav": "Traduction",
        "menu_doc": "Traduction de documents",
        "menu_localization": "Services de localisation",
        "menu_tools": "Outils de traduction intégrés",
        "menu_machine": "Traduction Machine",
        "menu_elearning": "Services eLearning",
        "menu_website": "Traduction de sites Web",
        "menu_languages": "Langues de traduction"
    },
    "other_language_services": {
        "nav": "Autres services linguistiques",
        "menu_clarification": "Clarification des documents",
        "menu_simplification": "Simplification de documents",
        "menu_audio": "Transcription audio",
        "menu_alternative": "Transcription en format alternatif"
    },
    "about": {
        "title_fir": "A propos de nous",
        "title_sed": "Fournisseur de services informatiques",
        "title_explain_fir": "Partenaire de confiance de 157 clients Fortune 500.",
        "title_explain_sec": "Notre force réside dans notre personnel, et nous sommes bien plus qu'une simple société de traduction. Nous sommes une famille d'individus engagés qui se consacrent à être le premier facilitateur de communications mondiales.",
        "title_num_one": "clients Fortune 500",
        "title_num_two": "ans d'expérience",
        "title_num_three": "traducteurs professionnels",
        "second_title": "Nous vous apportons de puissants avantages pour mener à bien votre transformation numérique",
        "second_one_tit": "Capacités de services d'implantation et d'exploitation d'entreprises à l'échelle mondiale",
        "second_one_main": "EMLIONS compte 3 500 professionnels dans le monde entier, répartis dans 50 centres de livraison dans 28 villes. Nous nous engageons à proposer des offres dans plus de 200 langues, un support technique 7x24 et des services à nos clients dans le monde entier.",
        "second_two_tit": "Accélérer l'innovation grâce à une riche expérience industrielle",
        "second_two_main": "Forte de ses 25 ans d'expérience dans le secteur, Emlions propose à ses clients du monde entier des services couvrant le conseil numérique, l'interaction des expériences, la mise en œuvre des technologies et les opérations. En tirant parti de sa riche expérience dans des secteurs tels que la haute technologie, la finance, la fabrication, les télécommunications et l'internet, Emlions a établi des relations de coopération à long terme avec 157 entreprises figurant au classement Fortune 500.",
        "second_three_tit": "Amélioration de la qualité grâce à une sécurité et une gestion de la qualité éprouvées",
        "second_three_main": "Depuis l'adoption de la norme de système de gestion de la qualité ISO 9001 en 2000, Emlions a été certifié CMMI L5, ISO 20000 et ISO 27001. Nous répondons et dépassons constamment les attentes de nos clients grâce à un système de contrôle qualité qui répond aux normes internationales en termes de sécurité et de qualité des installations, du personnel et des procédures.",
        "second_four_tit": "Renforcer l'ère numérique et créer de la valeur pour les clients",
        "second_four_main": "Emlions se consacre à l'innovation de produits et de services axés sur l'expérience client, ainsi qu'au marketing numérique intégré pour accélérer le déploiement des capacités numériques, et à fournir aux clients des services numériques de qualité supérieure et les aider à naviguer dans la transformation numérique en intégrant le conseil stratégique, la créativité et la conception d'expériences, ainsi que par des moyens techniques tels que le mobile, l'IoT, l'informatique en nuage et le big data."
    },
    "solutions": {
        "nav": "SOLUTIONS",
        "tit_fir": "L'industrie",
        "tit_sec": "Solutions",
        "main_fir": "En tant que communicateurs experts, nous savons que le contexte est primordial. C’est pourquoi nous disposons d’équipes dédiées possédant une vaste expérience dans presque tous les secteurs industriels.",
        "main_sec": "Cette expertise sur mesure vous garantit que votre contenu global est à la fois efficace et conforme aux exigences spécifiques en matière de formatage, de terminologie et de réglementation.",
        "get_in_touch": "Contactez nous",
        "read_more": "En savoir plus",
        "sec_tit": "Comment pouvons-nous vous aider à réussir ?",
        "sec_main": "Nos solutions sur mesure vous aident à atteindre ces nouveaux marchés rapidement, efficacement et de manière à maximiser les gains pour votre entreprise.",
        "learn_more": "En savoir plus",
        "sec_one_tit": "Sciences de la vie",
        "sec_one_main": "Nous fournissons des solutions linguistiques, COA et de contenu expertes aux organismes de sciences de la vie, de recherche médicale et de recherche sous contrat (CRO) pour vous aider à améliorer l'efficacité des processus, à réduire les coûts de traduction et à accélérer la mise sur le marché.",
        "sec_two_tit": "Loi",
        "sec_two_main": "Nous pouvons vous aider à résoudre vos problèmes de contenu multilingue dans toutes les pratiques juridiques afin que vous puissiez faire face à des charges de travail de traduction en croissance rapide, que vous gériez des données de découverte électronique, des dépôts de fusions et acquisitions (M&A), des documents de propriété intellectuelle, des contrats et accords, des supports marketing. et plus.",
        "sec_three_tit": "Solutions pour les administrations publiques",
        "sec_three_main": "Répondez aux exigences gouvernementales en matière de contenu multilingue grâce à nos solutions professionnelles de gestion des langues et du contenu pour le secteur public, les services de renseignement, la défense et les organismes chargés de l'application de la loi.",
        "sec_four_tit": "Services financiers",
        "sec_four_main": "Nos solutions linguistiques et de contenu répondent aux besoins spécifiques des organisations de services financiers afin que vous puissiez créer les meilleures relations possibles avec vos clients dans n'importe quelle langue, partout dans le monde.",
        "sec_five_tit": "Soins Gérés",
        "sec_five_main": "Communiquez en toute confiance au-delà des barrières linguistiques et techniques. Nos solutions et notre expertise vous aident à répondre à vos besoins de traduction et de conformité tout au long de l'année, et pas seulement pendant la période d'inscription annuelle (AEP).",
        "sec_six_tit": "Aérospatiale et défense",
        "sec_six_main": "Répondez aux exigences rigoureuses des avions commerciaux et des équipements de défense, et améliorez la productivité et l'efficacité tout au long du cycle de vie du produit, y compris la maintenance après livraison, grâce à notre suite de publication pour S1000D.",
        "sec_sevent_tit": "Fabrication industrielle",
        "sec_sevent_main": "Nos solutions linguistiques et de contenu complètes peuvent vous aider à répondre aux besoins de l'ensemble de la chaîne de valeur en adoptant des pratiques logicielles agiles matures, tout en réduisant les coûts et en augmentant l'efficacité.",
        "sec_eight_tit": "Logiciels de haute technologie",
        "sec_eight_main": "Répondez aux exigences du marché et à la rapidité accrue des produits grâce à nos solutions linguistiques et de contenu spécialisées pour les logiciels et le matériel d'entreprise, les services informatiques, les médias sociaux, les jeux, les applications en ligne, le multimédia, les applications de productivité, l'informatique en nuage et les appareils mobiles.",
        "sec_nine_tit": "Vente au détail et commerce électronique",
        "sec_nine_main": "Les services linguistiques et les technologies d'Emlions permettent aux détaillants de réussir à l'échelle mondiale grâce à des stratégies de localisation du commerce électronique qui répondent aux besoins de votre public cible.",
        "sec_ten_tit": "Voyages, loisirs et hôtellerie",
        "sec_ten_main": "Nos solutions de contenu multilingue de bout en bout pour les fournisseurs, les distributeurs et les détaillants vous aideront à créer, traduire et fournir de grandes bases de données de contenu d'inventaire, tout en réduisant les coûts, en maintenant la satisfaction des consommateurs et en respectant les réglementations relatives à la protection des données des consommateurs.",
        "sec_elevent_tit": "Automobile",
        "sec_elevent_main": "Gérez vos contenus marketing, produits et services multilingues sur tous les canaux numériques, pour les prospects, les clients, le véhicule connecté et une organisation après-vente répartie dans le monde entier.",
        "sec_twelve_tit": "Services de globalisation pour l'industrie chimique",
        "sec_twelve_main": "Nos clients de l'industrie chimique nous font confiance pour fournir des services de traduction, d'interprétation et de propriété intellectuelle par le biais de linguistes et d'experts techniques hautement spécialisés ayant une expertise du secteur et une connaissance des normes et réglementations internationales.",
        "sec_Thirteen_tit": "Traduction multilingue dans le secteur de l'énergie",
        "sec_Thirteen_main": "Les entreprises du secteur de l'énergie sont confrontées à l'accélération de la demande mondiale et à l'arrivée de nouveaux acteurs sur des marchés de plus en plus concurrentiels. Le soutien de la propriété intellectuelle au niveau mondial et une communication multilingue efficace et cohérente sont essentiels.",
        "sec_fourteen_tit": "Services de traduction dans le secteur des assurances",
        "sec_fourteen_main": "Notre expertise en la matière garantit que l'information produit des résultats. Nous ne faisons appel qu'à des traducteurs et interprètes spécialisés, dont beaucoup sont d'anciens praticiens, courtiers, souscripteurs, gestionnaires de risques et membres du Chartered Institute of Insurance.",
        "sec_fifteen_tit": "Services de localisation des médias",
        "sec_fifteen_main": "Chez Emlions, notre approche globale du contenu, de la localisation et de la technologie vous aide à rester pertinent dans le monde numérique.",
        "sec_sixteen_tit": "Services de localisation des télécommunications",
        "sec_sixteen_main": "Aujourd'hui, le paysage très concurrentiel des télécommunications pousse les entreprises à élargir non seulement l'échelle et la portée de leurs offres, mais aussi leur portée mondiale."
    },
    "doc_translation": {
        "tit": "TRADUCTION DE DOCUMENTS",
        "main": "Renforcer les relations avec les clients dans plus de 290 langues",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Laissez nos experts répondre à tous vos besoins en matière de traduction",
        "sec_one": "Vos projets de traduction ne sont pas limités à une langue, un canal ou un délai. Bénéficiez de l'expertise dont vous avez besoin pour communiquer clairement à n'importe quelle échelle et transformez vos défis linguistiques en opportunités, grâce à des solutions créatives qui améliorent l'efficacité et favorisent des relations fructueuses.",
        "sec_two": "Nos plus de 3 500 linguistes experts fournissent des traductions de haute qualité, personnalisées en fonction de l'usage et du public visés. Ils soumettent également chaque projet à un processus rigoureux de traduction, de révision et de relecture afin de s'assurer que le produit final dépasse vos attentes.",
        "trird_one": "Linguistes professionnels",
        "third_two": "Langues disponibles pour la traduction",
        "third_three": "Qualité du contenu assurée",
        "four_tit": "Traduisez tout type de contenu dans plus de 290 langues",
        "four_one_tit": "Traduction de documents",
        "four_one_contents": "Lettres, formulaires, courriels, manuels techniques, contenu en ligne, sorties CMS, documents marketing, politiques et procédures RH.",
        "four_two_tit": "Sites web",
        "four_two_contents": "HTML, ASP, scripts, applications Java et interfaces basées sur des bases de données.",
        "four_three_tit": "Multimédia",
        "four_three_contents": "Ingénierie Flash, voix off et films à impact.",
        "four_four_tit": "Matériel de formation",
        "four_four_contents": "Tutoriels, modèles, applications eLearning et ERP.",
        "four_five_tit": "Logiciels",
        "four_five_contents": "Interface graphique, contenu de base de données, appareils sans fil, applications web et systèmes de processus d'entreprise.",
        "four_six_tit": "Systèmes d'aide et de gestion de contenu",
        "four_six_contents": "WinHelp, HTML Help, WebHelp, JavaHelp, Oracle Help, FlashHelp, AppleHelp, HubSpot, WordPress, Drupal et systèmes propriétaires.",
        "five_tit": "Traduction intersectorielle",
        "five_one_tit": "Entreprise:",
        "five_one_contents": "Informations sur les produits, communication avec les clients et documents destinés aux employés",
        "five_two_tit": "Santé médicale :",
        "five_two_contents": "Documents vitaux, instructions de sortie, avis d'éligibilité et formulaires pour les patients",
        "five_three_tit": "Services financiers :",
        "five_three_contents": "Documents de prêt, contrats, documents hypothécaires, demandes financières, relevés de compte et rapports de crédit.",
        "five_five_tit": "Assurance:",
        "five_five_contents": "Formulaires de demande d'indemnisation, informations sur les polices d'assurance et rapports d'accident",
        "five_six_tit": "Gouvernement :",
        "five_six_contents": "Avis de droits, formulaires de consentement, plaintes, lettres exigeant une réponse, demandes et sensibilisation du public",
        "six_tit": "La différence EMLions",
        "six_one_tit": "Traducteurs humains",
        "six_one_contents": "Nous utilisons une touche humaine et une compréhension culturelle pour nous assurer que chaque travail transmet le véritable sens de votre contenu original.",
        "six_two_tit": "Personnalisation",
        "six_two_contents": "Nous nous adaptons à chaque projet en tenant compte de vos besoins, de vos délais et de vos flux de travail.",
        "six_three_tit": "Assurance qualité à 100",
        "six_three_contents": "Chaque projet est traduit, révisé et relu par au moins deux linguistes professionnels.",
        "six_four_tit": "Délais de livraison respectés",
        "six_four_contents": "Notre taux de respect des délais pour la traduction de documents est de 99,5 %.",
        "sevent_tit": "En savoir plus sur les services de traduction de documents",
        "sevent_contents": "Découvrez comment obtenir de l'aide pour fournir un contenu cohérent et de haute qualité dans n'importe quelle langue. Vous pouvez consulter notre brochure sur la traduction et la localisation ou demander le prix à nos experts.",
        "download_btn": "TÉLÉCHARGER MAINTENANT"
    },
    "local_services": {
        "tit": "SERVICES DE LOCALISATION",
        "main": "Renforcez vos relations dans plus de 290 langues",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Améliorer la qualité de la localisation et réduire les coûts",
        "sec_one": "Il n'y a pas de limite au nombre de personnes que votre contenu numérique peut atteindre, mais la plupart des clients préfèrent acheter des produits qui communiquent dans leur langue. Assurez-vous de transmettre votre message de manière cohérente dans le monde entier en travaillant avec un partenaire disposant de l'expertise et de l'envergure nécessaires pour répondre à tous vos besoins en matière de contenu et à tous vos projets, quelle que soit leur ampleur.",
        "sec_two": "Nos services de traduction et de localisation sont assurés par plus de 3 500 linguistes experts prêts à produire des documents cohérents et de grande qualité dans plus de 290 langues. Nous localiserons votre contenu imprimé ou numérique en fonction de la langue et des sensibilités culturelles d'un lieu spécifique, afin que votre public cible en fasse une expérience naturelle. En outre, pour garantir une livraison dans les délais, nous appliquons des procédures de contrôle de la qualité complètes et de bout en bout pour chaque projet.",
        "third_one": "Des linguistes experts au sein de notre équipe",
        "third_two": "Langues disponibles pour la traduction",
        "third_three": "Taux de précision des traductions",
        "four_tit": "SERVICES DE LOCALISATION",
        "four_main": "Mieux servir vos clients, vos patients et vos électeurs",
        "four_main_exp": "Nos professionnels peuvent localiser n'importe quel type de contenu imprimé ou numérique, y compris :",
        "four_main_exp_one": "Sites Web, applications et logiciels",
        "four_main_exp_two": "Vidéos, sous-titres, graphiques et voix off",
        "four_main_exp_three": "Documents et supports marketing",
        "four_main_exp_four": "Formation en ligne et formation des membres de l'équipe",
        "four_main_exp_five": "Documents de réglementation et de conformité",
        "four_main_exp_six": "Jeux vidéo et autres",
        "five_tit": "CONNECTEURS CMS",
        "five_main": "Automatisez vos transferts de fichiers",
        "five_main_exp": "EMLions a réimaginé la traduction du point de vue de ses clients, en intégrant l'automatisation multilingue directement dans les systèmes de contenu et les applications d'entreprise, ce qui permet à nos clients de nous envoyer facilement n'importe quel contenu. Choisissez l'un de nos 50+ connecteurs prêts à l'emploi pour améliorer l'efficacité de vos transferts de fichiers et de vos flux de publication.",
        "five_btn": "EN SAVOIR PLUS",
        "six_tit": "PROXY WEBSITE",
        "six_main": "Traduisez facilement votre site web",
        "six_main_exp": "Rationalisez le processus de production et de gestion des versions traduites de votre site Web anglais ou de votre plateforme d'e-commerce. Avec EMLions® Website Proxy, les versions traduites de votre site sont toujours synchronisées avec la version anglaise. De plus, si vous ne disposez pas des ressources nécessaires pour héberger un site traduit, Website Proxy vous permet de dupliquer votre contenu et de l'afficher dans la langue de votre choix.",
        "six_btn": "EN SAVOIR PLUS",
        "sevent_tit": "En savoir plus sur les outils de traduction intégrés",
        "sevent_main": "Fournissez un contenu cohérent et de haute qualité dans n'importe quelle langue sans sacrifier la précision. Vous pouvez consulter notre brochure sur la traduction et la localisation ou prendre rendez-vous avec nos experts pour discuter de la manière de relever vos défis en matière de traduction.",
        "sevent_download_btn": "TÉLÉCHARGER MAINTENANT",
        "sevent_schedule_btn": "PLANIFIER UNE CONSULTATION",
        "eigth_tit": "Services de localisation d'EMLions",
        "eigth_one_tit": "TRADUCTEURS HUMAINS",
        "eigth_one_contents": "Nous utilisons une touche humaine et une compréhension culturelle pour nous assurer que chaque travail transmet le sens de votre contenu original.",
        "eigth_two_tit": "PERSONNALISATION",
        "eigth_two_contents": "Nous mettons à votre disposition une technologie de pointe et une expertise linguistique pour nous adapter à l'évolution des besoins de votre projet.",
        "eigth_three_tit": "ASSURANCE QUALITÉ À 100",
        "eigth_three_contents": "Chaque projet est traduit, révisé et relu par au moins deux linguistes.",
        "eigth_four_tit": "LIVRAISON DANS LES DÉLAIS",
        "eigth_four_contents": "Nous n'avons jamais manqué une date de mise en service."
    },
    "integrated_translation": {
        "tit": "OUTILS DE TRADUCTION INTÉGRÉS",
        "main": "Accélérer la traduction sans sacrifier la précision",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "sec_tit": "Mettez la technologie de la traduction à votre portée",
        "sec_one": "Que vous souhaitiez développer vos programmes de marketing dans le monde entier, traduire des informations confidentielles sur les patients ou proposer du matériel pédagogique dans de nouvelles langues, vous avez besoin de traductions de haute qualité adaptées à vos besoins et à votre budget. L'utilisation de la bonne technologie de traduction peut rationaliser de nombreuses étapes du processus, ce qui vous permet de produire des documents précis dans des délais plus courts et à moindre coût.",
        "sec_two": "Nous proposons une technologie de traduction qui rend le processus aussi simple, facile et sûr que possible. Utilisez notre portail de traduction EMLions® crypté de bout en bout pour soumettre rapidement des projets à nos plus de 3 500 linguistes experts. Vous pouvez également télécharger vos traductions, consulter l'état d'avancement du projet et accéder aux rapports en ligne. Le résultat est un contenu précis et cohérent pour votre public, et une tranquillité d'esprit pour vous et votre personnel.",
        "third_one": "Langues disponibles pour la traduction",
        "third_two": "Taux de précision des traductions",
        "third_three": "Respect des délais pour les projets de traduction",
        "four_tit": "CONNECTEURS CMS",
        "four_main": "Automatisez vos transferts de fichiers",
        "four_main_exp": "EMLions a réimaginé la traduction du point de vue de nos clients, en intégrant l'automatisation multilingue directement dans les systèmes de contenu et les applications commerciales, ce qui permet à nos clients de nous envoyer facilement n'importe quel contenu. Choisissez l'un de nos plus de 50 connecteurs prêts à l'emploi pour améliorer l'efficacité de vos transferts de fichiers et de vos flux de publication.",
        "four_btn": "EN SAVOIR PLUS",
        "five_tit": "PROXY WEBSITE",
        "five_main": "Traduisez facilement votre site web",
        "five_main_exp": "Rationalisez le processus de production et de gestion des versions traduites de votre site Web anglais ou de votre plateforme d'e-commerce. Avec EMLions® Website Proxy, les versions traduites de votre site sont toujours synchronisées avec la version anglaise. De plus, si vous ne disposez pas des ressources nécessaires pour héberger un site traduit, Website Proxy vous permet de dupliquer votre contenu et de l'afficher dans la langue de votre choix.",
        "five_btn": "EN SAVOIR PLUS",
        "six_tit": "TRADUCTION AUTOMATIQUE",
        "six_main": "Obtenez des traductions en quelques minutes",
        "six_main_exp": "Traduisez des contenus à grande échelle le plus rapidement possible. Grâce à la traduction automatique d'EMLions®, notre technologie \" best fit \" pilotée par l'IA permet d'obtenir un contenu facile à comprendre en un rien de temps. Et lorsque vous associez votre contenu à notre processus de post-édition certifié ISO, vous avez l'assurance que toutes vos communications et tous vos produits livrables seront exacts.",
        "six_btn": "EN SAVOIR PLUS",
        "sevent_tit": "En savoir plus sur les outils de traduction intégrés",
        "sevent_main": "Fournissez un contenu cohérent et de haute qualité dans n'importe quelle langue sans sacrifier la précision. Vous pouvez consulter notre brochure sur la traduction et la localisation ou prendre rendez-vous avec nos experts pour discuter de la manière de relever vos défis en matière de traduction.",
        "sevent_download_btn": "TÉLÉCHARGER MAINTENANT",
        "sevent_schedule_btn": "PLANIFIER UNE CONSULTATION",
        "eigth_tit": "Outils de traduction intégrés",
        "eight_one_tit": "TECHNOLOGIE AVANCÉE",
        "eigth_one_contents": "Nous sommes à la pointe de l'industrie en concevant de nouvelles solutions de localisation.",
        "eight_two_tit": "PERSONNALISATION",
        "eigth_two_contents": "Nous fournissons une technologie de pointe et une expertise linguistique pour nous adapter à l'évolution des besoins de votre projet.",
        "eight_three_tit": "ASSURANCE QUALITÉ À 100",
        "eigth_three_contents": "Notre équipe intégrée de spécialistes de l'industrie garantit des résultats de la plus haute qualité.",
        "eight_four_tit": "RESPECT DES DÉLAIS",
        "eigth_four_contents": "Notre taux de respect des délais est de 99,5 %."
    },
    "machine_translation": {
        "tit": "TRADUCTION AUTOMATIQUE",
        "main": "Faites traduire votre contenu en quelques secondes",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Gagnez du temps et de l'argent en automatisant vos traductions",
        "sec_one": "Contrôlez totalement les délais et les coûts de vos projets de traduction. La traduction automatique utilise des moteurs spécialisés pour traduire le contenu instantanément. Pour obtenir les résultats les plus rapides possibles, vous pouvez automatiser entièrement le processus ou suivre un flux de travail hybride dans lequel l'un de nos 3 500+ linguistes professionnels prend en charge votre projet.",
        "sec_two": "Notre traduction automatique utilise l'intelligence artificielle pour améliorer la précision de vos traductions. Vous pouvez personnaliser vos processus de post-édition et de révision en fonction des besoins de votre projet, de votre budget et de votre calendrier.",
        "third_one": "Langues disponibles pour la traduction",
        "third_two": "Moteurs \"best fit\" pilotés par l'IA",
        "third_three": "Linguistes disponibles pour la post-édition",
        "four_tit": "TRADUCTION RAPIDE",
        "four_main": "La traduction automatique vous convient-elle ?",
        "four_main_exp": "La traduction automatique est idéale pour traduire de grandes quantités de contenu dans des délais serrés. Elle est idéale pour les contenus à faible risque de responsabilité, tels que les communications du service clientèle et les articles de blog. Si vous travaillez sur des documents juridiques, médicaux ou scientifiques, ou sur des contenus nécessitant une analyse approfondie des sentiments, vous obtiendrez de meilleurs résultats avec les services de traduction traditionnels.",
        "five_tit": "TECHNOLOGIE",
        "five_main": "Une précision accrue grâce à une technologie puissante",
        "five_main_exp": "EMLions® Machine Translation utilise plus de 40 moteurs de pointe pour maximiser la précision. Elle recherche automatiquement les meilleures options pour vos langues et vos types de contenu afin de garantir le meilleur résultat possible pour votre message. En utilisant notre flux de travail hybride ainsi que la mémoire de traduction et les glossaires de votre entreprise, nous pouvons augmenter la précision et vous aider à contrôler les coûts.",
        "six_tit": "Outils de traduction automatique EMLions",
        "six_one_tit": "TECHNOLOGIE AVANCÉE",
        "six_one_main": "Notre centre de traduction basé sur l'IA offre la traduction automatique la mieux adaptée à votre contenu.",
        "six_two_tit": "PERSONNALISATION",
        "six_two_main": "Sélectionnez le flux de traduction automatique qui répond le mieux à vos besoins en matière de contenu.",
        "six_three_tit": "SÉCURITÉ",
        "six_three_main": "Votre contenu précieux et exclusif reste dans notre environnement sécurisé.",
        "six_four_tit": "DÉLAIS D'EXÉCUTION RAPIDES",
        "six_four_main": "Réalisez vos projets instantanément ou plus rapidement grâce à notre flux de travail MTPE.",
        "sevent_tit": "En savoir plus sur la traduction et la localisation",
        "sevent_main": "En savoir plus sur la fourniture d'un contenu cohérent et de haute qualité dans n'importe quelle langue et pour n'importe quelle culture. Vous pouvez consulter notre brochure sur la traduction et la localisation ou prendre rendez-vous avec nos experts pour résoudre vos problèmes de traduction.",
        "sevent_download_btn": "TELECHARGER MAINTENANT",
        "sevent_schedule_btn": "PLANIFIER UNE CONSULTATION"
    },
    "elearning_translation": {
        "tit": "SERVICES D'APPRENTISSAGE EN LIGNE",
        "main": "Fournir du contenu eLearning dans plus de 290 langues",
        "schedule_btn": "PLANIFIER UNE CONSULTATION",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Augmentez le retour sur investissement de votre formation",
        "sec_one": "Les organisations du monde entier adoptent l'eLearning comme un moyen efficace et rentable de diffuser des contenus éducatifs et de formation à leur personnel et à leurs clients dispersés. Cependant, les entreprises qui ne traduisent pas et ne localisent pas correctement leur contenu eLearning risquent de limiter l'efficacité de leur formation et son retour sur investissement.",
        "sec_two": "Préparez vos programmes eLearning pour un public mondial en travaillant avec nos plus de 3 500 linguistes experts. Vous pouvez compter sur des livrables cohérents et de haute qualité. De plus, vous pouvez accéder à notre portail crypté de bout en bout pour télécharger des documents, suivre l'avancement et récupérer des traductions, ce qui garantit l'efficacité et la sécurité totale de chaque projet.",
        "third_one": "Des linguistes experts au sein de notre équipe",
        "third_two": "Langues disponibles pour la traduction",
        "third_three": "Taux de précision des traductions",
        "four_tit": "SERVICES D'APPRENTISSAGE EN LIGNE",
        "four_main": "Une qualité de contenu garantie à 100",
        "four_main_exp": "En tant que leader du secteur des services linguistiques, nous gérons chaque année des milliers de projets de traduction et de localisation dans plus de 290 langues. Nos décennies d'expérience dans la prise en charge de contenus de formation à distance nous ont permis de cultiver notre expertise dans la conception de programmes d'apprentissage en ligne hautement efficaces et accessibles. La qualité de notre contenu est garantie à 100 % et 99,5 % de nos clients jugent nos services de traduction et de localisation \"bons ou meilleurs\".",
        "five_tit": "TECHNOLOGIE",
        "five_main": "Applications eLearning et assistance",
        "five_main_exp": "93% des entreprises du Fortune 100 nous font confiance pour traduire et localiser des contenus spécialisés, notamment des cours eLearning, des supports de formation, des webinaires, des livres électroniques, des formations à l'intégration et des formations sur les systèmes et les produits. De plus, notre équipe d'experts est spécialisée dans la formation ERP pour SAP, Oracle, Salesforce et d'autres plateformes.",
        "six_tit": "EXPERTISE MONDIALE",
        "six_main": "Déployer des formations sectorielles dans le monde entier",
        "six_main_exp": "Nous veillons à ce que le vocabulaire unique de votre organisation et la terminologie spécifique à votre secteur soient compris à l'échelle mondiale dans plus de 290 langues. Nos plus de 3 500 linguistes experts ont une grande expérience de la traduction d'un large éventail de contenus eLearning dans des secteurs tels que",
        "six_main_one": "Santé",
        "six_main_two": "Informatique et SaaS",
        "six_main_three": "Commerce de détail et commerce électronique",
        "six_main_four": "l'éducation",
        "six_main_five": "Services financiers",
        "six_main_six": "Industrie pharmaceutique",
        "six_main_sevent": "Industrie manufacturière",
        "six_main_eight": "Construction et autres",
        "sevent_tit": "En savoir plus sur le eLearning",
        "sevent_main": "En savoir plus sur la fourniture d'un contenu eLearning cohérent et de haute qualité dans plus de 290 langues. Vous pouvez lire notre brochure sur la traduction et la localisation ou choisir un moment pour parler avec nos experts de la façon de résoudre vos défis en matière d'eLearning.",
        "sevent_download_btn": "TELECHARGER MAINTENANT",
        "sevent_schedule_btn": "PLANIFIER UNE CONSULTATION",
        "eight_tit": "Services eLearning",
        "eigth_one_tit": "SERVICE COMPLET",
        "eigth_one_contents": "Nous reproduisons votre contenu d'apprentissage de bout en bout pour activer vos audiences mondiales.",
        "eight_two_tit": "PERSONNALISATION",
        "eigth_two_contents": "Nous fournissons une technologie de pointe et une expertise linguistique pour nous adapter à l'évolution des besoins de votre projet.",
        "eight_three_tit": "ASSURANCE QUALITÉ À 100",
        "eigth_three_contents": "Notre équipe intégrée de spécialistes de l'industrie garantit des résultats de la plus haute qualité.",
        "eight_four_tit": "LIVRAISON DANS LES DÉLAIS",
        "eigth_four_contents": "Nous n'avons jamais manqué une date de mise en service."
    },
    "website_translation": {
        "tit": "TRADUCTION DE SITES WEB",
        "main": "Localisez votre site web pour un public international",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Traduisez votre site web dans plus de 290 langues",
        "sec_one": "Le contenu de votre site Web est en constante évolution, tout comme le besoin de vos clients de le voir dans la langue qu'ils comprennent. Maintenir votre site à jour avec le contenu le plus récent peut être une tâche intimidante en soi. Lorsque vous devez également le traduire dans plusieurs langues, la maintenance du site peut devenir un défi majeur qui a un impact sur la productivité du personnel et la rentabilité de l'organisation.",
        "sec_two": "Avec EMLions® Website Proxy, vous pouvez :",
        "sec_three": "Traduire des documents, des supports marketing, des formulaires, des communications et des guides pratiques.",
        "sec_four": "Localiser des sites Web, du matériel, des logiciels, du contenu de jeux et de l'apprentissage en ligne.",
        "sec_five": "Automatiser la traduction et la localisation de votre contenu",
        "third_one": "Des linguistes experts au sein de l'équipe",
        "third_two": "Taux de précision des traductions",
        "third_three": "Qualité du contenu assurée",
        "four_tit": "CONNECTEURS CMS",
        "four_main": "Automatisez vos transferts de fichiers",
        "four_main_exp": "EMLions a réimaginé la traduction du point de vue de ses clients, en intégrant l'automatisation multilingue directement dans les systèmes de contenu et les applications d'entreprise, ce qui permet à nos clients de nous envoyer facilement n'importe quel contenu. Choisissez l'un de nos 50+ connecteurs prêts à l'emploi pour améliorer l'efficacité de vos transferts de fichiers et de vos flux de publication.",
        "four_btn": "EN SAVOIR PLUS",
        "five_tit": "TRADUCTION AUTOMATIQUE",
        "five_main": "Est-ce que cela vous convient ?",
        "five_main_exp": "La traduction automatique est idéale pour traduire de grandes quantités de contenu dans des délais serrés. Elle est idéale pour les contenus à faible risque de responsabilité, tels que les communications du service client et les articles de blog. Si vous travaillez sur des documents juridiques, médicaux ou scientifiques, ou sur des contenus nécessitant une analyse approfondie des sentiments, vous obtiendrez de meilleurs résultats avec les services de traduction traditionnels.",
        "five_btn": "EN SAVOIR PLUS",
        "six_tit": "Traduction de sites Web EMLions",
        "six_one_tit": "TECHNOLOGIE DE POINTE",
        "six_one_contents": "Nous mettons à votre disposition une technologie de pointe et une expertise linguistique qui nous permettent de nous adapter à l'évolution des besoins de vos projets de sites Web.",
        "six_two_tit": "PERSONNALISATION",
        "six_two_contents": "Nous fournissons une technologie de pointe et une expertise linguistique pour nous adapter à l'évolution des besoins de votre projet de site Web.",
        "six_three_tit": "ASSURANCE QUALITÉ À 100",
        "six_three_contents": "Approche flexible pour toute complexité de site web et tout budget.",
        "six_four_tit": "LIVRAISON DANS LES DÉLAIS",
        "six_four_contents": "Notre équipe intégrée de spécialistes du secteur garantit des résultats de la plus haute qualité.",
        "sevent_tit": "En savoir plus sur la traduction et la localisation",
        "sevent_main": "En savoir plus sur la fourniture d'un contenu de site Web cohérent et de haute qualité dans n'importe quelle langue et pour n'importe quelle culture. Vous pouvez consulter notre brochure sur la traduction et la localisation ou choisir un moment pour parler avec nos experts de la résolution de vos problèmes de traduction."
    },
    "translation_languages": {
        "tit": "LANGUES DE TRADUCTION",
        "main": "Plus de 290 langues de traduction et de localisation disponibles",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "En savoir plus sur les outils de traduction intégrés",
        "sec_main": "Fournissez un contenu cohérent et de haute qualité dans n'importe quelle langue sans sacrifier la précision. Vous pouvez consulter notre brochure sur la traduction et la localisation ou choisir un moment pour discuter avec nos experts de la manière de relever vos défis en matière de traduction.",
        "sec_download_btn": "TELECHARGER MAINTENANT",
        "lang": {
            "Achi": "Achi",
            "Achinese": "Achinois",
            "Acholi": "Acholi",
            "Afar": "Afar",
            "Afghani": "Afghan",
            "Afrikaans": "Afrikaans",
            "Akan": "Akan",
            "Akateko": "Akatec",
            "Albanian": "Albanais",
            "Amharic": "Amharique",
            "Anuak": "Anuak",
            "Apache": "Apache",
            "Arabic": "Arabe",
            "Arabic_Iraq": "Arabe (Irak)",
            "Arabic_Juba": "Arabe (Juba)",
            "Arabic_Saudi_Arabia": "Arabe (Arabie Saoudite)",
            "Arabic_Sudan": "Arabe (Soudan)",
            "Arabic_Yemen": "Arabe (Yémen)",
            "Arakanese": "Arakanais",
            "Armenian": "Arménien",
            "Assamese": "Assamais",
            "Assyrian": "Assyrien",
            "Azeri": "Azéri",
            "Azeri_Cyrillic": "Azéri (cyrillique)",
            "Azeri_Latin": "Azéri (latin)",
            "Bajan": "Barbadien",
            "Bajuni": "Bajuni",
            "Bambara": "Bambara",
            "Basque": "Basque",
            "Bassa": "Bassa",
            "Behdini": "Behdini",
            "Beja": "Beja",
            "Belarusian": "Biélorusse",
            "Belgian": "Belge",
            "Bemba": "Bemba",
            "Bengali": "Bengali",
            "Berber": "Berbère",
            "Bhojpuri": "Bhojpuri",
            "Bislama": "Bislama",
            "Blackfoot_Canada": "Pieds-Noirs (Canada)",
            "Bosnian": "Bosniaque",
            "Bosnian_Cyrillic": "Bosniaque (cyrillique)",
            "Bosnian_Latin": "Bosniaque (latin)",
            "Braille": "Braille",
            "Bulgarian": "Bulgare",
            "Burmese": "Birman",
            "Cambodian": "Cambodgien",
            "Cambodian_Pidgin_English": "Créole cambodgien",
            "Cantonese": "Cantonais",
            "Cape_Verdean_Creole": "Créole cap-verdien",
            "Catalan": "Catalan",
            "Cebuano": "Cebuano",
            "Central_Alaskan_Yupik": "Yupik central alaskien",
            "Chaldean": "Chaldéen",
            "Chamorro": "Chamorro",
            "Chavacano": "Chavacano",
            "Cherokee": "Cherokee",
            "Chichewa": "Chichewa",
            "Chin": "Menton",
            "Chinese_Simplified": "Chinois (simplifié)",
            "Chinese_Traditional": "Chinois (traditionnel)",
            "Chinese_Traditional_HK": "Chinois traditionnel (Hong Kong)",
            "Choctaw": "Choctaw",
            "Chui_Chow": "Dialecte Teochew",
            "Chuj": "Chuj",
            "Chuukese": "Chuukese",
            "Cree_Canada": "Cri (Canada)",
            "Croatian": "Croate",
            "Crow": "Crow",
            "Czech": "Tchèque",
            "Dakota": "Dakota",
            "Danish": "Danois",
            "Dari": "Dari",
            "Dene": "Dénés",
            "Dinka": "Dinka",
            "Dutch": "Néerlandais",
            "Dutch_Belgium": "Néerlandais (Belgique)",
            "Dyula": "Dyula",
            "Dzongkha": "Dzongkha",
            "Eastern_Mari": "Mari oriental",
            "Edo": "Édo",
            "English_Australia": "Anglais (Australie)",
            "English_Belize": "Anglais (Belize)",
            "English_Canada": "Anglais (Canada)",
            "English_Ireland": "Anglais (Irlande)",
            "English_Jamaica": "Anglais (Jamaïque)",
            "English_Liberia": "Anglais (Libéria)",
            "English_New_Zealand": "Anglais (Nouvelle-Zélande)",
            "English_Trinidad_Tobago": "Anglais (Trinité-et-Tobago)",
            "English_UK": "Anglais (Royaume-Uni)",
            "English_US": "Anglais (États-Unis)",
            "English_Zimbabwe": "Anglais (Zimbabwe)",
            "Estonian": "Estonien",
            "Ewe": "Ewe",
            "Falam": "Falam",
            "Fante": "Fante",
            "Farsi": "Farsi",
            "Fijian": "Fidjien",
            "Filipino": "Philippin",
            "Finnish": "Finnois",
            "Flemish": "Flamand",
            "Formosan": "Formosan",
            "French_Belgium": "Français (Belgique)",
            "French_Burkina_Faso": "Français (Burkina Faso)",
            "French_Burundi": "Français (Burundi)",
            "French_Cameroon": "Français (Cameroun)",
            "French_Canada": "Français (Canada)",
            "French_Congo": "Français (Congo)",
            "French_France": "Français (France)",
            "French_Guinea": "Français (Guinée)",
            "French_Ivory_Coast": "Français (Côte d'Ivoire)",
            "French_Madagascar": "Français (Madagascar)",
            "French_Morocco": "Français (Maroc)",
            "French_Switzerland": "Français (Suisse)",
            "French_Tunisia": "Français (Tunisie)",
            "French_West_Africa": "Français (Afrique de l'Ouest)",
            "Frisian": "Frison",
            "Fukienese": "Fukienese",
            "Fula": "Fula",
            "Fulani": "Fulani",
            "Fulfulde": "Fulfulde",
            "Fuzhou": "Fuzhou",
            "Ga": "Ga",
            "Gaddang": "Gaddang",
            "Galician": "Galicien",
            "Garifuna": "Garifuna",
            "Georgian": "Géorgien",
            "German": "Allemand",
            "German_Austria": "Allemand (Autriche)",
            "German_Belgium": "Allemand (Belgique)",
            "German_Liechtenstein": "Allemand (Liechtenstein)",
            "German_Pennsylvania": "Allemand (Pennsylvanie)",
            "German_Switzerland": "Allemand (Suisse)",
            "Greek": "Grec",
            "Guarani": "Guarani",
            "Gujarati": "Gujarati",
            "Guyanese": "Guyanien",
            "Haitian_Creole": "Créole haïtien",
            "Hakka": "Hakka",
            "Hakka_China": "Hakka (Guangdong)",
            "Hakka_Taiwan": "Hakka (Taïwan)",
            "Harari": "Harari",
            "Haryanvi": "Haryanvi",
            "Hausa": "Haoussa",
            "Hawaiian": "Hawaïen",
            "Hebrew": "Hébreu",
            "Hindi": "Hindi",
            "Hmong": "Hmong",
            "Hokkien": "Hokkien",
            "Hopi": "Hopi",
            "Hunanese": "Hunanese",
            "Hungarian": "Hongrois",
            "Ibanag": "Ibanag",
            "Ibibio": "Ibibio",
            "Ibo": "Ibo",
            "Icelandic": "Islandais",
            "Igbo": "Igbo",
            "Ilocano": "Ilocano",
            "Ilonggo": "Ilonggo",
            "Indonesian_Bahasa_Indonesia": "Indonésien (Bahasa Indonesia)",
            "Inuktitut": "Inuktitut",
            "Inupiak": "Inupiak",
            "Iraqi": "Irakien",
            "Irish_Gaelic": "Gaélique irlandais",
            "Italian": "Italien",
            "Italian_Switzerland": "Italien (Suisse)",
            "Ixil": "Ixil",
            "Jakartanese": "Jakartanese",
            "Jamaican_Patois": "Créole jamaïcain",
            "Japanese": "Japonais",
            "Jarai": "Jarai",
            "Javanese": "Javanais",
            "Jju": "Jju",
            "Kachin": "Kachin",
            "Kanjobal": "Kanjobal",
            "Kannada": "Kannada",
            "Kaqchikel": "Kaqchikel",
            "Karen": "Karen",
            "Karenni": "Karenni",
            "Karenni_Kayah_Li": "Karenni (Kayah Li)",
            "Karenni_Latin": "Karenni (Latin)",
            "Karenni_Myanmar": "Karenni (Myanmar)",
            "Kashmiri": "Cachemire",
            "Kayah": "Kayah",
            "Kayah_Latin": "Kayah (Latin)",
            "Kayah_Myanmar": "Kayah (Myanmar)",
            "Kazakh": "Kazakh",
            "Kekchi": "Kekchi",
            "Khmer": "Khmer",
            "Kibembe": "Kibembe",
            "Kifulero": "Kifulero",
            "Kikuyu": "Kikuyu",
            "Kinyamulenge": "Kinyamulenge",
            "Kinyarwanda": "Kinyarwanda",
            "Kirundi": "Kirundi",
            "Kiswahili": "Kiswahili",
            "Kituba": "Kituba",
            "Kizigua": "Kizigua",
            "Konkani": "Konkani",
            "Korean": "Coréen",
            "Kosovan": "Kosovan",
            "Kosraean": "Kosraean",
            "Kpelle": "Kpelle",
            "Krahn": "Krahn",
            "Krio": "Krio",
            "Kunama": "Kunama",
            "Kurdish": "Kurde",
            "Kurdish_Arabic": "Kurde (Arabe)",
            "Kurdish_Cyrillic": "Kurde (Cyrillique)",
            "Kurdish_Latin": "Kurde (Latin)",
            "Kurmanji": "Kurmanji",
            "Kwakwala": "Kwakwala",
            "Kwanyama": "Kwanyama",
            "Kyrgyz": "Kirgiz",
            "Lai_Hakha": "Lai Hakha",
            "Lakota": "Lakota",
            "Lao": "Lao",
            "Latin": "Latin",
            "Latvian": "Letton",
            "Lingala": "Lingala",
            "Lithuanian": "Lituanien",
            "Luganda": "Luganda",
            "Luo": "Luo",
            "Luxembourgish": "Luxembourgeois",
            "Maay_Maay": "Maay Maay",
            "Macedonian": "Macédonien",
            "Madi_Uganda": "Madi (Ouganda)",
            "Malagasy": "Malgache",
            "Malay": "Malais",
            "Malay_Jawi": "Malais (Jawi)",
            "Malayalam": "Malayalam",
            "Maltese": "Maltais",
            "Mam": "Mam",
            "Mandarin": "Mandarin",
            "Mandingo": "Mandingo",
            "Mandinka": "Mandinka",
            "Mandinka_Arabic": "Mandinka (Arabe)",
            "Mandinka_Latin": "Mandinka (Latin)",
            "Manipuri": "Manipuri",
            "Mankon": "Mankon",
            "Maori": "Maori",
            "Marathi": "Marathi",
            "Marshallese": "Marshalleur",
            "Mauritian_Creole": "Créole Mauricien",
            "Memoni": "Memoni",
            "Mende": "Mende",
            "Mi_kmaq": "Mi'kmaq",
            "Michif": "Michif",
            "Mien": "Mien",
            "Mina": "Mina",
            "Mixteco": "Mixteco",
            "Mnong": "Mnong",
            "Mon": "Mon",
            "Mongolian": "Mongole",
            "Mongolian_Cyrillic": "Mongol cyrillique",
            "Montenegrin": "Monténégrin",
            "Montenegrin_Cyrillic": "Monténégrin (cyrillique)",
            "Montenegrin_Latin": "Monténégrin (latin)",
            "Mossi": "Mossi",
            "Nahuatl": "Nahua",
            "Navajo": "Navajo",
            "Nepali": "Népalais",
            "Nigerian_Pidgin_English": "Pidgin English Nigérian",
            "Nordic": "Nordique",
            "Norwegian": "Norvégien",
            "Nuer": "Nuer",
            "Ojibway": "Ojibway",
            "Oriya": "Oriya",
            "Oromo": "Oromo",
            "Palauan": "Paluan",
            "Pampangan": "Pampangan",
            "Papiamento": "Papiamento",
            "Pashai": "Pashai",
            "Pashto": "Pachto",
            "Patois": "Patois",
            "Persian": "Persan",
            "Pidgin_English": "Pidgin English",
            "Pima": "Pima",
            "Pinyin": "Pinyin",
            "Pohnpeian": "Pohnpeian",
            "Polish": "Polonais",
            "Portuguese_Brazil": "Portugais (Brésil)",
            "Portuguese_Guinea_Bissau": "Portugais (Guinée-Bissau)",
            "Portuguese_Portugal": "Portugais (Portugal)",
            "Punjabi": "Pendjabi",
            "Pushtu": "Pushtu",
            "Quechua": "Quechua",
            "Queens_English_UK": "Anglais Queen (Royaume-Uni)",
            "Quiche": "Quiche",
            "Rade": "Rade",
            "Rohingya_Arabic": "Rohingya (Arabe)",
            "Rohingya_Latin": "Rohingya (Latin)",
            "Romani": "Romani",
            "Romanian": "Roumain",
            "Romansh": "Romanche",
            "Russian": "Russe",
            "Saint_Lucian_Creole": "Créole Saint-Lucien",
            "Samoan": "Samoan",
            "Sango": "Sango",
            "Sanskrit": "Sanskrit",
            "Saraiki": "Saraiki",
            "Scottish_Gaelic": "Gaélique écossais",
            "Serbian": "Serbe",
            "Serbian_Cyrillic": "Serbe (cyrillique)",
            "Serbian_Latin": "Serbe (latin)",
            "Sesotho": "Sesotho",
            "Setswana": "Setswana",
            "Shanghainese": "Shanghaïen",
            "Shona": "Shona",
            "Shuswap": "Shuswap",
            "Siberian_Yup_ik": "Yupik sibérien",
            "Sicilian": "Sicilien",
            "Sidaama": "Sidaama",
            "Sindhi": "Sindhi",
            "Sinhala": "Cinghalais",
            "Siswati": "Siswati",
            "Slovak": "Slovaque",
            "Slovenian": "Slovène",
            "Somali": "Somali",
            "Soninke": "Soninké",
            "Sorani": "Sorani",
            "Spanish_Argentina": "Espagnol (Argentine)",
            "Spanish_Chile": "Espagnol (Chili)",
            "Spanish_Colombia": "Espagnol (Colombie)",
            "Spanish_Ecuador": "Espagnol (Équateur)",
            "Spanish_International": "Espagnol (International)",
            "Spanish_Latin_America": "Espagnol (Amérique Latine)",
            "Spanish_Mexico": "Espagnol (Mexique)",
            "Spanish_Panama": "Espagnol (Panama)",
            "Spanish_Puerto_Rico": "Espagnol (Porto Rico)",
            "Spanish_Spain": "Espagnol (Espagne)",
            "Spanish_US": "Espagnol (États-Unis)",
            "Spanish_Venezuela": "Espagnol (Venezuela)",
            "Stoney_Canada": "Stoney (Canada)",
            "Sudanese": "Soudanais",
            "Sundanese": "Soundanais",
            "Swahili": "Swahili",
            "Swedish": "Suédois",
            "Swedish_Finland": "Suédois (Finlande)",
            "Sylheti": "Sylheti",
            "Syriac": "Syriaque",
            "Szechuan": "Szechuan",
            "Tagalog": "Tagalog",
            "Tahitian": "Tahitien",
            "Taiwanese": "Taïwanais",
            "Tajik": "Tadjik",
            "Tamil": "Tamoul",
            "Tatar": "Tatar",
            "Tedim_Chin": "Tedim Chin",
            "Telugu": "Télougou",
            "Temne": "Temne",
            "Tetum": "Tétoum",
            "Thai": "Thaï",
            "Tibetan": "Tibétain",
            "Tigre": "Tigre",
            "Tigrinya": "Tigrinya",
            "Tlingit": "Tlingit",
            "Toishanese": "Toishanais",
            "Tongan": "Tonguien",
            "Trique": "Trique",
            "Tsuut_ina": "Tsuut'ina",
            "Turkish": "Turc",
            "Turkmen": "Turkmène",
            "Twi": "Twi",
            "Uighur": "Ouïghour",
            "Ukrainian": "Ukrainien",
            "Ulster_Scots": "Scots d'Ulster",
            "Urdu": "Ourdou",
            "Uzbek": "Ouzbek",
            "Uzbek_Cyrillic": "Ouzbek (cyrillique)",
            "Uzbek_Latin": "Ouzbek (latin)",
            "Vietnamese": "Vietnamien",
            "Visayan": "Bisaya",
            "Welsh": "Gallois",
            "Wolof": "Wolof",
            "Xhosa": "Xhosa",
            "Yapese": "Yapois",
            "Yaqui": "Yaqui",
            "Yavapai": "Yavapai",
            "Yiddish_other": "Yiddish (autre)",
            "Yiddish_US": "Yiddish (É.-U.)",
            "Yoruba": "Yoruba",
            "Yucateco": "Yucatèque",
            "Yupik": "Yupik",
            "Zarma": "Zarma",
            "Zou": "Zou",
            "Zulu": "Zoulou"
        },
        "third_info_one": "D'autres langues et dialectes peuvent être disponibles. Si vous avez une question concernant la disponibilité des langues",
        "third_info_two": "veuillez nous contacter à l'adresse suivante"
    },
    "document_clarification": {
        "tit": "CLARIFICATION DES DOCUMENTS",
        "main": "Accès linguistique à tous les points de contact grâce à EMLions Clarity",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "sec_one": "EMLions est la plus grande société d'accès linguistique au monde. En plus de proposer des solutions de traduction dans plus de 290 langues, notre équipe Clarity veille à ce que les communications soient, avant tout, simples, claires et compréhensibles en \"anglais courant\".",
        "sec_two": "Nous sommes fiers de combler le fossé de la communication aux quatre coins du monde. Cependant, nous souhaitons prendre en compte le fait que les anglophones ont également besoin d'un meilleur accès à l'information qu'ils consomment en anglais. Si l'on considère que la personne moyenne aux États-Unis a les capacités de lecture d'un élève de 7e année, l'utilisation de l'anglais simple est impérative pour une communication claire, plus accessible et plus concise. C'est pourquoi nous considérons l'anglais simple comme une langue à part entière.",
        "third_tit": "EMLIONS CLARTÉ",
        "third_main": "Communication claire. Communication simple.",
        "third_main_one": "Les documents trop longs, mal organisés et truffés de jargon et de termes d'initiés sont difficiles, voire impossibles à comprendre dans n'importe quelle langue, quelle que soit la qualité de leur traduction.",
        "third_main_two": "En revanche, lorsque les documents numériques et imprimés sont débarrassés du contenu non essentiel et qu'ils sont rédigés et conçus de manière à être parfaitement clairs pour les destinataires, ils sont faciles à comprendre dans toutes les langues. En soi, la clarté peut offrir l'opportunité de renforcer les liens et la confiance entre vous et vos clients.",
        "third_main_three": "Les services de clarté d'EMLions sont totalement différents des autres services de rédaction en langage clair. Au lieu de s'attaquer à une poignée de documents individuels en vase clos, EMLions® réorganise des systèmes documentaires entiers et vous aide à produire un contenu plus léger, plus transparent et plus rentable."
    },
    "document_simplification": {
        "tit": "SIMPLIFICATION DES DOCUMENTS",
        "main": "Atteignez votre public avec un langage clair et simple.",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Qu'est-ce que la simplification des documents ?",
        "sec_main": "Les clients, les patients et les électeurs apprécient les communications simples et directes et y répondent. Lorsqu'ils comprennent ce que vous dites du premier coup, ils sont plus enclins à revenir pour en savoir plus. C'est ce que nos services de simplification des documents peuvent vous aider à réaliser. Nous proposons la macrosimplification et la microsimplification pour améliorer la compréhension et réduire les coûts de traduction.",
        "macro": "MACRO SIMPLIFICATION",
        "third_one_tit": "Simplifier un système documentaire complet",
        "third_one_main": "Réorganisez entièrement votre système documentaire pour obtenir un contenu allégé et plus efficace qui augmente la satisfaction en améliorant la lisibilité et la compréhension à travers tous les points de contact. L'amélioration de votre système documentaire peut également se traduire par des économies et une meilleure performance.",
        "third_two_tit": "Traiter des centaines de documents à la fois",
        "third_two_main_one": "Rationaliser les systèmes de documents imprimés en grand format et les systèmes de documents numériques",
        "third_two_main_two": "Remanier des contenus complexes pour les rendre plus clairs et plus faciles à utiliser",
        "third_two_main_three": "Dimensionner les bibliothèques de documents",
        "third_two_main_four": "Améliorer les processus de communication et l'efficacité",
        "third_three_tit": "Économiser de l'argent et respecter les normes de conformité",
        "third_three_main": "Les projets de macro-simplification peuvent être rentabilisés par l'amélioration de la qualité et la réduction des coûts de production et d'impression. Ils améliorent également l'expérience des clients, des patients et des administrés, renforcent leur satisfaction et vous aident à respecter les normes de conformité.",
        "third_four_tit": "Comment savoir si vous en avez besoin",
        "third_four_main": "Parlez à l'un de nos représentants de la macrosimplification si votre organisation est aux prises avec les problèmes suivants",
        "third_four_main_one": "des informations obsolètes et incohérentes",
        "third_four_main_two": "Un contenu redondant et excessif",
        "third_four_main_three": "Difficulté à atteindre des publics spécifiques",
        "third_four_main_four": "Des communications confuses qui conduisent à des appels de clients",
        "third_four_main_five": "Un manque de gouvernance efficace et de contrôle de la qualité",
        "micro": "MICRO SIMPLIFICATION",
        "four_one_tit": "Rationaliser les documents et les communications individuels",
        "four_one_main": "La microsimplification est un outil précieux pour toute organisation. Le processus comprend",
        "four_one_main_one": "l'analyse du contenu et des recommandations d'amélioration",
        "four_one_main_two": "Suppression des informations inutiles et excessives",
        "four_one_main_three": "Utilisation d'un anglais simple pour les informations essentielles",
        "four_one_main_four": "Création d'un nouveau texte facile à lire et attrayant.",
        "four_two_tit": "Offrir une meilleure expérience au client",
        "four_two_main": "La micro-simplification des documents de marketing peut accroître considérablement leur efficacité et vous aider à respecter les normes de conformité de votre secteur d'activité. Des documents simples et clairs ont plus de chances de convertir les prospects en clients et de les fidéliser à long terme.",
        "four_three_tit": "Améliorer les résultats et réduire les coûts",
        "four_three_main": "La microsimplification est particulièrement utile pour les organisations offrant des services complexes tels que les assurances, les services financiers ou les soins médicaux. Un formulaire simplifié, une facture ou une brochure très visible peuvent avoir un impact considérable sur les résultats et les coûts.",
        "five_tit": "Parlons solutions",
        "five_main": "Nous sommes là pour vous offrir une assistance linguistique experte conçue pour répondre à vos besoins et à votre budget. Demandez un devis maintenant ou parlez à l'un de nos représentants pour examiner vos défis en matière d'accès linguistique et discuter des solutions.",
        "schedule_btn": "PLANIFIER UNE CONSULTATION"
    },
    "audio_transcription": {
        "tit": "TRANSCRIPTION AUDIO",
        "main": "Transformez vos enregistrements audio en atouts précieux",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Utilisez la transcription audio pour stimuler votre activité",
        "sec_one": "Les entreprises d'aujourd'hui collectent de grandes quantités d'informations non seulement dans les communications imprimées et numériques, mais aussi dans leurs enregistrements audio et vidéo. Ces actifs contiennent des informations précieuses qui peuvent améliorer le service à la clientèle, la formation du personnel et le marketing.",
        "sec_two": "Nous pouvons vous aider à transcrire vos enregistrements audio et vidéo. Nous proposons des services de transcription d'audio en texte, de voix-off et de traduction dans plus de 290 langues. Soyez assuré que nos plus de 3 500 linguistes experts mèneront à bien vos projets de transcription avec un résultat de haute qualité, une relecture approfondie et une livraison dans les délais impartis.",
        "transcription": "TRANSCRIPTION",
        "third_one_tit": "Améliorer les performances et la productivité",
        "third_one_main": "De nombreuses entreprises enregistrent les appels au service clientèle à des fins de contrôle de la qualité, mais les écouter peut prendre du temps et nuire à la productivité. Vos enregistrements peuvent vous donner de nouvelles indications sur la manière dont vous servez vos clients, vos patients et vos administrés. De plus, la transcription peut permettre aux responsables de votre centre d'appels d'évaluer plus rapidement les performances de leur personnel et d'intervenir rapidement en leur offrant un coaching.",
        "third_two_tit": "Améliorer l'accessibilité pour tous",
        "third_two_main": "La transcription d'enregistrements audio peut rendre votre contenu plus accessible aux clients et employés sourds ou malentendants. Si votre organisation produit des enregistrements audio à des fins de marketing, d'assistance à la clientèle ou de formation des employés, la transformation de ces enregistrements en contenu imprimé ou numérique les rendra accessibles à tous ceux que vous servez.",
        "third_three_tit": "Atteindre un public mondial",
        "third_three_main": "Les publics du monde entier sont avides de contenus efficaces. Ne vous contentez donc pas de transformer vos enregistrements en communications imprimées ou numériques. Allez plus loin en faisant appel à nos plus de 3 500 linguistes experts, qui peuvent traduire une seule transcription audio dans plus de 290 langues, multipliant ainsi radicalement l'impact de chaque enregistrement que vous avez réalisé.",
        "captioning": "CAPTIONNEMENT",
        "four_one_tit": "Répondre à la demande croissante de vidéos",
        "four_one_main": "Le contenu vidéo représente aujourd'hui plus de 65 % de l'ensemble du trafic Internet, et de nombreux spectateurs préfèrent les vidéos sous-titrées. Certains sont sourds ou malentendants, tandis que d'autres préfèrent regarder des vidéos en public sans le son. En ajoutant des sous-titres à votre contenu vidéo, vous pouvez répondre aux besoins de millions de personnes.",
        "four_two_tit": "Mieux servir des populations diverses",
        "four_two_main": "Le sous-titrage du contenu vidéo vous aide à",
        "four_two_main_one": "d'améliorer l'accessibilité pour les populations DHH ou celles dont la maîtrise de l'anglais est limitée",
        "four_two_main_two": "stimuler l'engagement en augmentant les chances que les spectateurs regardent votre contenu jusqu'à la fin",
        "four_two_main_three": "attirer de nouveaux publics et des groupes démographiques de plus en plus nombreux.",
        "four_three_tit": "Comptez sur nos experts linguistes",
        "four_three_main": "Nos professionnels sont des experts en sous-titrage. Nous avons aidé des milliers d'entreprises, d'organismes de santé, d'agences gouvernementales et bien d'autres à étendre leur portée tout en étant plus inclusifs et accessibles aux populations qu'ils servent.",
        "five_tit": "Parlons solutions",
        "five_main": "Nous sommes prêts à fournir des services de transcription audio experts qui répondent à vos besoins et à votre budget. Vous pouvez consulter notre brochure sur la traduction et la localisation ou choisir un moment pour parler à nos experts afin de résoudre vos problèmes d'accès linguistique.",
        "five_download_btn": "TELECHARGER MAINTENANT"
    },
    "alternative_format": {
        "tit": "TRANSCRIPTION EN FORMAT ALTERNATIF",
        "main": "Établir des relations avec les populations malvoyantes",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Assurez l'égalité d'accès à vos produits et services",
        "sec_one": "Plus de 32 millions d'adultes américains déclarent avoir subi une perte de vision ou une cécité importante. Il peut s'agir de vos clients, de vos patients, de vos administrés ou de vos employés. Lorsque vous répondez à leurs besoins en présentant des documents écrits en braille, en gros caractères ou dans d'autres formats alternatifs, vous garantissez l'égalité d'accès et permettez de meilleurs résultats.",
        "sec_two": "Nous pouvons transcrire votre contenu pour qu'il ait un impact maximal sur les personnes aveugles et malvoyantes. Notre technologie avancée nous permet de réaliser vos projets rapidement et à moindre coût, tout en maintenant les plus hauts niveaux de qualité.",
        "third_tit": "BRAILLE ET GROS CARACTÈRES",
        "third_main": "Répondre aux besoins des populations malvoyantes",
        "third_main_exp": "Nous pouvons vous aider à fournir des documents écrits en braille et en gros caractères dans un grand nombre de langues. De plus, les gros caractères peuvent améliorer vos documents numériques et votre site web. Notre équipe de rédacteurs professionnels vous garantit des résultats de haute qualité.",
        "four_tit": "TRANSCRIPTION AUDIO",
        "four_main": "Transcrire l'audio pour améliorer vos services et votre activité",
        "four_main_exp": "Vos enregistrements audio contiennent probablement des informations essentielles qui peuvent améliorer votre service client et la formation de votre personnel. De plus, ils peuvent servir de base à de nouveaux supports marketing. Nous vous déchargeons du travail de transcription des enregistrements grâce à la transcription audio en texte, à la voix off et à la traduction dans plus de 290 langues.",
        "four_btn": "EN SAVOIR PLUS",
        "five_tit": "Parlons de solutions",
        "five_main": "Nous fournissons des services de transcription de formats alternatifs de haute qualité, adaptés à votre projet et à votre budget. Discutez avec l'un de nos experts pour résoudre vos problèmes d'accès linguistique et garantir l'accessibilité pour tous.",
        "six_tit": "Améliorer l'accessibilité grâce à une technologie de traduction avancée",
        "six_one_tit": "TRANSCRIPTION AUDIO",
        "six_one_contents": "Mieux servir les personnes aveugles et malvoyantes grâce à des versions audio de votre contenu écrit et de vos informations.",
        "six_two_tit": "DAISY",
        "six_two_contents": "Améliorez la convivialité et l'accessibilité de votre contenu grâce à l'exportation au format DAISY (Digital Accessible Information System).",
        "six_three_tit": "MOON",
        "six_three_contents": "Transcrivez votre contenu dans Moon pour le rendre plus utilisable et plus percutant pour les malvoyants.",
        "six_four_tit": "E-TEXT",
        "six_four_contents": "Convertissez votre contenu en E-Text pour permettre à un logiciel de l'agrandir, de le lire à haute voix et de le reproduire sur un afficheur braille actualisable.",
        "six_five_tit": "LECTURE FACILE",
        "six_five_contents": "Combinez le texte et les images pour offrir un contenu plus facile à lire aux personnes ayant des difficultés d'apprentissage."
    },
    "translate": {
        "tit": "SERVICES DE TRADUCTION",
        "main": "Renforcer les relations par une compréhension totale",
        "main_exp": "Obtenez l'expertise dont vous avez besoin pour communiquer clairement à n'importe quelle échelle.",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION",
        "sec_tit": "Améliorer la qualité de la traduction dans plus de 290 langues",
        "sec_one": "Plus de 67 millions d'habitants des États-Unis parlent une langue autre que l'anglais à la maison. Dialoguer avec des personnes maîtrisant mal l'anglais dans la langue de leur choix n'est pas seulement une bonne pratique, c'est la clé de la croissance. Des études montrent que 92 % des personnes achètent des produits vendus dans leur langue préférée et que 91 % d'entre elles renouvellent leurs achats.",
        "sec_two": "La plupart des prestataires de services de traduction sont limités en termes d'effectifs, d'expertise et de qualité, ce qui vous oblige à chercher ailleurs pour certains projets. Plutôt que de travailler avec un réseau de services de niche, vous pouvez vous adresser à un seul fournisseur, fort de plus de 25 ans d'expérience, de plus de 3 500 linguistes experts et de normes de qualité éprouvées, étayées par de multiples certifications ISO.",
        "sec_three": "Les experts en traduction et localisation d'EMLions® prennent en charge tous les types de contenus et toutes les tailles de projets dans plus de 290 langues. Notre technologie innovante permet d'améliorer l'efficacité des flux de travail tout en offrant un service aussi rapide que possible. EMLions est prêt à vous aider :",
        "sec_four": "Traduire des documents, des supports marketing, des formulaires, des communications et des guides pratiques.",
        "sec_five": "Localiser des sites Web, du matériel, des logiciels, du contenu de jeux et de l'apprentissage en ligne.",
        "sec_six": "Automatiser la traduction et la localisation de votre contenu",
        "third_one": "Des linguistes professionnels au service de votre organisation",
        "third_two": "Taux de précision des traductions de contenu d'EMLions",
        "third_three": "Taux de respect des délais de livraison des projets de traduction d'EMLions",
        "four_one_tit": "Traduction de documents",
        "four_one_contents": "Obtenez des traductions de documents de haute qualité, adaptées à l'utilisation prévue, au public visé et à la durée de conservation.",
        "four_two_tit": "Services de localisation",
        "four_two_contents": "Localisez vos contenus imprimés et numériques en fonction de la langue et des sensibilités culturelles d'un pays donné, afin que votre public cible en fasse une expérience naturelle.",
        "four_three_tit": "Outils de traduction intégrés",
        "four_three_contents": "Obtenez des résultats plus rapides grâce à notre technologie innovante qui élimine les obstacles à la traduction et réduit les erreurs humaines.",
        "four_four_tit": "Traduction automatique",
        "four_four_contents": "La technologie \" best fit \" d'EMLions, basée sur l'IA, permet des délais d'exécution rapides pour les contenus à grande échelle et à faible responsabilité, au coût le plus bas.",
        "four_five_tit": "Services eLearning",
        "four_five_contents": "Transformez votre contenu eLearning en différentes langues afin d'accroître la productivité de votre organisation.",
        "four_six_tit": "Traduction de sites web",
        "four_six_contents": "Website Proxy permet de produire et de gérer sans intervention les versions traduites de votre site Web en anglais, ou de synchroniser en permanence la version anglaise et les versions traduites.",
        "four_sevent_tit": "Langues de traduction",
        "four_sevent_contents": "Découvrez l'expertise et la qualité que nos traducteurs offrent dans plus de 290 langues.",
        "five_tit": "Parlons solutions",
        "five_main": "Nos linguistes professionnels sont prêts à vous fournir des services de traduction et de localisation adaptés à votre budget. Demandez dès maintenant un devis pour votre prochain projet ou parlez à l'un de nos experts en solutions de traduction EMLions.",
        "quote_btn": "DEMANDER LE PRIX"
    },
    "other_translate": {
        "tit": "AUTRES SERVICES LINGUISTIQUES",
        "main": "Faites en sorte que chaque mot compte",
        "main_exp": "Aidez votre organisation à communiquer de manière claire et concise afin de maximiser l'impact de tous vos documents et contenus.",
        "quote_btn": "DEMANDER LE PRIX",
        "sec_tit": "Communiquer efficacement avec un langage clair et plus encore",
        "sec_one": "L'adulte américain moyen lit au niveau de la septième année. La plupart des lecteurs se perdent lorsqu'ils sont confrontés à un contenu complexe, à une terminologie peu familière et au jargon du secteur. Si votre organisation utilise un langage trop compliqué, vous risquez de créer une mauvaise expérience client, de semer la confusion et d'augmenter les coûts.",
        "sec_two": "En tant que premier partenaire d'accès linguistique au monde, nous proposons des solutions de traduction dans plus de 290 langues. Aujourd'hui, la norme d'excellence pour une communication efficace est le Plain English, que nous considérons comme une langue à part entière. Nous pouvons vous aider à simplifier vos documents, afin de faire passer votre message et de toucher tous vos publics.",
        "third_one_tit": "Clarification des documents",
        "third_one_contents": "Nous réécrivons les documents destinés au public afin qu'ils soient plus faciles à comprendre et moins coûteux à traduire dans d'autres langues.",
        "third_two_tit": "Simplification des documents",
        "third_two_contents": "Nous identifions le contenu de base utile à tous les publics et nous le simplifions pour qu'il ait plus d'impact.",
        "third_three_tit": "Transcription audio",
        "third_three_contents": "Nous pouvons transcrire vos vidéos et les traduire dans la langue de votre choix. De plus, nous recherchons et enregistrons des artistes de doublage.",
        "third_four_tit": "Transcription en formats alternatifs",
        "third_four_contents": "Nous transcrivons des contenus en braille, y compris en anglais et en espagnol.",
        "four_tit": "Solutions de conversation",
        "four_main": "Nous sommes là pour vous apporter une assistance linguistique experte, conçue pour répondre à vos besoins et à votre budget. Demandez un devis maintenant ou parlez à l'un de nos représentants pour examiner vos défis en matière d'accès linguistique et discuter des solutions.",
        "schedule_btn": "PRENDRE RENDEZ-VOUS POUR UNE CONSULTATION"
    },
    "footer": {
        "interpretation": "Traduction orale",
        "testing_and_training": "Tests & Formation",
        "industries": "les industries",
        "careers": "Carrières",
        "resources": "Ressources",
        "customer_service": "Service client",
        "contact_info": "Contactez-nous à",
        "corporate_info": "© EMLions Solutions 2024. Tous droits réservés.",
        "sitemap": "Plan du site",
        "privacy_policy": "Politique de confidentialité",
        "cookie_policy": "Politique en matière de cookies",
        "global_ethics_hotline": "Ligne d'assistance éthique mondiale"
    }
}