import Vue from "vue";
import VueRouter from "vue-router";
import Meta from 'vue-meta';
// import i18n from '../i18n'
Vue.use(VueRouter)
Vue.use(Meta)

const routes=[
    {
        path:'/',
        name:"indexPage",
        component:()=> import('../view/indexPage.vue')
    },
    {
        path:'/about',
        name:"aboutPage",
        component:()=> import('../view/aboutPage.vue')
    },
    {
        path:'/solutions',
        name:'solutionsPage',
        component:()=>import('../view/solutionsPage.vue')
    },
    {
        path:'/translation',
        component:()=>import('../view/translationPage.vue'),
        children:[
            {
                path:'',
                name:'translationServer',
                component:()=>import('../view/translation/translationServer.vue')
            },
            {
                path:'documentTranslation',
                name:'documentTranslation',
                component:()=>import('../view/translation/documentTranslation.vue')
            },
            {
                path:'localization-services',
                name:'localizationServices',
                component:()=>import('../view/translation/localizationServices.vue')
            },
            {
                path:'integrated-translation',
                name:'integratedTranslation',
                component:()=>import('../view/translation/integratedTranslation.vue')
            },
            {
                path:'machine-translation',
                name:'machineTranslation',
                component:()=>import('../view/translation/machineTranslation.vue')
            },
            {
                path:'elearning-services',
                name:'elearningServices',
                component:()=>import('../view/translation/eLearningServices.vue')
            },
            {
                path:'website-translation',
                name:'websiteTranslation',
                component:()=>import('../view/translation/websiteTranslation.vue')
            },
            {
                path:'translation-languages',
                name:'translationLanguages',
                component:()=>import('../view/translation/translationLanguages.vue')
            }
        ]
    },
    {
        path:'/other-language-services',
        component:()=>import('../view/otherServices.vue'),
        children:[
            {
                path:'',
                name:'otherLanguageServices',
                component:()=>import('../view/otherServices/otherLanguageServices.vue')
            },
            {
                path:'document-clarification',
                name:'documentClarification',
                component:()=>import('../view/otherServices/documentClarification.vue')
            },
            {
                path:'document-simplification',
                name:'documentSimplification',
                component:()=>import('../view/otherServices/documentSimplification.vue')
            },
            {
                path:'audio-transcription',
                name:'audioTranscription',
                component:()=>import('../view/otherServices/audioTranscription.vue')
            },
            {
                path:'alternative-format-transcription',
                name:'alternativeFormatTranscription',
                component:()=>import("@/view/otherServices/alternativeFormatTranscription.vue")
            }
        ]
    }
]

const router=new VueRouter({
    mode:'history',
    base:process.env.BASE_URL,
    routes
})

export default router