export default{
    "lang": "Español",
    "home": {
        "main_explain": "Liderando el mundo a través de la tecnología digital, creando valor con servicios orientados al sector Acelerar la transformación digital para cada uno de nuestros clientes a través de ideas punteras y una ejecución ágil.",
        "contact_btn": "Póngase en contacto",
        "read_me_btn": "Más información",
        "second_tit": "Potenciar la era digital Creando valor juntos",
        "second_main": "Imagine un mundo en el que ya no existan las barreras lingüísticas y culturales, en el que la comprensión sea universal y todo el mundo esté capacitado.",
        "second_expand": "Le ayudaremos a servir a los miembros de su comunidad en el momento en que lo necesiten, independientemente de su idioma, cultura o capacidad.",
        "second_learn_more": "Póngase en contacto con nosotros para obtener más información",
        "second_main_tips_one": "Localización y traducción de contenidos",
        "second_main_tips_two": "Gestión de proyectos de traducción",
        "second_main_tips_three": "Mejorar la calidad de la traducción",
        "second_main_tips_four": "Aumento de la productividad del equipo",
        "second_main_tips_five": "Potenciación de la experiencia mediante IA",
        "second_main_tips_six": "Localización de aplicaciones y software",
        "third_tit": "Destaque en el mercado global con nuestros servicios de traducción",
        "third_main": "Permitimos a nuestros clientes llegar a nuevos mercados en todo el mundo conectando con su público y ofreciendo la mejor experiencia posible al cliente, en cualquier idioma. Somos un socio que puede llevarle de la planificación a la ejecución a través de nuestros servicios, tecnología, consultoría y experiencia en el sector.",
        "third_btn": "Más información",
        "four_tit": "¿Cómo podemos ayudarle a tener éxito?",
        "four_main": "Nuestras soluciones a medida le ayudan a llegar a estos nuevos mercados de forma rápida, eficiente y eficaz, y de un modo que maximiza los beneficios para su empresa.",
        "four_main_one_tit": "LOCALIZACIÓN Y TRADUCCIÓN DE CONTENIDOS",
        "four_main_one_content": "Amplíe su contenido global y maximice su impacto local con la ayuda de nuestras tecnologías inteligentes.",
        "four_main_two_tit": "GESTIÓN DE PROYECTOS DE TRADUCCIÓN",
        "four_main_two_content": "Amplíe, agilice y automatice fácilmente el proceso de localización con la plataforma de gestión de la traducción.",
        "four_main_three_tit": "MEJORAR LA CALIDAD DE LA TRADUCCIÓN",
        "four_main_three_content": "Proteja la reputación de la marca con comprobaciones lingüísticas que garanticen que el contenido es claro y no contiene errores.",
        "four_main_four_tit": "LOCALIZACIÓN DE APLICACIONES Y SOFTWARE",
        "four_main_four_content": "Asegúrate de que los productos se adaptan perfectamente y se presentan de forma impecable en todos los mercados con nuestra completa gama de servicios de localización.",
        "four_main_five_tit": "AUMENTAR LA PRODUCTIVIDAD DEL EQUIPO",
        "four_main_five_content": "Proporcione a los traductores y gestores de proyectos las herramientas para trabajar de forma más inteligente con el software de traducción asistida por ordenador (TAO) más fiable.",
        "four_main_six_tit": "POTENCIAR LA EXPERIENCIA MEDIANTE AI",
        "four_main_six_content": "Responda a las preguntas de los clientes a una fracción del coste y con la rapidez suficiente para convertirlas en acciones relevantes y oportunas gracias a la inteligencia artificial.",
        "five_tit": "Explore hoy mismo los servicios para empresas.",
        "five_main": "Consigamos juntos sus objetivos más ambiciosos.",
        "six_tit": "¿Quién utiliza EMLIONS?",
        "six_main": "Soluciones de transformación de contenidos y datos a escala global reconocidas por clientes, traductores y organizaciones del sector.",
        "six_main_find": "Obtenga más información."
    },
    "contact_btn": "Contacte con nosotros",
    "translation": {
        "nav": "Traducción",
        "menu_doc": "Traducción de documentos",
        "menu_localization": "Servicios de localización",
        "menu_tools": "Herramientas de traducción integradas",
        "menu_machine": "Traducción automática",
        "menu_elearning": "Servicios eLearning",
        "menu_website": "Traducción de sitios web",
        "menu_languages": "Idiomas de traducción"
    },
    "other_language_services": {
        "nav": "Otros servicios lingüísticos",
        "menu_clarification": "Aclaración de documentos",
        "menu_simplification": "Simplificación de documentos",
        "menu_audio": "Transcripción de audio",
        "menu_alternative": "Transcripción de formatos alternativos"
    },
    "about": {
        "title_fir": "Quiénes somos",
        "title_sed": "Proveedor de servicios de TI",
        "title_explain_fir": "Socio de confianza de 157 clientes de Fortune 500.",
        "title_explain_sec": "Nuestra fuerza es nuestra gente, y somos algo más que una empresa de traducción. Somos una familia de personas comprometidas y dedicadas a ser el principal facilitador mundial de las comunicaciones globales.",
        "title_num_one": "Clientes de Fortune 500",
        "title_num_two": "años de experiencia",
        "title_num_three": "traductores profesionales",
        "second_title": "Le aportamos poderosas ventajas para navegar por su transformación digital",
        "second_one_tit": "Disposición global del negocio y capacidad de servicio operativo",
        "second_one_main": "EMLIONS cuenta con 3.500 profesionales en todo el mundo, distribuidos en 50 centros de entrega en 28 ciudades. Nos comprometemos a proporcionar ofertas en más de 200 idiomas, soporte técnico 7x24 y prestación de servicios a clientes de todo el mundo.",
        "second_two_tit": "Acelerar la innovación gracias a una gran experiencia en el sector",
        "second_two_main": "Con 25 años de experiencia en el sector, Emlions ofrece a sus clientes de todo el mundo servicios que abarcan la consultoría digital, la interacción de experiencias, la implementación de tecnología y las operaciones. Gracias a su amplia experiencia en sectores como la alta tecnología, las finanzas, la fabricación, las telecomunicaciones e Internet, Emlions ha establecido relaciones de cooperación a largo plazo con 157 empresas incluidas en la lista Fortune 500.",
        "second_three_tit": "Impulsando mejoras de calidad con una seguridad y una gestión de calidad probadas",
        "second_three_main": "Desde que adoptó la norma del sistema de gestión de calidad ISO 9001 en 2000, Emlions ha obtenido las certificaciones CMMI L5, ISO 20000 e ISO 27001. Cumplimos y superamos constantemente las expectativas del cliente con un sistema de control de calidad que cumple con los estándares internacionales en términos de seguridad y calidad de las instalaciones, el personal y los procedimientos.",
        "second_four_tit": "Impulsar la era digital y crear valor para los clientes",
        "second_four_main": "Emlions se compromete a acelerar el despliegue de capacidades digitales a través de productos y servicios innovadores orientados a la experiencia del cliente y marketing digital integrado, y mediante la integración de consultoría estratégica, creatividad, diseño de experiencias, etc., a través de dispositivos móviles, IoT, computación en la nube, big data y otros. tecnologías significa brindar a los clientes excelentes servicios digitales y ayudarlos en su transformación digital."
    },
    "solutions": {
        "nav": "SOLUCIONES",
        "tit_fir": "Industria",
        "tit_sec": "Soluciones",
        "main_fir": "Como expertos comunicadores, sabemos que el contexto lo es todo. Por eso contamos con equipos especializados con amplia experiencia en casi todos los sectores industriales.",
        "main_sec": "Esta experiencia personalizada le brinda la tranquilidad de saber que su contenido global es efectivo y cumple con los requisitos normativos, terminológicos y de formato específicos.",
        "get_in_touch": "Póngase en contacto con nosotros",
        "read_more": "Más información",
        "sec_tit": "¿Cómo podemos ayudarle a tener éxito?",
        "sec_main": "Nuestras soluciones a medida le ayudan a llegar a estos nuevos mercados de forma rápida, eficiente y eficaz, y de un modo que maximiza los beneficios para su empresa.",
        "learn_more": "Más información",
        "sec_one_tit": "Ciencias de la vida",
        "sec_one_main": "Consiga una mayor eficacia en los procesos, reduzca los costes de traducción y acelere la salida al mercado con nuestras soluciones lingüísticas, de COA y de contenido especializadas para las ciencias de la vida, la investigación médica y las organizaciones de investigación por contrato (CRO).",
        "sec_two_tit": "Ley",
        "sec_two_main": "Podemos ayudarle a resolver los retos de contenido multilingüe en todas las prácticas jurídicas para que pueda seguir el ritmo de una carga de trabajo de traducción en rápido crecimiento, tanto si gestiona datos de e-discovery, rellenos de fusiones y adquisiciones (M&amp;A), documentos de propiedad intelectual, contratos y acuerdos, materiales de marketing y mucho más.",
        "sec_three_tit": "Soluciones gubernamentales",
        "sec_three_main": "Cumpla los requisitos gubernamentales de contenido multilingüe con nuestras soluciones profesionales de gestión lingüística y de contenidos para el sector público, los servicios de inteligencia, la defensa y los organismos encargados de hacer cumplir la ley.",
        "sec_four_tit": "Servicios financieros",
        "sec_four_main": "Nuestras soluciones lingüísticas y de contenidos satisfacen las necesidades específicas de las organizaciones de servicios financieros para que pueda crear las mejores relaciones posibles con sus clientes en cualquier idioma y en todo el mundo.",
        "sec_five_tit": "Atención sanitaria gestionada",
        "sec_five_main": "Comuníquese con confianza superando las barreras lingüísticas y de capacidad. Nuestras soluciones y experiencia le ayudan a satisfacer sus necesidades de traducción y cumplimiento durante todo el año, no sólo durante el Periodo de inscripción anual (AEP).",
        "sec_six_tit": "Aeroespacial y defensa",
        "sec_six_main": "Cumpla las rigurosas exigencias tanto de los aviones comerciales como de los equipos de defensa, y mejore la productividad y la eficacia durante todo el ciclo de vida del producto, incluido el mantenimiento posterior a la entrega, con nuestra suite de publicación para S1000D.",
        "sec_sevent_tit": "Fabricación industrial",
        "sec_sevent_main": "Nuestras completas soluciones lingüísticas y de contenido pueden ayudarle a satisfacer las necesidades de toda la cadena de valor mediante la adopción de prácticas de software ágiles y maduras, al tiempo que se reducen los costes y se aumenta la eficacia.",
        "sec_eight_tit": "Software de alta tecnología",
        "sec_eight_main": "Responda a las demandas del mercado y a la mayor velocidad de los productos con nuestras soluciones lingüísticas y de contenido especializadas para software y hardware empresarial, servicios de TI, redes sociales, juegos, aplicaciones en línea, multimedia, aplicaciones de productividad, computación en la nube y dispositivos móviles.",
        "sec_nine_tit": "Comercio minorista y electrónico",
        "sec_nine_main": "Los servicios lingüísticos y las tecnologías de Emlions preparan a los minoristas para el éxito global con estrategias de localización de comercio electrónico que satisfacen las necesidades de su público objetivo.",
        "sec_ten_tit": "Viajes, ocio y hostelería",
        "sec_ten_main": "Nuestras soluciones integrales de contenido multilingüe para proveedores, distribuidores y minoristas por igual, le ayudarán a crear, traducir y entregar grandes bases de datos de contenido de inventario, al tiempo que reduce costes, mantiene la satisfacción del consumidor y cumple la normativa de protección de datos del consumidor.",
        "sec_elevent_tit": "Automoción",
        "sec_elevent_main": "Gestione su contenido multilingüe de marketing, productos y servicios en todos los canales digitales, para clientes potenciales, clientes, el vehículo conectado y una organización de posventa distribuida globalmente.",
        "sec_twelve_tit": "Servicios de globalización para el sector químico",
        "sec_twelve_main": "Nuestros clientes del sector químico confían en nosotros para proporcionar servicios de traducción, interpretación y propiedad intelectual a través de lingüistas altamente especializados y expertos técnicos con experiencia en el sector y conocimiento de las normas y reglamentos internacionales.",
        "sec_Thirteen_tit": "Traducción multilingüe en el sector energético",
        "sec_Thirteen_main": "Las empresas del sector energético se enfrentan a la aceleración de la demanda mundial y a nuevos actores en mercados cada vez más competitivos. El apoyo global a la propiedad intelectual y una comunicación multilingüe eficaz y coherente son fundamentales.",
        "sec_fourteen_tit": "Servicios de traducción de seguros",
        "sec_fourteen_main": "Nuestra experiencia garantiza que los mensajes produzcan resultados. Sólo empleamos traductores e intérpretes profesionales, muchos de los cuales son ex profesionales, corredores, aseguradores, administradores de riesgos y miembros del Chartered Insurance Institute.",
        "sec_fifteen_tit": "Servicios de localización de medios",
        "sec_fifteen_main": "En Emlions, nuestro enfoque integral del contenido global, la localización y la tecnología le ayuda a seguir siendo relevante en el mundo digital.",
        "sec_sixteen_tit": "Servicios de localización de telecomunicaciones",
        "sec_sixteen_main": "El panorama actual de las telecomunicaciones, altamente competitivo, está empujando a las empresas a ampliar no sólo la escala y el alcance de sus ofertas, sino también su alcance global."
    },
    "doc_translation": {
        "tit": "TRADUCCIÓN DE DOCUMENTOS",
        "main": "Fortalezca las relaciones con sus clientes en más de 290 idiomas",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Deje que nuestros expertos resuelvan todas sus necesidades de traducción",
        "sec_one": "Sus proyectos de traducción no se limitan a un solo idioma, canal o plazo. Obtenga la experiencia que necesita para comunicarse con claridad a cualquier escala y transformar sus retos lingüísticos en oportunidades, con soluciones creativas que mejoran la eficiencia y fomentan relaciones significativas.",
        "sec_two": "Nuestros más de 3.500 lingüistas expertos ofrecen traducciones de alta calidad y muy personalizadas para el uso y el público al que van dirigidas. Además, someten cada proyecto a un riguroso proceso de traducción, revisión y corrección para garantizar que el resultado final supere sus expectativas.",
        "trird_one": "Lingüistas profesionales",
        "third_two": "Idiomas disponibles para la traducción",
        "third_three": "Calidad de contenidos garantizada",
        "four_tit": "Traducimos cualquier tipo de contenido a más de 290 idiomas",
        "four_one_tit": "Traducción de documentos",
        "four_one_contents": "Cartas, formularios, correos electrónicos, manuales técnicos, contenidos en línea, documentos CMS, material de marketing y políticas y procedimientos de RR.HH.",
        "four_two_tit": "Sitios web",
        "four_two_contents": "HTML, ASP, scripts, aplicaciones basadas en Java e interfaces basadas en bases de datos.",
        "four_three_tit": "Multimedia",
        "four_three_contents": "Ingeniería Flash, voz en off y películas de impacto.",
        "four_four_tit": "Materiales de formación",
        "four_four_contents": "Tutoriales, plantillas, aplicaciones eLearning y ERP.",
        "four_five_tit": "Software",
        "four_five_contents": "Interfaz gráfica de usuario, contenido de bases de datos, dispositivos inalámbricos, aplicaciones web y sistemas de procesos empresariales.",
        "four_six_tit": "Sistemas de ayuda y gestión de contenidos",
        "four_six_contents": "WinHelp, HTML Help, WebHelp, JavaHelp, Oracle Help, FlashHelp, AppleHelp, HubSpot, WordPress, Drupal y sistemas propietarios.",
        "five_tit": "Traducción en todos los sectores",
        "five_one_tit": "Negocio:",
        "five_one_contents": "Información sobre productos, comunicación con clientes y material para empleados",
        "five_two_tit": "Salud médica:",
        "five_two_contents": "Documentos vitales, instrucciones de alta, avisos de elegibilidad y formularios para pacientes.",
        "five_three_tit": "Servicios financieros:",
        "five_three_contents": "Documentos de préstamos, contratos, documentos hipotecarios, solicitudes financieras, extractos de cuenta e informes crediticios.",
        "five_five_tit": "Seguros:",
        "five_five_contents": "Formularios de reclamaciones, información sobre pólizas e informes de accidentes.",
        "five_six_tit": "Administración pública:",
        "five_six_contents": "Notificaciones de derechos, formularios de consentimiento, quejas, cartas que requieren respuesta, solicitudes y divulgación pública",
        "six_tit": "La diferencia de EMLions Translation",
        "six_one_tit": "Traductores humanos",
        "six_one_contents": "Utilizamos un toque humano y comprensión cultural para asegurar que cada trabajo transmita el verdadero significado de su contenido original.",
        "six_two_tit": "Personalización",
        "six_two_contents": "Nos adaptamos a cada proyecto teniendo en cuenta sus necesidades, plazos y flujos de trabajo.",
        "six_three_tit": "Garantía de calidad al 100",
        "six_three_contents": "Cada proyecto es traducido, corregido y revisado por al menos dos lingüistas profesionales.",
        "six_four_tit": "Entrega puntual",
        "six_four_contents": "Nuestro porcentaje de entrega puntual en la traducción de documentos es del 99,5%.",
        "sevent_tit": "Más información sobre los servicios de traducción de documentos",
        "sevent_contents": "Descubra cómo obtener ayuda para ofrecer contenidos coherentes y de alta calidad en cualquier idioma. Puede leer nuestro folleto sobre traducción y localización o solicitar un presupuesto a nuestros expertos.",
        "download_btn": "DESCARGAR AHORA"
    },
    "local_services": {
        "tit": "SERVICIOS DE LOCALIZACIÓN",
        "main": "Establezca relaciones más sólidas en más de 290 idiomas",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Aumente la calidad de la localización y reduzca costes",
        "sec_one": "No hay límite para el número de personas a las que puede llegar su contenido digital, pero la mayoría de los clientes prefieren comprar productos que se comuniquen en su idioma. Asegúrese de transmitir su mensaje de forma coherente en todo el mundo trabajando con un socio con la experiencia y la escala necesarias para gestionar todas sus necesidades de contenido y proyectos de cualquier envergadura.",
        "sec_two": "Nuestros servicios de traducción y localización están a cargo de más de 3.500 lingüistas expertos preparados para producir resultados coherentes y de alta calidad en más de 290 idiomas. Localizaremos su contenido impreso o digital para adaptarlo a las sensibilidades lingüísticas y culturales de un lugar concreto, de modo que resulte una experiencia natural para su público objetivo. Además, para garantizar una entrega a tiempo, aplicamos procedimientos de control de calidad integrales en todos los proyectos.",
        "third_one": "Lingüistas profesionales",
        "third_two": "Idiomas disponibles para la traducción",
        "third_three": "Índice de precisión de la traducción",
        "four_tit": "SERVICIOS DE LOCALIZACIÓN",
        "four_main": "Preste un mejor servicio a sus clientes, pacientes y electores",
        "four_main_exp": "Nuestros profesionales pueden localizar cualquier tipo de contenido impreso o digital, incluidos:",
        "four_main_exp_one": "Sitios web, aplicaciones y software",
        "four_main_exp_two": "Contenido de vídeos, subtítulos, gráficos y voz en off",
        "four_main_exp_three": "Documentos y materiales de marketing",
        "four_main_exp_four": "eLearning y formación de miembros del equipo",
        "four_main_exp_five": "Documentación reglamentaria y de cumplimiento",
        "four_main_exp_six": "Videojuegos y mucho más",
        "five_tit": "CONECTORES CMS",
        "five_main": "Automatice sus transferencias de archivos",
        "five_main_exp": "EMLions ha reimaginado la traducción desde la perspectiva de nuestros clientes, integrando la automatización multilingüe directamente en los sistemas de contenidos y aplicaciones empresariales, lo que permite a nuestros clientes enviarnos fácilmente cualquier contenido. Elija cualquiera de nuestros más de 50 conectores listos para usar para aumentar la eficacia de la transferencia de archivos y los flujos de trabajo de publicación.",
        "five_btn": "CONOZCA MÁS",
        "six_tit": "PROXY DE PÁGINAS WEB",
        "six_main": "Traduzca fácilmente su sitio web",
        "six_main_exp": "Agilice el proceso de producción y gestión de versiones traducidas de su sitio web o plataforma de comercio electrónico en inglés. Con EMLions® Website Proxy, las versiones traducidas de su sitio están siempre sincronizadas con la versión en inglés. Además, si carece de los recursos para alojar un sitio traducido, Website Proxy le ayuda a reflejar su contenido y mostrarlo en el idioma de su elección.",
        "six_btn": "MÁS INFORMACIÓN",
        "sevent_tit": "Más información sobre las herramientas de traducción integradas",
        "sevent_main": "Ofrezca contenidos coherentes y de alta calidad en cualquier idioma sin sacrificar la precisión. Puede leer nuestro folleto Traducción y localización o elegir una hora para hablar con nuestros expertos sobre cómo resolver sus problemas de traducción.",
        "sevent_download_btn": "DESCARGAR AHORA",
        "sevent_schedule_btn": "PROGRAMAR CONSULTA",
        "eigth_tit": "Servicios de localización de EMLions",
        "eigth_one_tit": "TRADUCTORES HUMANOS",
        "eigth_one_contents": "Utilizamos el toque humano y la comprensión cultural para garantizar que cada trabajo transmita el significado de su contenido original.",
        "eigth_two_tit": "PERSONALIZACIÓN",
        "eigth_two_contents": "Ofrecemos tecnología punta y experiencia lingüística para adaptarnos a las necesidades cambiantes de su proyecto.",
        "eigth_three_tit": "100% DE GARANTÍA DE CALIDAD",
        "eigth_three_contents": "Cada proyecto es traducido, corregido y revisado por al menos dos lingüistas.",
        "eigth_four_tit": "ENTREGA PUNTUAL",
        "eigth_four_contents": "Nunca hemos incumplido una fecha de entrega."
    },
    "integrated_translation": {
        "tit": "HERRAMIENTAS DE TRADUCCIÓN INTEGRADAS",
        "main": "Acelere la traducción sin sacrificar la precisión",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "sec_tit": "Ponga la tecnología de traducción a su alcance",
        "sec_one": "Tanto si está expandiendo sus programas de marketing por todo el mundo, traduciendo información confidencial de pacientes u ofreciendo material educativo en nuevos idiomas, necesita traducciones de alta calidad que se ajusten a sus necesidades y a su presupuesto. El uso de la tecnología de traducción adecuada puede agilizar muchos de los pasos del proceso, permitiéndole producir materiales precisos en un plazo más corto y a un coste menor.",
        "sec_two": "Ofrecemos tecnología de traducción que hace que el proceso sea lo más directo, fácil y seguro posible. Utilice nuestro Portal de Traducción EMLions® cifrado de extremo a extremo para enviar rápidamente proyectos a nuestros más de 3.500 lingüistas expertos. También puede descargar sus traducciones, ver el estado del proyecto y acceder a los informes en línea. El resultado es un contenido preciso y coherente para su audiencia, y tranquilidad para usted y su personal.",
        "third_one": "Idiomas disponibles para traducción",
        "third_two": "Índice de precisión de las traducciones",
        "third_three": "Entrega puntual de los proyectos de traducción",
        "four_tit": "CONECTORES CMS",
        "four_main": "Automatice sus transferencias de archivos",
        "four_main_exp": "EMLions ha reimaginado la traducción desde la perspectiva de nuestros clientes, integrando la automatización multilingüe directamente en los sistemas de contenidos y aplicaciones empresariales, lo que permite a nuestros clientes enviarnos fácilmente cualquier contenido. Elija cualquiera de nuestros más de 50 conectores listos para usar para aumentar la eficacia de la transferencia de archivos y los flujos de trabajo de publicación.",
        "four_btn": "MÁS INFORMACIÓN",
        "five_tit": "PROXY DE SITIOS WEB",
        "five_main": "Traduzca fácilmente su sitio web",
        "five_main_exp": "Agilice el proceso de producción y gestión de versiones traducidas de su sitio web o plataforma de comercio electrónico en inglés. Con EMLions® Website Proxy, las versiones traducidas de su sitio están siempre sincronizadas con la versión en inglés. Además, si carece de los recursos para alojar un sitio traducido, Website Proxy le ayuda a reflejar su contenido y mostrarlo en el idioma de su elección.",
        "five_btn": "MÁS INFORMACIÓN",
        "six_tit": "TRADUCCIÓN AUTOMÁTICA",
        "six_main": "Obtenga traducciones en minutos",
        "six_main_exp": "Traduzca contenidos a gran escala lo antes posible. Con la traducción automática de EMLions®, nuestra tecnología \"best fit\" basada en IA proporciona contenidos fáciles de entender en muy poco tiempo. Y cuando combina su contenido con nuestro proceso de posedición con certificación ISO, puede estar seguro de que todas sus comunicaciones y entregables serán precisos.",
        "six_btn": "MÁS INFORMACIÓN",
        "sevent_tit": "Más información sobre las herramientas de traducción integradas",
        "sevent_main": "Ofrezca contenidos coherentes y de alta calidad en cualquier idioma sin sacrificar la precisión. Puede leer nuestro folleto Traducción y localización o elegir una hora para hablar con nuestros expertos sobre cómo resolver sus problemas de traducción.",
        "sevent_download_btn": "DESCARGAR AHORA",
        "sevent_schedule_btn": "PROGRAMAR CONSULTA",
        "eigth_tit": "Herramientas de traducción integradas",
        "eight_one_tit": "TECNOLOGÍA AVANZADA",
        "eigth_one_contents": "Lideramos el sector diseñando nuevas soluciones de localización.",
        "eight_two_tit": "PERSONALIZACIÓN",
        "eigth_two_contents": "Ofrecemos tecnología punta y conocimientos lingüísticos para adaptarnos a las necesidades cambiantes de su proyecto.",
        "eight_three_tit": "GARANTÍA DE CALIDAD AL 100",
        "eigth_three_contents": "Nuestro equipo integrado de especialistas del sector garantiza resultados de la máxima calidad.",
        "eight_four_tit": "ENTREGA PUNTUAL",
        "eigth_four_contents": "Nuestro índice de puntualidad es del 99,5%."
    },
    "machine_translation": {
        "tit": "TRADUCCIÓN AUTOMÁTICA",
        "main": "Traduzca su contenido en segundos",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Ahorre tiempo y dinero automatizando las traducciones",
        "sec_one": "Obtenga un control total sobre el plazo y el coste de sus proyectos de traducción. La traducción automática utiliza motores especializados para traducir contenidos al instante. Para obtener los resultados más rápidos posibles, puede automatizar completamente el proceso o seguir un flujo de trabajo híbrido en el que uno de nuestros más de 3.500 lingüistas profesionales apoye su proyecto.",
        "sec_two": "Nuestra traducción automática utiliza inteligencia artificial para aumentar la precisión de sus traducciones. Puede personalizar sus procesos de postedición y revisión en función de las necesidades de su proyecto, presupuesto y plazos.",
        "third_one": "Idiomas disponibles para la traducción",
        "third_two": "Motores de \"mejor ajuste\" basados en IA",
        "third_three": "Lingüistas disponibles para postedición",
        "four_tit": "TRADUCCIÓN RÁPIDA",
        "four_main": "¿La traducción automática es adecuada para usted?",
        "four_main_exp": "La traducción automática funciona mejor para traducir grandes cantidades de contenido en un plazo de tiempo ajustado. Es ideal para contenidos de bajo riesgo, como comunicaciones de atención al cliente y artículos de blog. Si trabajas con documentos jurídicos, médicos o científicos, o con contenidos que requieren un análisis más profundo de los sentimientos, obtendrás mejores resultados con los servicios de traducción tradicionales.",
        "five_tit": "TECNOLOGÍA",
        "five_main": "Aumente la precisión con una potente tecnología",
        "five_main_exp": "EMLions® Machine Translation utiliza más de 40 motores líderes para maximizar la precisión. Busca automáticamente las mejores opciones para sus idiomas y tipo de contenido para garantizar el mejor resultado para su mensaje. Utilizando nuestro flujo de trabajo híbrido junto con la memoria de traducción y los glosarios de su empresa, podemos aumentar la precisión y ayudarle a controlar los costes.",
        "six_tit": "Herramientas de traducción automática de EMLions",
        "six_one_tit": "TECNOLOGÍA AVANZADA",
        "six_one_main": "Nuestro centro de traducción basado en IA ofrece la traducción automática que mejor se adapta a su contenido.",
        "six_two_tit": "PERSONALIZACIÓN",
        "six_two_main": "Seleccione el flujo de trabajo de traducción automática que mejor se adapte a sus necesidades de contenido.",
        "six_three_tit": "SEGURIDAD",
        "six_three_main": "Su contenido valioso y exclusivo permanece dentro de nuestro entorno seguro.",
        "six_four_tit": "PLAZOS DE ENTREGA RÁPIDOS",
        "six_four_main": "Complete sus proyectos al instante o más rápido con nuestro flujo de trabajo MTPE.",
        "sevent_tit": "Más información sobre traducción y localización",
        "sevent_main": "Obtenga más información sobre cómo ofrecer contenidos coherentes y de alta calidad en cualquier idioma y para cualquier cultura. Puede leer nuestro folleto Traducción y localización o elegir una hora para hablar con nuestros expertos sobre cómo resolver sus problemas de traducción.",
        "sevent_download_btn": "DESCARGAR AHORA",
        "sevent_schedule_btn": "PROGRAMAR CONSULTA"
    },
    "elearning_translation": {
        "tit": "SERVICIOS ELEARNING",
        "main": "Ofrezca contenidos de eLearning en más de 290 idiomas",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Aumente la rentabilidad de su inversión en formación",
        "sec_one": "Organizaciones de todo el mundo están adoptando el eLearning como una forma eficaz y rentable de ofrecer contenidos educativos y de formación a sus empleados y clientes dispersos. Sin embargo, las empresas que no traducen y localizan correctamente sus contenidos de eLearning pueden limitar la eficacia de su formación y su retorno de la inversión.",
        "sec_two": "Prepare sus programas de eLearning para una audiencia global trabajando con nuestros más de 3.500 lingüistas expertos. Puede contar con resultados coherentes y de alta calidad. Además, acceda a nuestro portal cifrado de extremo a extremo para cargar materiales, realizar un seguimiento del progreso y recuperar traducciones, lo que garantiza la eficacia y la seguridad total en cada proyecto.",
        "third_one": "Lingüistas expertos en plantilla",
        "third_two": "Idiomas disponibles para la traducción",
        "third_three": "Índice de precisión de la traducción",
        "four_tit": "SERVICIOS ELEARNING",
        "four_main": "Calidad de contenidos garantizada al 100",
        "four_main_exp": "Como líder del sector en servicios lingüísticos, gestionamos miles de proyectos de traducción y localización al año en más de 290 idiomas. Nuestras décadas de experiencia apoyando contenidos de formación a distancia han cultivado nuestra pericia en el diseño de programas de eLearning altamente eficaces y accesibles. La calidad de nuestros contenidos está garantizada al 100%, y el 99,5% de nuestros clientes califican nuestros servicios de traducción y localización como \"buenos o mejores\".",
        "five_tit": "TECNOLOGÍA",
        "five_main": "Aplicaciones y soporte de eLearning",
        "five_main_exp": "El 93% de las empresas incluidas en la lista Fortune 100 confían en nosotros para traducir y localizar contenidos especializados, como cursos de eLearning, materiales de formación, seminarios web, libros electrónicos, incorporación y formación en sistemas y productos. Además, nuestro equipo de expertos está especializado en formación ERP para SAP, Oracle, Salesforce y otras plataformas.",
        "six_tit": "EXPERIENCIA GLOBAL",
        "six_main": "Implemente formación específica del sector en todo el mundo",
        "six_main_exp": "Nos aseguraremos de que el vocabulario exclusivo de su organización y la terminología específica del sector se entiendan a nivel mundial en más de 290 idiomas. Nuestros más de 3.500 lingüistas expertos tienen una gran experiencia en la traducción de una amplia gama de contenidos de eLearning en sectores como:",
        "six_main_one": "Sanidad",
        "six_main_two": "TI y SaaS",
        "six_main_three": "Comercio minorista y electrónico",
        "six_main_four": "Educación",
        "six_main_five": "Servicios financieros",
        "six_main_six": "Farmacéutica",
        "six_main_sevent": "Fabricación",
        "six_main_eight": "Construcción y más",
        "sevent_tit": "Más información sobre eLearning",
        "sevent_main": "Obtenga más información sobre cómo ofrecer contenidos de eLearning coherentes y de alta calidad en más de 290 idiomas. Puede leer nuestro folleto sobre traducción y localización o elegir un momento para hablar con nuestros expertos sobre cómo resolver sus retos de eLearning.",
        "sevent_download_btn": "DESCARGAR AHORA",
        "sevent_schedule_btn": "PROGRAMAR CONSULTA",
        "eight_tit": "Servicios eLearning",
        "eigth_one_tit": "SERVICIO COMPLETO",
        "eigth_one_contents": "Reproducimos sus contenidos de aprendizaje de principio a fin para activar a sus audiencias globales.",
        "eight_two_tit": "PERSONALIZACIÓN",
        "eigth_two_contents": "Proporcionamos tecnología punta y experiencia lingüística para adaptarnos a las necesidades cambiantes de su proyecto.",
        "eight_three_tit": "GARANTÍA DE CALIDAD AL 100",
        "eigth_three_contents": "Nuestro equipo integrado de especialistas del sector garantiza resultados de la máxima calidad.",
        "eight_four_tit": "ENTREGA PUNTUAL",
        "eigth_four_contents": "Nunca hemos incumplido una fecha de entrega."
    },
    "website_translation": {
        "tit": "TRADUCCIÓN DE SITIOS WEB",
        "main": "Localice su sitio web para audiencias globales",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Traduzca su sitio web a más de 290 idiomas",
        "sec_one": "El contenido de su sitio web evoluciona constantemente, al igual que la necesidad de sus clientes de verlo en el idioma que entienden. Mantener su sitio actualizado con el contenido más reciente puede ser una tarea desalentadora en sí misma. Si además tiene que traducirlo a varios idiomas, el mantenimiento del sitio puede convertirse en un gran reto que afecte a la productividad del personal y a la rentabilidad de la organización.",
        "sec_two": "Con EMLions® Website Proxy usted puede:",
        "sec_three": "Traducir documentos, materiales de marketing, formularios, comunicaciones y guías prácticas.",
        "sec_four": "Localizar sitios web, hardware, software, contenido de juegos y aprendizaje electrónico.",
        "sec_five": "Automatizar la traducción y localización de sus contenidos",
        "third_one": "Lingüistas expertos en plantilla",
        "third_two": "Índice de precisión de la traducción",
        "third_three": "Calidad de contenidos garantizada",
        "four_tit": "CONECTORES CMS",
        "four_main": "Automatice sus transferencias de archivos",
        "four_main_exp": "EMLions ha reimaginado la traducción desde la perspectiva de nuestros clientes, integrando la automatización multilingüe directamente en los sistemas de contenido y las aplicaciones empresariales, lo que permite a nuestros clientes enviarnos fácilmente cualquier contenido. Elija cualquiera de nuestros más de 50 conectores listos para usar para aumentar la eficacia de la transferencia de archivos y los flujos de trabajo de publicación.",
        "four_btn": "MÁS INFORMACIÓN",
        "five_tit": "TRADUCCIÓN AUTOMÁTICA",
        "five_main": "¿Es adecuada para usted?",
        "five_main_exp": "La traducción automática funciona mejor para traducir grandes cantidades de contenido en un plazo ajustado. Es ideal para contenidos de bajo riesgo de responsabilidad, como comunicaciones de atención al cliente y artículos de blog. Si trabaja con documentos jurídicos, médicos o científicos, o con contenidos que requieren un análisis más profundo de los sentimientos, obtendrá mejores resultados con los servicios de traducción tradicionales.",
        "five_btn": "MÁS INFORMACIÓN",
        "six_tit": "EMLions Traducción de sitios web",
        "six_one_tit": "TECNOLOGÍA AVANZADA",
        "six_one_contents": "Ofrecemos tecnología punta y experiencia lingüística para adaptarnos a las necesidades cambiantes de su proyecto web.",
        "six_two_tit": "PERSONALIZACIÓN",
        "six_two_contents": "Ofrecemos tecnología punta y experiencia lingüística para adaptarnos a las necesidades cambiantes de su proyecto web.",
        "six_three_tit": "100% DE GARANTÍA DE CALIDAD",
        "six_three_contents": "Enfoque flexible para cualquier complejidad y presupuesto.",
        "six_four_tit": "ENTREGA PUNTUAL",
        "six_four_contents": "Nuestro equipo integrado de especialistas del sector garantiza resultados de la máxima calidad.",
        "sevent_tit": "Más información sobre traducción y localización",
        "sevent_main": "Obtenga más información sobre cómo ofrecer contenidos web coherentes y de alta calidad en cualquier idioma y para cualquier cultura. Puede leer nuestro folleto Traducción y localización o elegir una hora para hablar con nuestros expertos sobre cómo resolver sus problemas de traducción."
    },
    "translation_languages": {
        "tit": "IDIOMAS DE TRADUCCIÓN",
        "main": "Más de 290 idiomas de traducción y localización disponibles",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Más información sobre las herramientas de traducción integradas",
        "sec_main": "Ofrezca contenidos coherentes y de alta calidad en cualquier idioma sin sacrificar la precisión. Puede leer nuestro folleto Traducción y localización o elegir una hora para hablar con nuestros expertos sobre cómo resolver sus retos de traducción.",
        "sec_download_btn": "DESCARGAR AHORA",
        "lang": {
            "Achi": "Achi",
            "Achinese": "Acehés",
            "Acholi": "Acholi",
            "Afar": "Afar",
            "Afghani": "Afgano",
            "Afrikaans": "Afrikáans",
            "Akan": "Akan",
            "Akateko": "Akatec",
            "Albanian": "Albanés",
            "Amharic": "Amhárico",
            "Anuak": "Anuak",
            "Apache": "Apache",
            "Arabic": "Árabe",
            "Arabic_Iraq": "Árabe (Iraq)",
            "Arabic_Juba": "Árabe (Juba)",
            "Arabic_Saudi_Arabia": "Árabe (Arabia Saudí)",
            "Arabic_Sudan": "Árabe (Sudán)",
            "Arabic_Yemen": "Árabe (Yemen)",
            "Arakanese": "Arakanés",
            "Armenian": "Armenio",
            "Assamese": "Asamés",
            "Assyrian": "Asirio",
            "Azeri": "Azerbaiyano",
            "Azeri_Cyrillic": "Azerbaiyano (cirílico)",
            "Azeri_Latin": "Azerbaiyano (latín)",
            "Bajan": "Bajan",
            "Bajuni": "Bajuni",
            "Bambara": "Bambara",
            "Basque": "Vasco",
            "Bassa": "Bassa",
            "Behdini": "Behdini",
            "Beja": "Beja",
            "Belarusian": "Bielorruso",
            "Belgian": "Belga",
            "Bemba": "Bemba",
            "Bengali": "Bengalí",
            "Berber": "Bereber",
            "Bhojpuri": "Bhojpuri",
            "Bislama": "Bislama",
            "Blackfoot_Canada": "Blackfoot (Canadá)",
            "Bosnian": "Bosnio",
            "Bosnian_Cyrillic": "Bosnio (cirílico)",
            "Bosnian_Latin": "Bosnio (latín)",
            "Braille": "Braille",
            "Bulgarian": "Búlgaro",
            "Burmese": "Birmano",
            "Cambodian": "Camboyano",
            "Cambodian_Pidgin_English": "Pidgin Inglés Camboyano",
            "Cantonese": "Cantonés",
            "Cape_Verdean_Creole": "Criollo Caboverdiano",
            "Catalan": "Catalán",
            "Cebuano": "Cebuano",
            "Central_Alaskan_Yupik": "Yupik Central de Alaska",
            "Chaldean": "Caldeo",
            "Chamorro": "Chamorro",
            "Chavacano": "Chavacano",
            "Cherokee": "Cherokee",
            "Chichewa": "Chichewa",
            "Chin": "Chin",
            "Chinese_Simplified": "Chino (Simplificado)",
            "Chinese_Traditional": "Chino (Tradicional)",
            "Chinese_Traditional_HK": "Chino (Hong Kong)",
            "Choctaw": "Choctaw",
            "Chui_Chow": "Chui Chow",
            "Chuj": "Chuj",
            "Chuukese": "Chuukese",
            "Cree_Canada": "Cree (Canadá)",
            "Croatian": "Croata",
            "Crow": "Crow",
            "Czech": "Checo",
            "Dakota": "Dakota",
            "Danish": "Danés",
            "Dari": "Dari",
            "Dene": "Dene",
            "Dinka": "Dinka",
            "Dutch": "Neerlandés",
            "Dutch_Belgium": "Neerlandés (Bélgica)",
            "Dyula": "Dyula",
            "Dzongkha": "Dzongkha",
            "Eastern_Mari": "Mari Oriental",
            "Edo": "Edo",
            "English_Australia": "Inglés (Australia)",
            "English_Belize": "Inglés (Belice)",
            "English_Canada": "Inglés (Canadá)",
            "English_Ireland": "Inglés (Irlanda)",
            "English_Jamaica": "Inglés (Jamaica)",
            "English_Liberia": "Inglés (Liberia)",
            "English_New_Zealand": "Inglés (Nueva Zelanda)",
            "English_Trinidad_Tobago": "Inglés (Trinidad y Tobago)",
            "English_UK": "Inglés (Reino Unido)",
            "English_US": "Inglés (EE.UU.)",
            "English_Zimbabwe": "Inglés (Zimbabue)",
            "Estonian": "Estonio",
            "Ewe": "Ewe",
            "Falam": "Falam",
            "Fante": "Fante",
            "Farsi": "Farsi",
            "Fijian": "Fiyiano",
            "Filipino": "Filipino",
            "Finnish": "Finlandés",
            "Flemish": "Flamenco",
            "Formosan": "Formosano",
            "French_Belgium": "Francés (Bélgica)",
            "French_Burkina_Faso": "Francés (Burkina Faso)",
            "French_Burundi": "Francés (Burundi)",
            "French_Cameroon": "Francés (Camerún)",
            "French_Canada": "Francés (Canadá)",
            "French_Congo": "Francés (Congo)",
            "French_France": "Francés (Francia)",
            "French_Guinea": "Francés (Guinea)",
            "French_Ivory_Coast": "Francés (Costa de Marfil)",
            "French_Madagascar": "Francés (Madagascar)",
            "French_Morocco": "Francés (Marruecos)",
            "French_Switzerland": "Francés (Suiza)",
            "French_Tunisia": "Francés (Túnez)",
            "French_West_Africa": "Francés (África Occidental)",
            "Frisian": "Frisón",
            "Fukienese": "Fukienese",
            "Fula": "Fula",
            "Fulani": "Fulani",
            "Fulfulde": "Fulfulde",
            "Fuzhou": "Fuzhou",
            "Ga": "Ga",
            "Gaddang": "Gaddang",
            "Galician": "Gallego",
            "Garifuna": "Garífuna",
            "Georgian": "Georgiano",
            "German": "Alemán",
            "German_Austria": "Alemán (Austria)",
            "German_Belgium": "Alemán (Bélgica)",
            "German_Liechtenstein": "Alemán (Liechtenstein)",
            "German_Pennsylvania": "Alemán (Pensilvania)",
            "German_Switzerland": "Alemán (Suiza)",
            "Greek": "Griego",
            "Guarani": "Guaraní",
            "Gujarati": "Guyaratí",
            "Guyanese": "Guyanes",
            "Haitian_Creole": "Criollo haitiano",
            "Hakka": "Hakka",
            "Hakka_China": "Hakka (Cantón)",
            "Hakka_Taiwan": "Hakka (Taiwán)",
            "Harari": "Harari",
            "Haryanvi": "Haryanvi",
            "Hausa": "Hausa",
            "Hawaiian": "Hawaiano",
            "Hebrew": "Hebreo",
            "Hindi": "Hindi",
            "Hmong": "Hmong",
            "Hokkien": "Hokkien",
            "Hopi": "Hopi",
            "Hunanese": "Hunanés",
            "Hungarian": "Húngaro",
            "Ibanag": "Ibanag",
            "Ibibio": "Ibibio",
            "Ibo": "Ibo",
            "Icelandic": "Islandés",
            "Igbo": "Igbo",
            "Ilocano": "Ilocano",
            "Ilonggo": "Ilonggo",
            "Indonesian_Bahasa_Indonesia": "Indonesio (Bahasa Indonesia)",
            "Inuktitut": "Inuktitut",
            "Inupiak": "Inupiak",
            "Iraqi": "Iraquí",
            "Irish_Gaelic": "Gaélico irlandés",
            "Italian": "Italiano",
            "Italian_Switzerland": "Italiano (Suiza)",
            "Ixil": "Ixil",
            "Jakartanese": "Jakartanés",
            "Jamaican_Patois": "Criollo jamaiquino",
            "Japanese": "Japonés",
            "Jarai": "Jarai",
            "Javanese": "Javanés",
            "Jju": "Jju",
            "Kachin": "Kachin",
            "Kanjobal": "Kanjobal",
            "Kannada": "Kannada",
            "Kaqchikel": "Kaqchikel",
            "Karen": "Karen",
            "Karenni": "Karenni",
            "Karenni_Kayah_Li": "Karenni (Kayah Li)",
            "Karenni_Latin": "Karenni (Latín)",
            "Karenni_Myanmar": "Karenni (Myanmar)",
            "Kashmiri": "Cachemiro",
            "Kayah": "Kayah",
            "Kayah_Latin": "Kayah (Latín)",
            "Kayah_Myanmar": "Kayah (Myanmar)",
            "Kazakh": "Kazajo",
            "Kekchi": "Kekchi",
            "Khmer": "Khmer",
            "Kibembe": "Kibembe",
            "Kifulero": "Kifulero",
            "Kikuyu": "Kikuyu",
            "Kinyamulenge": "Kinyamulenge",
            "Kinyarwanda": "Kinyarwanda",
            "Kirundi": "Kirundi",
            "Kiswahili": "Kiswahili",
            "Kituba": "Kituba",
            "Kizigua": "Kizigua",
            "Konkani": "Konkani",
            "Korean": "Coreano",
            "Kosovan": "Kosovan",
            "Kosraean": "Kosraean",
            "Kpelle": "Kpelle",
            "Krahn": "Krahn",
            "Krio": "Krio",
            "Kunama": "Kunama",
            "Kurdish": "Kurdo",
            "Kurdish_Arabic": "Kurdo (Árabe)",
            "Kurdish_Cyrillic": "Kurdo (Cirílico)",
            "Kurdish_Latin": "Kurdo (Latín)",
            "Kurmanji": "Kurmanji",
            "Kwakwala": "Kwakwala",
            "Kwanyama": "Kwanyama",
            "Kyrgyz": "Kirguís",
            "Lai_Hakha": "Lai Hakha",
            "Lakota": "Lakota",
            "Lao": "Lao",
            "Latin": "Latín",
            "Latvian": "Letón",
            "Lingala": "Lingala",
            "Lithuanian": "Lituano",
            "Luganda": "Luganda",
            "Luo": "Luo",
            "Luxembourgish": "Luxemburgués",
            "Maay_Maay": "Maay Maay",
            "Macedonian": "Macedonio",
            "Madi_Uganda": "Madi (Uganda)",
            "Malagasy": "Malgache",
            "Malay": "Malayo",
            "Malay_Jawi": "Malayo (Jawi)",
            "Malayalam": "Malayalam",
            "Maltese": "Maltés",
            "Mam": "Mam",
            "Mandarin": "Mandarín",
            "Mandingo": "Mandingo",
            "Mandinka": "Mandinka",
            "Mandinka_Arabic": "Mandinka (Árabe)",
            "Mandinka_Latin": "Mandinka (Latín)",
            "Manipuri": "Manipuri",
            "Mankon": "Mankon",
            "Maori": "Maorí",
            "Marathi": "Marathi",
            "Marshallese": "Marshalés",
            "Mauritian_Creole": "Criollo Mauriciano",
            "Memoni": "Memoni",
            "Mende": "Mende",
            "Mi_kmaq": "Mi'kmaq",
            "Michif": "Michif",
            "Mien": "Mien",
            "Mina": "Mina",
            "Mixteco": "Mixteco",
            "Mnong": "Mnong",
            "Mon": "Mon",
            "Mongolian": "Mongol",
            "Mongolian_Cyrillic": "Mongol cirílico",
            "Montenegrin": "Montenegrino",
            "Montenegrin_Cyrillic": "Montenegrino cirílico",
            "Montenegrin_Latin": "Montenegrino latino",
            "Mossi": "Mossi",
            "Nahuatl": "Nahuatl",
            "Navajo": "Navajo",
            "Nepali": "Nepalí",
            "Nigerian_Pidgin_English": "Pidgin Nigeriano Inglés",
            "Nordic": "Nórdico",
            "Norwegian": "Noruego",
            "Nuer": "Nuer",
            "Ojibway": "Ojibwa",
            "Oriya": "Oriya",
            "Oromo": "Oromo",
            "Palauan": "Palauano",
            "Pampangan": "Pampango",
            "Papiamento": "Papiamento",
            "Pashai": "Pashai",
            "Pashto": "Pastún",
            "Patois": "Patois",
            "Persian": "Persa",
            "Pidgin_English": "Pidgin Inglés",
            "Pima": "Pima",
            "Pinyin": "Pinyin",
            "Pohnpeian": "Pohnpeiano",
            "Polish": "Polaco",
            "Portuguese_Brazil": "Portugués (Brasil)",
            "Portuguese_Guinea_Bissau": "Portugués (Guinea-Bissau)",
            "Portuguese_Portugal": "Portugués (Portugal)",
            "Punjabi": "Panyabí",
            "Pushtu": "Pushto",
            "Quechua": "Quechua",
            "Queens_English_UK": "Inglés Reina (UK)",
            "Quiche": "Quiché",
            "Rade": "Rade",
            "Rohingya_Arabic": "Rohingya (árabe)",
            "Rohingya_Latin": "Rohingya (latino)",
            "Romani": "Romaní",
            "Romanian": "Rumano",
            "Romansh": "Romanche",
            "Russian": "Ruso",
            "Saint_Lucian_Creole": "Criollo San Luciano",
            "Samoan": "Samoano",
            "Sango": "Sango",
            "Sanskrit": "Sánscrito",
            "Saraiki": "Saraiki",
            "Scottish_Gaelic": "Gaélico Escocés",
            "Serbian": "Serbio",
            "Serbian_Cyrillic": "Serbio cirílico",
            "Serbian_Latin": "Serbio latino",
            "Sesotho": "Sesotho",
            "Setswana": "Setsuana",
            "Shanghainese": "Shanghainés",
            "Shona": "Shona",
            "Shuswap": "Shuswap",
            "Siberian_Yup_ik": "Yup'ik Siberiano",
            "Sicilian": "Siciliano",
            "Sidaama": "Sidaama",
            "Sindhi": "Sindhi",
            "Sinhala": "Cingalés",
            "Siswati": "Siswati",
            "Slovak": "Eslovaco",
            "Slovenian": "Esloveno",
            "Somali": "Somalí",
            "Soninke": "Soninké",
            "Sorani": "Sorani",
            "Spanish_Argentina": "Español (Argentina)",
            "Spanish_Chile": "Español (Chile)",
            "Spanish_Colombia": "Español (Colombia)",
            "Spanish_Ecuador": "Español (Ecuador)",
            "Spanish_International": "Español (Internacional)",
            "Spanish_Latin_America": "Español (Latinoamérica)",
            "Spanish_Mexico": "Español (México)",
            "Spanish_Panama": "Español (Panamá)",
            "Spanish_Puerto_Rico": "Español (Puerto Rico)",
            "Spanish_Spain": "Español (España)",
            "Spanish_US": "Español (EE.UU.)",
            "Spanish_Venezuela": "Español (Venezuela)",
            "Stoney_Canada": "Stoney (Canadá)",
            "Sudanese": "Sudanés",
            "Sundanese": "Sudanés",
            "Swahili": "Suajili",
            "Swedish": "Sueco",
            "Swedish_Finland": "Sueco (Finlandia)",
            "Sylheti": "Sylheti",
            "Syriac": "Siríaco",
            "Szechuan": "Sichuano",
            "Tagalog": "Tagalo",
            "Tahitian": "Tahitiano",
            "Taiwanese": "Taiwanés",
            "Tajik": "Tayiko",
            "Tamil": "Tamil",
            "Tatar": "Tártaro",
            "Tedim_Chin": "Tedim_Chin",
            "Telugu": "Telugu",
            "Temne": "Temne",
            "Tetum": "Tetum",
            "Thai": "Tailandés",
            "Tibetan": "Tibetano",
            "Tigre": "Tigre",
            "Tigrinya": "Tigrinya",
            "Tlingit": "Tlingit",
            "Toishanese": "Toisanés",
            "Tongan": "Tongano",
            "Trique": "Triqui",
            "Tsuut_ina": "Tsuut'ina",
            "Turkish": "Turco",
            "Turkmen": "Turcomano",
            "Twi": "Twi",
            "Uighur": "Uigur",
            "Ukrainian": "Ucraniano",
            "Ulster_Scots": "Escocés de Ulster",
            "Urdu": "Urdu",
            "Uzbek": "Uzbeco",
            "Uzbek_Cyrillic": "Uzbeco (cirílico)",
            "Uzbek_Latin": "Uzbeco (latino)",
            "Vietnamese": "Vietnamita",
            "Visayan": "Visayan",
            "Welsh": "Galés",
            "Wolof": "Wolof",
            "Xhosa": "Xhosa",
            "Yapese": "Yapés",
            "Yaqui": "Yaqui",
            "Yavapai": "Yavapai",
            "Yiddish_other": "Yiddish (otro)",
            "Yiddish_US": "Yiddish (EE.UU.)",
            "Yoruba": "Yoruba",
            "Yucateco": "Yucateco",
            "Yupik": "Yupik",
            "Zarma": "Zarma",
            "Zou": "Zou",
            "Zulu": "Zulú"
        },
        "third_info_one": "Puede haber otros idiomas y dialectos disponibles. Si tiene alguna pregunta sobre la disponibilidad de idiomas",
        "third_info_two": "póngase en contacto con nosotros en"
    },
    "document_clarification": {
        "tit": "ACLARACIÓN DE DOCUMENTOS",
        "main": "Acceso lingüístico a través de todos los puntos de contacto con EMLions Clarity",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "sec_one": "EMLions es la mayor empresa de acceso lingüístico del mundo. Además de ofrecer soluciones de traducción en más de 290 idiomas, nuestro equipo Clarity también garantiza que las comunicaciones sean, ante todo, sencillas, claras y comprensibles en \"inglés sencillo\".",
        "sec_two": "Estamos orgullosos de tender puentes en la comunicación en todos los rincones del mundo. Aun así, queremos tener en cuenta que los angloparlantes también necesitan un mejor acceso a la información que consumen en inglés. Teniendo en cuenta que la persona media en EE.UU. tiene la capacidad lectora de un alumno de 7º curso, el uso del inglés llano es imprescindible para una comunicación clara, más accesible y concisa. Por eso consideramos que el \"plain English\" es un idioma valioso en sí mismo.",
        "third_tit": "EMLIONS CLARIDAD",
        "third_main": "Comunicación clara. Comunicación sencilla.",
        "third_main_one": "Los documentos demasiado largos, mal organizados y plagados de jerga y lenguaje de iniciados son difíciles o imposibles de entender en cualquier idioma, por muy expertamente que se traduzcan.",
        "third_main_two": "En cambio, cuando los documentos digitales e impresos se limpian de contenido no esencial y se redactan y diseñan para que resulten claros para el público al que van dirigidos, resultan fáciles de entender en todos los idiomas. Por sí solo puede ofrecer una oportunidad para aumentar la conexión y la confianza entre usted y sus clientes.",
        "third_main_three": "Los servicios de Claridad de EMLions son totalmente diferentes de otros servicios de redacción en lenguaje llano. En lugar de abordar un puñado de documentos individuales en el vacío, EMLions® rediseña sistemas documentales enteros y le ayuda a producir contenidos más ágiles, transparentes y rentables."
    },
    "document_simplification": {
        "tit": "SIMPLIFICACIÓN DE DOCUMENTOS",
        "main": "Llegue a su audiencia con lenguaje sencillo y total claridad",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "¿Qué es la simplificación de documentos?",
        "sec_main": "Los clientes, pacientes y electores aprecian y responden a las comunicaciones sencillas y directas. Cuando entienden lo que se les dice a la primera, es más probable que vuelvan a por más. Esto es lo que nuestros servicios de simplificación de documentos pueden ayudarle a conseguir. Ofrecemos macrosimplificación y microsimplificación para mejorar la comprensión y reducir el gasto en traducción.",
        "macro": "MACROSIMPLIFICACIÓN",
        "third_one_tit": "Simplifique todo un sistema documental",
        "third_one_main": "Rediseñe por completo su sistema documental para obtener contenidos más ágiles y eficaces que aumenten la satisfacción al mejorar la legibilidad y la comprensión en todos los puntos de contacto. El perfeccionamiento de su sistema documental también puede suponer un ahorro de costes y un mayor rendimiento.",
        "third_two_tit": "Cientos de documentos a la vez",
        "third_two_main_one": "Racionalice los sistemas de documentos impresos y digitales de gran tamaño",
        "third_two_main_two": "Renueve el contenido complejo para que sea más claro y fácil de usar",
        "third_two_main_three": "Ajuste el tamaño de las bibliotecas de documentos",
        "third_two_main_four": "Impulse los procesos de comunicación y la eficacia",
        "third_three_tit": "Ahorre dinero y cumpla las normas",
        "third_three_main": "Los proyectos de macrosimplificación pueden amortizarse con un aumento de la calidad y una reducción de los costes de producción e impresión. También mejoran la experiencia del cliente, el paciente y el electorado, aumentan la satisfacción y le ayudan a cumplir los requisitos de conformidad.",
        "third_four_tit": "Cómo saber si lo necesita",
        "third_four_main": "Hable con uno de nuestros representantes acerca de la macrosimplificación si su organización tiene problemas con:",
        "third_four_main_one": "Información obsoleta e incoherente",
        "third_four_main_two": "Contenido redundante y excesivo",
        "third_four_main_three": "Dificultad para llegar a públicos específicos",
        "third_four_main_four": "Comunicaciones confusas que provocan llamadas de los clientes",
        "third_four_main_five": "Falta de gobernanza y control de calidad eficaces",
        "micro": "MICROSIMPLIFICACIÓN",
        "four_one_tit": "Racionalizar documentos y comunicaciones individuales",
        "four_one_main": "La microsimplificación tiene un valor incalculable para cualquier organización. El proceso incluye",
        "four_one_main_one": "Análisis del contenido y recomendaciones de mejora",
        "four_one_main_two": "Eliminación de información innecesaria y excesiva",
        "four_one_main_three": "Uso del inglés sencillo para la información básica",
        "four_one_main_four": "Creación de un nuevo texto fácil de leer y atractivo.",
        "four_two_tit": "Ofrezca una mejor experiencia al cliente",
        "four_two_main": "La microsimplificación de los materiales de marketing puede aumentar drásticamente su eficacia y ayudarle a cumplir las normas de su sector. Los materiales sencillos y claros tienen más posibilidades de convertir clientes potenciales en clientes y de fidelizarlos a largo plazo.",
        "four_three_tit": "Mejore los resultados y reduzca los costes",
        "four_three_main": "La microsimplificación es especialmente útil para las organizaciones que ofrecen servicios complejos como seguros, servicios financieros o atención médica. Un formulario, una factura o un folleto muy visibles pueden tener un impacto enorme en los resultados y los costes.",
        "five_tit": "Hablemos de soluciones",
        "five_main": "Estamos aquí con asistencia lingüística experta diseñada para satisfacer sus necesidades y su presupuesto. Solicite un presupuesto ahora o hable con uno de nuestros representantes para revisar sus retos de acceso lingüístico y discutir soluciones.",
        "schedule_btn": "PROGRAMAR CONSULTA"
    },
    "audio_transcription": {
        "tit": "TRANSCRIPCIÓN DE AUDIO",
        "main": "Convierta sus grabaciones de audio en activos valiosos",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Utilice la transcripción de audio para impulsar su negocio",
        "sec_one": "Las organizaciones actuales recopilan grandes cantidades de información no sólo en comunicaciones impresas y digitales, sino también en sus grabaciones de audio y vídeo. Estos activos contienen información valiosa que puede mejorar el servicio al cliente, la formación del personal y el alcance del marketing.",
        "sec_two": "Podemos facilitarle la transcripción de sus grabaciones de audio y vídeo. Ofrecemos transcripción de audio a texto, voz en off y traducción en más de 290 idiomas. Puede estar seguro de que nuestros más de 3.500 lingüistas expertos completarán sus proyectos de transcripción con un resultado de alta calidad, una revisión exhaustiva y una entrega puntual.",
        "transcription": "TRANSCRIPCIÓN",
        "third_one_tit": "Mejore el rendimiento y la productividad",
        "third_one_main": "Muchas empresas graban las llamadas del servicio de atención al cliente para controlar la calidad, pero escucharlas puede llevar mucho tiempo y ser un lastre para la productividad. Las grabaciones pueden ofrecerle nuevas perspectivas sobre la calidad del servicio que presta a sus clientes, pacientes y electores. Además, la transcripción puede ofrecer a los gestores de su centro de llamadas una forma más rápida de revisar el rendimiento del personal e intervenir con asesoramiento de forma inmediata.",
        "third_two_tit": "Aumente la accesibilidad para todos",
        "third_two_main": "La transcripción de grabaciones de audio puede hacer que su contenido sea más accesible para clientes y empleados sordos o con dificultades auditivas. Si su organización produce audio con fines de marketing, atención al cliente o formación de empleados, la conversión de estas grabaciones en contenido impreso o digital las hará accesibles a todos sus clientes.",
        "third_three_tit": "Llegue a un público global",
        "third_three_main": "El público de todo el mundo está ávido de contenidos eficaces. Así que no se limite a convertir sus grabaciones en comunicaciones impresas y digitales. Vaya más allá contratando a nuestros más de 3.500 lingüistas expertos, que pueden traducir una sola transcripción de audio a más de 290 idiomas, multiplicando radicalmente el impacto de cada grabación que haya realizado.",
        "captioning": "CAPTIONING",
        "four_one_tit": "Satisfaga la creciente demanda de vídeo",
        "four_one_main": "Los contenidos de vídeo representan actualmente más del 65% de todo el tráfico de Internet, y muchos espectadores prefieren los vídeos con subtítulos. Algunos son sordos o tienen problemas de audición, mientras que a otros les gusta ver vídeos en público sin sonido. Si añade subtítulos a sus vídeos, podrá satisfacer las necesidades de millones de personas.",
        "four_two_tit": "Servir mejor a poblaciones diversas",
        "four_two_main": "El subtitulado de contenidos de vídeo le ayuda a",
        "four_two_main_one": "Aumentar la accesibilidad para las personas con discapacidad auditiva o con conocimientos limitados de inglés.",
        "four_two_main_two": "Aumentar la participación mejorando las posibilidades de que los espectadores vean el contenido hasta el final.",
        "four_two_main_three": "Atraer a nuevas audiencias y a grupos demográficos en crecimiento",
        "four_three_tit": "Cuente con nuestros lingüistas expertos",
        "four_three_main": "Nuestros profesionales son expertos en subtitulación. Hemos ayudado a miles de empresas, organizaciones sanitarias, agencias gubernamentales, etc. a ampliar su alcance y a ser más inclusivos y accesibles para las poblaciones a las que sirven.",
        "five_tit": "Hablemos de soluciones",
        "five_main": "Estamos preparados para ofrecerle servicios expertos de transcripción de audio que se ajusten a sus necesidades y a su presupuesto. Puede leer nuestro folleto Traducción y localización o elegir una hora para hablar con nuestros expertos sobre cómo resolver sus problemas de acceso lingüístico.",
        "five_download_btn": "DESCARGAR AHORA"
    },
    "alternative_format": {
        "tit": "TRANSCRIPCIÓN DE FORMATOS ALTERNATIVOS",
        "main": "Establezca relaciones con personas con discapacidad visual",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Garantice la igualdad de acceso a sus productos y servicios",
        "sec_one": "Más de 32 millones de adultos estadounidenses afirman haber sufrido una pérdida significativa de visión o ceguera. Pueden ser sus clientes, pacientes, electores o empleados. Si satisface sus necesidades presentando material escrito en Braille, en letra grande o en otros formatos alternativos, garantizará la igualdad de acceso y obtendrá mejores resultados.",
        "sec_two": "Podemos transcribir sus contenidos para lograr el máximo impacto entre las personas ciegas y con discapacidad visual. Nuestra avanzada tecnología nos permite completar sus proyectos de forma rápida y rentable, manteniendo al mismo tiempo los más altos niveles de calidad.",
        "third_tit": "BRAILLE Y LETRA GRANDE",
        "third_main": "Satisfacer las necesidades de las personas con discapacidad visual",
        "third_main_exp": "Podemos ayudarle a proporcionar materiales escritos en Braille y en ediciones de letra grande en una amplia gama de idiomas. Además, la impresión en letra grande puede mejorar sus materiales digitales y su sitio web. Cuente con un resultado de alta calidad respaldado por nuestro equipo de editores profesionales.",
        "four_tit": "TRANSCRIPCIÓN DE AUDIO",
        "four_main": "Transcriba audio para mejorar sus servicios y su negocio",
        "four_main_exp": "Es probable que sus grabaciones de audio contengan información esencial que puede mejorar su servicio de atención al cliente y la formación de su personal. Además, sirven de base para nuevos materiales de marketing. Le ahorraremos el trabajo de transcribir grabaciones con transcripción de audio a texto, voz en off y traducción en más de 290 idiomas.",
        "four_btn": "MÁS INFORMACIÓN",
        "five_tit": "Soluciones Let's Talk",
        "five_main": "Ofrecemos servicios de transcripción de formatos alternativos de alta calidad adaptados a su proyecto y presupuesto. Hable con uno de nuestros expertos para resolver sus problemas de acceso lingüístico y garantizar la accesibilidad para todos.",
        "six_tit": "Mejore la accesibilidad con tecnología de traducción avanzada",
        "six_one_tit": "TRANSCRIPCIÓN DE AUDIO",
        "six_one_contents": "Preste un mejor servicio a las personas ciegas y con discapacidad visual con versiones de audio de sus contenidos e información escritos.",
        "six_two_tit": "DAISY",
        "six_two_contents": "Aumente la facilidad de uso y la accesibilidad de sus contenidos con una exportación en formato de sistema de información digital accesible (DAISY).",
        "six_three_tit": "LUNA",
        "six_three_contents": "Transcriba sus contenidos a Moon para hacerlos más utilizables e impactantes para el público con discapacidad visual.",
        "six_four_tit": "TEXTO ELECTRÓNICO",
        "six_four_contents": "Convierta su contenido a E-Text para que el software pueda ampliarlo, leerlo en voz alta y reproducirlo en una pantalla Braille actualizable.",
        "six_five_tit": "LECTURA FÁCIL",
        "six_five_contents": "Combine texto e imágenes para ofrecer contenidos más consumibles a las personas con dificultades de aprendizaje."
    },
    "translate": {
        "tit": "SERVICIOS DE TRADUCCIÓN",
        "main": "Construya relaciones más sólidas a través de la comprensión total",
        "main_exp": "Obtenga la experiencia que necesita para comunicarse con claridad a cualquier escala.",
        "schedule_btn": "PROGRAMAR CONSULTA",
        "sec_tit": "Aumente la calidad de la traducción en más de 290 idiomas",
        "sec_one": "Más de 67 millones de estadounidenses hablan en casa una lengua distinta del inglés. Conversar con personas con conocimientos limitados de inglés (LEP) en la lengua que elijan no es sólo una buena práctica, es la clave del crecimiento. Los estudios demuestran que el 92% de las personas comprarán productos que se vendan en su lengua preferida y el 91% repetirán la compra.",
        "sec_two": "La mayoría de los proveedores de servicios de traducción están limitados en cuanto a personal, experiencia y calidad, lo que le obliga a buscar en otra parte para determinados proyectos. En lugar de trabajar con una red de servicios de nicho, asóciese con un único proveedor con más de 25 años de experiencia, más de 3500 lingüistas expertos y unos estándares de calidad probados respaldados por múltiples certificaciones ISO.",
        "sec_three": "Los expertos en traducción y localización de EMLions® dan soporte a todo tipo de contenidos y tamaños de proyectos en más de 290 idiomas. Nuestra innovadora tecnología crea eficiencias en el flujo de trabajo a la vez que facilita el servicio más rápido posible. EMLions está preparado para ayudarle",
        "sec_four": "Traducir documentos, materiales de marketing, formularios, comunicaciones y guías prácticas.",
        "sec_five": "Localizar sitios web, hardware, software, contenido de juegos y aprendizaje electrónico.",
        "sec_six": "Automatizar la traducción y localización de sus contenidos",
        "third_one": "Lingüistas profesionales al servicio de su organización",
        "third_two": "Índice de precisión en la traducción de contenidos de EMLions",
        "third_three": "Tasa de entrega puntual de proyectos de traducción de EMLions",
        "four_one_tit": "Traducción de documentos",
        "four_one_contents": "Obtenga traducciones de documentos de alta calidad personalizadas para el uso, la audiencia y la vida útil previstos.",
        "four_two_tit": "Servicios de localización",
        "four_two_contents": "Localice contenido impreso y digital para adaptarlo a las sensibilidades lingüísticas y culturales de un lugar específico, de modo que resulte una experiencia natural para su público objetivo.",
        "four_three_tit": "Herramientas de traducción integradas",
        "four_three_contents": "Obtenga resultados más rápidos con nuestra innovadora tecnología que elimina las barreras de traducción y reduce los errores humanos.",
        "four_four_tit": "Traducción automática",
        "four_four_contents": "La tecnología \"best fit\" de EMLions, basada en la inteligencia artificial, proporciona plazos de entrega rápidos para contenidos a gran escala y de baja responsabilidad, al menor coste.",
        "four_five_tit": "Servicios eLearning",
        "four_five_contents": "Reutilice sus contenidos de eLearning en diferentes idiomas para aumentar la productividad de su organización.",
        "four_six_tit": "Traducción de sitios web",
        "four_six_contents": "Website Proxy permite producir y gestionar versiones traducidas de su sitio web en inglés, o mantener la versión inglesa y las versiones traducidas siempre sincronizadas.",
        "four_sevent_tit": "Idiomas de traducción",
        "four_sevent_contents": "Obtenga más información sobre la experiencia y la calidad que ofrecen nuestros traductores en más de 290 idiomas.",
        "five_tit": "Hablemos de soluciones",
        "five_main": "Nuestros lingüistas profesionales están deseando ofrecerle servicios de traducción y localización que se ajusten a su presupuesto. Solicite ahora un presupuesto para su próximo proyecto o hable con uno de nuestros expertos en soluciones de traducción de EMLions.",
        "quote_btn": "SOLICITAR PRESUPUESTO"
    },
    "other_translate": {
        "tit": "OTROS SERVICIOS LINGÜÍSTICOS",
        "main": "Haga que cada palabra cuente",
        "main_exp": "Ayude a su organización a comunicarse de forma clara y concisa para maximizar el impacto de todos sus documentos y contenidos.",
        "quote_btn": "SOLICITAR PRESUPUESTO",
        "sec_tit": "Comuníquese eficazmente con un lenguaje sencillo y mucho más",
        "sec_one": "El adulto estadounidense medio lee a un nivel de séptimo grado. La mayoría de los lectores se pierden cuando se enfrentan a contenidos complejos, terminología desconocida y jerga del sector. Si su organización utiliza un lenguaje demasiado complicado, corre el riesgo de crear una mala experiencia para el cliente, provocar confusión y aumentar los costes.",
        "sec_two": "Como socio líder mundial en acceso lingüístico, ofrecemos soluciones de traducción en más de 290 idiomas. Hoy en día, la norma de referencia para una comunicación eficaz es el inglés sencillo, que consideramos un idioma en sí mismo. Podemos ayudarle a simplificar sus documentos para que su mensaje llegue a todos los públicos.",
        "third_one_tit": "Clarificación de documentos",
        "third_one_contents": "Reescribimos los materiales de cara al público para que sean más fáciles de entender y menos costosos de traducir a otros idiomas.",
        "third_two_tit": "Simplificación de documentos",
        "third_two_contents": "Identificamos el contenido básico que es útil para todos los públicos y lo simplificamos para que tenga mayor impacto.",
        "third_three_tit": "Transcripción de audio",
        "third_three_contents": "Podemos transcribir sus vídeos y traducirlos al idioma de su elección. Además, buscamos y grabamos locutores.",
        "third_four_tit": "Transcripción en formatos alternativos",
        "third_four_contents": "Transcribimos contenidos a Braille, incluidos el inglés y el español.",
        "four_tit": "Hablemos de soluciones",
        "four_main": "Estamos aquí con asistencia lingüística experta diseñada para satisfacer sus necesidades y su presupuesto. Solicite un presupuesto ahora o hable con uno de nuestros representantes para revisar sus retos de acceso lingüístico y discutir soluciones.",
        "schedule_btn": "PROGRAMAR CONSULTA"
    },
    "footer": {
        "interpretation": "Traducción oral",
        "testing_and_training": "Pruebas & Formación",
        "industries": "Industria",
        "careers": "Empleo",
        "resources": "Recursos",
        "customer_service": "Atención al cliente",
        "contact_info": "Contacte con nosotros en",
        "corporate_info": "EMLions Solutions 2024. Todos los derechos reservados.",
        "sitemap": "Mapa del sitio",
        "privacy_policy": "Política de privacidad",
        "cookie_policy": "Política de cookies",
        "global_ethics_hotline": "Línea Ética Global"
    }
}