export default{
    "lang": "Português",
    "home": {
        "main_explain": "Liderando o mundo por meio da tecnologia digital, criando valor com serviços direcionados ao setor Acelere a transformação digital de cada um de nossos clientes por meio de ideias de ponta e execução ágil.",
        "contact_btn": "Entrar em contato",
        "read_me_btn": "Leia mais",
        "second_tit": "Capacitando a Era Digital Criando Valor Juntos",
        "second_main": "Imagine um mundo em que as barreiras linguísticas e culturais já não existem - onde a compreensão é universal e todos estão capacitados.",
        "second_expand": "Ajudá-lo-emos a servir os membros da sua comunidade no momento em que precisam, independentemente da língua, cultura ou capacidade.",
        "second_learn_more": "Contacte-nos para saber mais",
        "second_main_tips_one": "Localização, tradução de conteúdos",
        "second_main_tips_two": "Gestão de projectos de tradução",
        "second_main_tips_three": "Melhorar a qualidade da tradução",
        "second_main_tips_four": "Aumentar a produtividade da equipa",
        "second_main_tips_five": "Potenciar a experiência através da IA",
        "second_main_tips_six": "Localização de aplicações e software",
        "third_tit": "Destaque-se no mercado global com os nossos serviços de tradução",
        "third_main": "Permitimos que nossos clientes alcancem novos mercados globalmente, conectando-se com seus públicos e fornecendo a melhor experiência possível ao cliente - em qualquer idioma. Somos um parceiro que o pode levar do planeamento à execução através dos nossos serviços, tecnologia, consultoria e experiência no setor.",
        "third_btn": "Contacte-nos para saber mais",
        "four_tit": "Como podemos ajudá-lo a ser bem sucedido?",
        "four_main": "As nossas soluções personalizadas ajudam-no a chegar a estes novos mercados de forma rápida, eficiente e eficaz, e de uma forma que maximiza os ganhos para o seu negócio.",
        "four_main_one_tit": "LOCALIZE E TRADUZA CONTEÚDO",
        "four_main_one_content": "Dimensione o seu conteúdo global e maximize o seu impacto local com a ajuda das nossas tecnologias inteligentes.",
        "four_main_two_tit": "GESTÃO DE PROJECTOS DE TRADUÇÃO",
        "four_main_two_content": "Dimensione, simplifique e automatize facilmente o processo de localização com a plataforma de gestão de tradução.",
        "four_main_three_tit": "MELHORAR A QUALIDADE DA TRADUÇÃO",
        "four_main_three_content": "Proteja a reputação da marca com verificações linguísticas que garantem que o conteúdo é claro e não contém erros.",
        "four_main_four_tit": "LOCALIZAÇÃO DE APLICAÇÕES E SOFTWARE",
        "four_main_four_content": "Certifique-se de que os produtos são perfeitamente adaptados e apresentados de forma impecável a todos os mercados com a nossa gama completa de serviços de localização.",
        "four_main_five_tit": "AUMENTAR A PRODUTIVIDADE DA EQUIPA",
        "four_main_five_content": "Dê aos tradutores e gestores de projectos as ferramentas para trabalharem de forma mais inteligente com o software de tradução assistida por computador (CAT) mais fiável.",
        "four_main_six_tit": "POTENCIAR A EXPERIÊNCIA ATRAVÉS DA IA",
        "four_main_six_content": "Responda às perguntas dos clientes a uma fração do custo e com rapidez suficiente para se transformar em acções relevantes e oportunas com a inteligência artificial.",
        "five_tit": "Explore os serviços empresariais hoje mesmo.",
        "five_main": "Vamos alcançar os seus objectivos mais ambiciosos - juntos.",
        "six_tit": "Quem está a utilizar EMLIONS?",
        "six_main": "Soluções de transformação de conteúdo e dados em escala global reconhecidas por clientes, tradutores e organizações do setor.",
        "six_main_find": "Saiba mais."
    },
    "contact_btn": "Contacte-nos",
    "translation": {
        "nav": "Tradução",
        "menu_doc": "Tradução de documentos",
        "menu_localization": "Serviços de localização",
        "menu_tools": "Ferramentas de tradução integradas",
        "menu_machine": "Tradução automática",
        "menu_elearning": "Serviços de eLearning",
        "menu_website": "Tradução de sites",
        "menu_languages": "Línguas de tradução"
    },
    "other_language_services": {
        "nav": "Outros serviços linguísticos",
        "menu_clarification": "Esclarecimento de documentos",
        "menu_simplification": "Simplificação de documentos",
        "menu_audio": "Transcrição de áudio",
        "menu_alternative": "Transcrição de formatos alternativos"
    },
    "about": {
        "title_fir": "Sobre nós",
        "title_sed": "Fornecedor de serviços de TI",
        "title_explain_fir": "Um parceiro de confiança de 157 clientes da Fortune 500.",
        "title_explain_sec": "A nossa força é o nosso pessoal e somos mais do que uma simples empresa de tradução. Somos uma família de indivíduos empenhados e dedicados a ser o principal facilitador de comunicações globais do mundo.",
        "title_num_one": "clientes da Fortune 500",
        "title_num_two": "anos de experiência",
        "title_num_three": "tradutores profissionais",
        "second_title": "Trazemos-lhe vantagens poderosas para navegar na sua transformação digital",
        "second_one_tit": "Layout global de negócios e capacidades de serviço de operação",
        "second_one_main": "EMLIONS é a casa de 3.500 profissionais em todo o mundo, distribuídos em 50 centros de entrega em 28 cidades. Estamos empenhados em fornecer ofertas em mais de 200 idiomas, suporte técnico 7x24 e prestação de serviços a clientes em todo o mundo.",
        "second_two_tit": "Acelerar a inovação através de uma vasta experiência no sector",
        "second_two_main": "Com 25 anos de experiência na indústria, a Emlions fornece aos clientes em todo o mundo serviços que abrangem consultoria digital, interação de experiências, implementação de tecnologia e operações. Ao tirar partido de uma vasta experiência em sectores como a alta tecnologia, finanças, fabrico, telecomunicações e Internet, a Emlions estabeleceu relações de cooperação a longo prazo com 157 empresas da Fortune 500.",
        "second_three_tit": "Impulsionar actualizações de qualidade com segurança e gestão de qualidade comprovadas",
        "second_three_main": "Desde que a Emlions passou as normas do sistema de gestão da qualidade ISO 9001 em 2000, também passámos sucessivamente as certificações CMMI L5, ISO 20000 e ISO 27001. A Emlions cumpre e excede sempre as expectativas dos clientes em termos de segurança e qualidade das instalações, do pessoal e dos procedimentos, com sistemas de controlo de qualidade que atingem padrões internacionais.",
        "second_four_tit": "Potenciar a era digital e criar valor para os clientes",
        "second_four_main": "A Emlions dedica-se a inovar os produtos e serviços orientados para a experiência do cliente, bem como o marketing digital integrado para acelerar a implementação de capacidades digitais, e a fornecer aos clientes serviços digitais superiores e ajudá-los a navegar na transformação digital, integrando consultoria estratégica, criatividade e design de experiência, bem como através de meios técnicos como o telemóvel, IoT, computação em nuvem e big data."
    },
    "solutions": {
        "nav": "SOLUÇÕES",
        "tit_fir": "Indústria",
        "tit_sec": "Soluções",
        "main_fir": "Como comunicadores especializados, sabemos que o contexto é tudo. É por isso que temos equipas dedicadas com profunda experiência em quase todos os sectores da indústria.",
        "main_sec": "Esta experiência personalizada dá-lhe a tranquilidade de saber que o seu conteúdo global é eficaz e está em conformidade com os requisitos específicos de formatação, terminologia e regulamentação.",
        "get_in_touch": "Entre em contacto",
        "read_more": "Ler mais",
        "sec_tit": "Como é que o podemos ajudar a ter sucesso?",
        "sec_main": "As nossas soluções personalizadas ajudam-no a chegar a estes novos mercados de forma rápida, eficiente e eficaz, e de uma forma que maximiza os ganhos para a sua empresa.",
        "learn_more": "Saiba mais",
        "sec_one_tit": "Ciências da Vida",
        "sec_one_main": "Ganhe maior eficiência nos processos, reduza os custos de tradução e acelere a entrada no mercado com as nossas soluções linguísticas, de COA e de conteúdo especializadas em ciências da vida, investigação médica e organizações de investigação contratadas (CROs).",
        "sec_two_tit": "Lei",
        "sec_two_main": "Podemos ajudá-lo a resolver desafios de conteúdos multilingues em todas as práticas jurídicas para que possa acompanhar o ritmo de uma carga de trabalho de tradução em rápida expansão, quer esteja a gerir dados de e-discovery, preenchimentos de fusões e aquisições (M&amp;A), documentos de PI, contratos e acordos, materiais de marketing e muito mais.",
        "sec_three_tit": "Soluções governamentais",
        "sec_three_main": "Satisfaça os requisitos governamentais de conteúdos multilingues com as nossas soluções profissionais de gestão linguística e de conteúdos para o sector público, inteligência, defesa e agências de aplicação da lei.",
        "sec_four_tit": "Serviços financeiros",
        "sec_four_main": "As nossas soluções linguísticas e de conteúdos apoiam as necessidades específicas das organizações de serviços financeiros, para que possa criar as melhores relações possíveis com os seus clientes em qualquer língua, a nível global.",
        "sec_five_tit": "Cuidados geridos",
        "sec_five_main": "Comunique com confiança para além das barreiras linguísticas e de competências. As nossas soluções e experiência ajudam-no a satisfazer as suas necessidades de tradução e conformidade durante todo o ano, e não apenas durante o Período Anual de Inscrição (AEP).",
        "sec_six_tit": "Aeroespacial e Defesa",
        "sec_six_main": "Satisfaça as exigências rigorosas das aeronaves comerciais e dos equipamentos de defesa e aumente a produtividade e a eficiência ao longo do ciclo de vida do produto - incluindo a manutenção pós-entrega - com a nossa suite de publicação para o S1000D.",
        "sec_sevent_tit": "Fabrico industrial",
        "sec_sevent_main": "As nossas soluções abrangentes de linguagem e conteúdo podem ajudá-lo a satisfazer as necessidades de toda a cadeia de valor, adoptando práticas de software ágeis e maduras, ao mesmo tempo que reduzem os custos e aumentam a eficiência.",
        "sec_eight_tit": "Software de alta tecnologia",
        "sec_eight_main": "Responda às exigências do mercado e ao aumento da velocidade dos produtos com as nossas soluções linguísticas e de conteúdos especializadas para software e hardware empresariais, serviços de TI, redes sociais, jogos, aplicações online, multimédia, aplicações de produtividade, computação em nuvem e dispositivos móveis.",
        "sec_nine_tit": "Varejo e comércio eletrônico",
        "sec_nine_main": "Os serviços linguísticos e as tecnologias da Emlions preparam os retalhistas para o sucesso global com estratégias de localização de comércio eletrónico que satisfazem as necessidades do seu público-alvo.",
        "sec_ten_tit": "Viagens, lazer e hospitalidade",
        "sec_ten_main": "As nossas soluções completas de conteúdo multilingue para fornecedores, distribuidores e retalhistas ajudam-no a criar, traduzir e fornecer grandes bases de dados de conteúdo de inventário, reduzindo os custos, mantendo a satisfação do consumidor e cumprindo os regulamentos de proteção de dados do consumidor.",
        "sec_elevent_tit": "Automóvel",
        "sec_elevent_main": "Gerir o seu conteúdo multilingue de marketing, produtos e serviços em todos os canais digitais, para potenciais clientes, clientes, veículos ligados e uma organização pós-venda distribuída globalmente.",
        "sec_twelve_tit": "Serviços de globalização de produtos químicos",
        "sec_twelve_main": "Os nossos clientes da indústria química confiam em nós para fornecer serviços de tradução, interpretação e PI através de linguistas altamente especializados e peritos técnicos com conhecimentos especializados da indústria e consciência das normas e regulamentos internacionais.",
        "sec_Thirteen_tit": "Tradução multilingue no sector da energia",
        "sec_Thirteen_main": "As empresas do sector da energia enfrentam uma procura global acelerada e novos intervenientes em mercados cada vez mais competitivos. O apoio global de PI e uma comunicação multilingue eficaz e consistente são fundamentais.",
        "sec_fourteen_tit": "Serviços de tradução no sector dos seguros",
        "sec_fourteen_main": "A nossa experiência no assunto garante que a informação produz resultados. Utilizamos apenas tradutores e intérpretes especializados, muitos dos quais são antigos profissionais, corretores, subscritores, gestores de risco e membros do Chartered Institute of Insurance.",
        "sec_fifteen_tit": "Serviços de localização de mídia",
        "sec_fifteen_main": "Na Emlions, a nossa abordagem abrangente ao conteúdo global, localização e tecnologia ajuda-o a manter-se relevante no mundo digital.",
        "sec_sixteen_tit": "Serviços de localização de telecomunicações",
        "sec_sixteen_main": "O cenário de telecomunicações altamente competitivo de hoje está levando as empresas a expandir não apenas a escala e o escopo de suas ofertas, mas também seu alcance global."
    },
    "doc_translation": {
        "tit": "TRADUÇÃO DE DOCUMENTOS",
        "main": "Construa relações mais fortes com os clientes em mais de 290 idiomas",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "Deixe que os nossos especialistas resolvam todas as suas necessidades de tradução",
        "sec_one": "Os seus projectos de tradução não estão limitados a um idioma, canal ou prazo. Obtenha a experiência de que necessita para comunicar claramente em qualquer escala e transformar os seus desafios linguísticos em oportunidades - com soluções criativas que melhoram a eficiência e promovem relações significativas.",
        "sec_two": "Os nossos mais de 3.500 linguistas especializados fornecem traduções de alta qualidade e de alto nível, personalizadas para o uso e o público-alvo pretendidos. Também submetem cada projeto a uma tradução, revisão e correção rigorosas para garantir que o resultado final excede os seus padrões.",
        "trird_one": "Linguistas especializados na equipa",
        "third_two": "Idiomas disponíveis para tradução",
        "third_three": "Qualidade de conteúdo garantida",
        "four_tit": "Traduzir qualquer tipo de conteúdo em mais de 290 idiomas",
        "four_one_tit": "Tradução de documentos",
        "four_one_contents": "Cartas, formulários, e-mails, manuais técnicos, conteúdo on-line, saída CMS, material de marketing e políticas e procedimentos de RH.",
        "four_two_tit": "Websites",
        "four_two_contents": "HTML, ASP, Scripts, aplicações baseadas em Java e interfaces baseadas em bases de dados.",
        "four_three_tit": "Multimédia",
        "four_three_contents": "Engenharia Flash, voiceover e filmes de impacto.",
        "four_four_tit": "Materiais de formação",
        "four_four_contents": "Tutoriais, modelos, aplicações de eLearning e ERP.",
        "four_five_tit": "Software",
        "four_five_contents": "GUI, conteúdo de bases de dados, dispositivos sem fios, aplicações Web e sistemas de processos empresariais.",
        "four_six_tit": "Sistemas de ajuda e gestão de conteúdos",
        "four_six_contents": "WinHelp, HTML Help, WebHelp, JavaHelp, Oracle Help, FlashHelp, AppleHelp, HubSpot, WordPress, Drupal e sistemas proprietários.",
        "five_tit": "Tradução em todos os sectores",
        "five_one_tit": "Negócios:",
        "five_one_contents": "Informações sobre produtos, comunicação com clientes e materiais para funcionários",
        "five_two_tit": "Cuidados de saúde:",
        "five_two_contents": "Documentos vitais, instruções de alta, avisos de elegibilidade e formulários de pacientes",
        "five_three_tit": "Serviços financeiros:",
        "five_three_contents": "Documentos de empréstimo, contratos, documentos de hipoteca, aplicações financeiras, extractos de conta e relatórios de crédito",
        "five_five_tit": "Seguros:",
        "five_five_contents": "Formulários de sinistros, informações sobre apólices e relatórios de acidentes",
        "five_six_tit": "Governo:",
        "five_six_contents": "Avisos de direitos, formulários de consentimento, queixas, cartas que exigem resposta, candidaturas e divulgação pública",
        "six_tit": "A diferença de tradução da EMLions",
        "six_one_tit": "Tradutores humanos",
        "six_one_contents": "Usamos um toque humano e compreensão cultural para garantir que cada trabalho transmita o verdadeiro significado do seu conteúdo original.",
        "six_two_tit": "Personalização",
        "six_two_contents": "Adaptamo-nos e personalizamos cada projeto tendo em conta as suas necessidades, prazos e fluxos de trabalho.",
        "six_three_tit": "100% de garantia de qualidade",
        "six_three_contents": "Cada projeto é traduzido, copiado e revisto por, pelo menos, dois linguistas profissionais.",
        "six_four_tit": "Entrega dentro do prazo",
        "six_four_contents": "A nossa taxa de entrega atempada para a tradução de documentos é de 99,5%.",
        "sevent_tit": "Saiba mais sobre os serviços de tradução de documentos",
        "sevent_contents": "Saiba como obter ajuda para fornecer conteúdo consistente e de alta qualidade em qualquer idioma. Pode ler a nossa brochura de Tradução e Localização ou pedir um orçamento aos nossos especialistas.",
        "download_btn": "DESCARREGAR AGORA"
    },
    "local_services": {
        "tit": "SERVIÇOS DE LOCALIZAÇÃO",
        "main": "Construa relacionamentos mais fortes em mais de 290 idiomas",
        "schedule_btn": "AGENDAR CONSULTA",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "Aumente a qualidade da localização e reduza os custos",
        "sec_one": "Não há limite para o número de pessoas que o seu conteúdo digital pode alcançar, mas a maioria dos clientes prefere comprar produtos que comunicam na sua língua. Certifique-se de que transmite a sua mensagem de forma consistente em todo o mundo, trabalhando com um parceiro com a experiência e a escala necessárias para lidar com todas as suas necessidades de conteúdos e projectos de qualquer escala.",
        "sec_two": "Os nossos serviços de tradução e localização são geridos por mais de 3500 linguistas especializados, prontos a produzir resultados consistentes e de alta qualidade em mais de 290 línguas. Localizaremos o seu conteúdo impresso ou digital de acordo com as sensibilidades linguísticas e culturais de um local específico, para que seja uma experiência natural para o seu público-alvo. Além disso, para garantir uma entrega atempada e bem sucedida, adoptamos procedimentos de controlo de qualidade abrangentes e completos para cada projeto.",
        "third_one": "Linguistas profissionais",
        "third_two": "Idiomas disponíveis para tradução",
        "third_three": "Taxa de precisão da tradução",
        "four_tit": "SERVIÇOS DE LOCALIZAÇÃO",
        "four_main": "Sirva melhor os seus clientes, pacientes e constituintes",
        "four_main_exp": "Nossos profissionais podem localizar qualquer tipo de conteúdo impresso ou digital, incluindo:",
        "four_main_exp_one": "Websites, aplicações e software",
        "four_main_exp_two": "Conteúdo de vídeos, legendas, gráficos e narração",
        "four_main_exp_three": "Documentos e materiais de marketing",
        "four_main_exp_four": "eLearning e formação de membros da equipa",
        "four_main_exp_five": "Documentação regulamentar e de conformidade",
        "four_main_exp_six": "Jogos de vídeo e muito mais",
        "five_tit": "CONECTORES CMS",
        "five_main": "Automatize suas transferências de arquivos",
        "five_main_exp": "A EMLions reimaginou a tradução a partir da perspetiva dos nossos clientes, incorporando a automatização multilingue diretamente nos sistemas de conteúdos e aplicações empresariais, permitindo aos nossos clientes enviar-nos facilmente qualquer conteúdo. Escolha um dos nossos mais de 50 conectores prontos a utilizar para aumentar a eficiência da transferência de ficheiros e dos fluxos de trabalho de publicação.",
        "five_btn": "SAIBA MAIS",
        "six_tit": "PROXY DE WEBSITE",
        "six_main": "Traduza facilmente o seu sítio Web",
        "six_main_exp": "Simplifique o processo de produção e gerenciamento de versões traduzidas de seu site em inglês ou plataforma de comércio eletrônico. Com o EMLions® Website Proxy, as versões traduzidas do seu site estão sempre em sincronia com a versão em inglês. Além disso, se não tiver recursos para hospedar um site traduzido, o Website Proxy ajuda a espelhar seu conteúdo e exibi-lo no idioma de sua escolha.",
        "six_btn": "SAIBA MAIS",
        "sevent_tit": "Saiba mais sobre as ferramentas de tradução integradas",
        "sevent_main": "Forneça conteúdo consistente e de alta qualidade em qualquer idioma sem sacrificar a precisão. Pode ler a nossa brochura de Tradução e Localização ou escolher uma hora para falar com os nossos especialistas sobre como resolver os seus desafios de tradução.",
        "sevent_download_btn": "BAIXAR AGORA",
        "sevent_schedule_btn": "AGENDAR CONSULTA",
        "eigth_tit": "Serviços de localização do EMLions",
        "eigth_one_tit": "TRADUTORES HUMANOS",
        "eigth_one_contents": "Usamos um toque humano e compreensão cultural para garantir que cada trabalho transmita o significado do seu conteúdo original.",
        "eigth_two_tit": "PERSONALIZAÇÃO",
        "eigth_two_contents": "Fornecemos tecnologia de ponta e conhecimentos linguísticos para nos adaptarmos às necessidades do seu projeto em constante evolução.",
        "eigth_three_tit": "100% DE GARANTIA DE QUALIDADE",
        "eigth_three_contents": "Cada projeto é traduzido, copiado e revisto por, pelo menos, dois linguistas.",
        "eigth_four_tit": "ENTREGA PONTUAL",
        "eigth_four_contents": "Nunca falhámos uma data de entrada em funcionamento."
    },
    "integrated_translation": {
        "tit": "FERRAMENTAS DE TRADUÇÃO INTEGRADAS",
        "main": "Acelere a tradução sem sacrificar a precisão",
        "schedule_btn": "AGENDAR CONSULTA",
        "sec_tit": "Coloque a tecnologia de tradução na ponta dos seus dedos",
        "sec_one": "Quer esteja a expandir os seus programas de marketing por todo o mundo, a traduzir informações confidenciais de pacientes ou a oferecer materiais educativos em novas línguas, precisa de traduções de alta qualidade que se adaptem às suas necessidades e ao seu orçamento. A utilização da tecnologia de tradução certa pode simplificar muitos dos passos do processo, permitindo-lhe produzir materiais precisos num prazo mais curto e a um custo mais baixo.",
        "sec_two": "Oferecemos tecnologia de tradução que torna o processo tão simples, fácil e seguro quanto possível. Utilize o nosso Portal de Tradução EMLions® encriptado de ponta a ponta para submeter rapidamente projectos aos nossos mais de 3500 linguistas especializados. Também pode transferir as suas traduções, ver o estado do projeto e aceder a relatórios online. O resultado é um conteúdo preciso e consistente para o seu público e paz de espírito para si e para a sua equipa.",
        "third_one": "Línguas disponíveis para tradução",
        "third_two": "Taxa de precisão da tradução",
        "third_three": "Entrega atempada de projectos de tradução",
        "four_tit": "CONECTORES CMS",
        "four_main": "Automatize suas transferências de arquivos",
        "four_main_exp": "A EMLions reimaginou a tradução na perspetiva dos nossos clientes, incorporando a automatização multilingue diretamente em sistemas de conteúdos e aplicações empresariais, permitindo aos nossos clientes enviar-nos facilmente qualquer conteúdo. Escolha um dos nossos mais de 50 conectores prontos a utilizar para aumentar a eficiência da transferência de ficheiros e dos fluxos de trabalho de publicação.",
        "four_btn": "SAIBA MAIS",
        "five_tit": "PROXY DE WEBSITE",
        "five_main": "Traduza facilmente o seu sítio Web",
        "five_main_exp": "Simplifique o processo de produção e gerenciamento de versões traduzidas de seu site em inglês ou plataforma de comércio eletrônico. Com o EMLions® Website Proxy, as versões traduzidas do seu site estão sempre em sincronia com a versão em inglês. Além disso, se não tiver recursos para hospedar um site traduzido, o Website Proxy ajuda a espelhar seu conteúdo e exibi-lo no idioma de sua escolha.",
        "five_btn": "SAIBA MAIS",
        "six_tit": "TRADUÇÃO AUTOMÁTICA",
        "six_main": "Obtenha traduções em minutos",
        "six_main_exp": "Traduza conteúdo de grande escala o mais rápido possível. Com a tradução automática EMLions®, nossa tecnologia de \"melhor ajuste\" orientada por IA fornece conteúdo fácil de entender em pouco tempo. E quando combina o seu conteúdo com o nosso processo de pós-edição com certificação ISO, pode ter a certeza de que todas as suas comunicações e resultados serão exactos.",
        "six_btn": "APRENDA MAIS",
        "sevent_tit": "Saiba mais sobre as ferramentas de tradução integradas",
        "sevent_main": "Forneça conteúdo consistente e de alta qualidade em qualquer idioma sem sacrificar a precisão. Pode ler a nossa brochura de Tradução e Localização ou escolher uma altura para falar com os nossos especialistas sobre como resolver os seus desafios de tradução.",
        "sevent_download_btn": "BAIXAR AGORA",
        "sevent_schedule_btn": "AGENDAR CONSULTA",
        "eigth_tit": "Ferramentas de tradução integradas",
        "eight_one_tit": "TECNOLOGIA AVANÇADA",
        "eigth_one_contents": "Lideramos o setor criando novas soluções de localização.",
        "eight_two_tit": "PERSONALIZAÇÃO",
        "eigth_two_contents": "Fornecemos tecnologia de ponta e conhecimentos linguísticos para nos adaptarmos às necessidades do seu projeto em constante evolução.",
        "eight_three_tit": "100% DE GARANTIA DE QUALIDADE",
        "eigth_three_contents": "A nossa equipa integrada de especialistas da indústria garante resultados da mais alta qualidade.",
        "eight_four_tit": "ENTREGA ATEMPADA",
        "eigth_four_contents": "A nossa taxa de pontualidade é de 99,5%."
    },
    "machine_translation": {
        "tit": "TRADUÇÃO AUTOMÁTICA",
        "main": "Tenha o seu conteúdo traduzido em segundos",
        "schedule_btn": "AGENDAR CONSULTA",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "Poupe tempo e dinheiro automatizando as traduções",
        "sec_one": "Obtenha controlo total sobre o prazo e o custo dos seus projectos de tradução. A tradução automática utiliza motores especializados para traduzir conteúdos instantaneamente. Para obter os resultados mais rápidos possíveis, pode automatizar totalmente o processo ou seguir um fluxo de trabalho híbrido no qual um dos nossos mais de 3.500 linguistas profissionais apoia o seu projeto.",
        "sec_two": "A nossa tradução automática utiliza inteligência artificial para aumentar a precisão das suas traduções. Pode personalizar os seus processos de pós-edição e revisão com base nas necessidades do seu projeto, orçamento e calendário.",
        "third_one": "Idiomas disponíveis para tradução",
        "third_two": "Motores de \"melhor ajuste\" orientados por IA",
        "third_three": "Linguistas disponíveis para pós-edição",
        "four_tit": "TRADUÇÃO RÁPIDA",
        "four_main": "A tradução automática é adequada para si?",
        "four_main_exp": "A tradução automática funciona melhor para traduzir grandes quantidades de conteúdo num prazo apertado. É ideal para conteúdo de baixo risco de responsabilidade, como comunicações de atendimento ao cliente e artigos de blogue. Se estiver a trabalhar com documentos jurídicos, médicos ou científicos, ou com conteúdo que exija uma análise de sentimentos mais profunda, obterá melhores resultados com serviços de tradução tradicionais.",
        "five_tit": "TECNOLOGIA",
        "five_main": "Aumente a precisão com uma tecnologia poderosa",
        "five_main_exp": "A tradução automática EMLions® utiliza mais de 40 motores líderes para maximizar a precisão. Procura automaticamente as melhores opções para os seus idiomas e tipo de conteúdo para garantir o melhor resultado para a sua mensagem. Utilizando o nosso fluxo de trabalho híbrido, juntamente com a memória de tradução e os glossários da sua empresa, podemos aumentar a precisão e ajudá-lo a controlar os custos.",
        "six_tit": "Ferramentas de tradução automática da EMLions",
        "six_one_tit": "TECNOLOGIA AVANÇADA",
        "six_one_main": "O nosso centro de tradução baseado em IA oferece a tradução automática mais adequada ao seu conteúdo.",
        "six_two_tit": "PERSONALIZAÇÃO",
        "six_two_main": "Seleccione o fluxo de trabalho de tradução automática que melhor se adapta às suas necessidades de conteúdo.",
        "six_three_tit": "SEGURANÇA",
        "six_three_main": "O seu conteúdo valioso e proprietário permanece no nosso ambiente seguro.",
        "six_four_tit": "TEMPO DE RESPOSTA RÁPIDO",
        "six_four_main": "Conclua os seus projectos de forma instantânea ou mais rápida com o nosso fluxo de trabalho MTPE.",
        "sevent_tit": "Saiba mais sobre tradução e localização",
        "sevent_main": "Saiba mais sobre como fornecer conteúdo consistente e de alta qualidade em qualquer idioma e para qualquer cultura. Pode ler a nossa brochura de Tradução e Localização ou escolher uma altura para falar com os nossos especialistas sobre como resolver os seus desafios de tradução.",
        "sevent_download_btn": "BAIXAR AGORA",
        "sevent_schedule_btn": "AGENDAR CONSULTA"
    },
    "elearning_translation": {
        "tit": "SERVIÇOS DE E-LEARNING",
        "main": "Forneça conteúdo de eLearning em mais de 290 idiomas",
        "schedule_btn": "AGENDAR CONSULTA",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "Aumente o retorno do seu investimento em treinamento",
        "sec_one": "As organizações em todo o mundo estão a adotar o eLearning como uma forma eficiente e económica de fornecer conteúdos educativos e de formação à sua força de trabalho e clientes dispersos. No entanto, as empresas que não traduzem e localizam corretamente os seus conteúdos de eLearning podem limitar a eficácia da sua formação e o seu ROI.",
        "sec_two": "Prepare os seus programas de eLearning para um público global, trabalhando com os nossos mais de 3.500 linguistas especializados. Pode contar com resultados consistentes e de alta qualidade. Além disso, aceda ao nosso portal encriptado de ponta a ponta para carregar materiais, acompanhar o progresso e recuperar traduções - garantindo eficiência e segurança total em cada projeto.",
        "third_one": "Linguistas especializados na equipa",
        "third_two": "Idiomas disponíveis para tradução",
        "third_three": "Taxa de precisão da tradução",
        "four_tit": "SERVIÇOS DE E-LEARNING",
        "four_main": "Conte com 100% de garantia de qualidade de conteúdo",
        "four_main_exp": "Como líder do sector em serviços linguísticos, gerimos milhares de projectos de tradução e localização todos os anos em mais de 290 línguas. As nossas décadas de experiência no apoio a conteúdos de formação remota cultivaram a nossa experiência na conceção de programas de eLearning altamente eficazes e acessíveis. A qualidade do nosso conteúdo é 100% garantida, e 99,5% dos nossos clientes classificam os nossos serviços de tradução e localização como \"bons ou melhores\".",
        "five_tit": "TECNOLOGIA",
        "five_main": "Aplicações e suporte de eLearning",
        "five_main_exp": "93% das empresas da Fortune 100 contam connosco para traduzir e localizar conteúdos especializados - incluindo cursos de eLearning, materiais de formação, webinars, livros electrónicos, integração e formação em sistemas e produtos. Além disso, a nossa equipa de especialistas é especializada em formação em ERP para SAP, Oracle, Salesforce e outras plataformas.",
        "six_tit": "EXPERIÊNCIA GLOBAL",
        "six_main": "Implante treinamento específico para o setor em todo o mundo",
        "six_main_exp": "Garantimos que o vocabulário exclusivo da sua organização e a terminologia específica do sector são compreendidos globalmente em mais de 290 idiomas. Os nossos mais de 3.500 linguistas especializados têm uma profunda experiência na tradução de uma vasta gama de conteúdos de eLearning em sectores, incluindo",
        "six_main_one": "Saúde",
        "six_main_two": "TI e SaaS",
        "six_main_three": "Retalho e comércio eletrónico",
        "six_main_four": "Educação",
        "six_main_five": "Serviços financeiros",
        "six_main_six": "Indústria farmacêutica",
        "six_main_sevent": "Indústria transformadora",
        "six_main_eight": "Construção e muito mais",
        "sevent_tit": "Saiba mais sobre o eLearning",
        "sevent_main": "Saiba mais sobre como fornecer conteúdo de eLearning consistente e de alta qualidade em mais de 290 idiomas. Pode ler a nossa brochura de Tradução e Localização ou escolher uma altura para falar com os nossos especialistas sobre como resolver os seus desafios de eLearning.",
        "sevent_download_btn": "BAIXAR AGORA",
        "sevent_schedule_btn": "AGENDAR CONSULTA",
        "eight_tit": "Serviços de eLearning",
        "eigth_one_tit": "SERVIÇO COMPLETO",
        "eigth_one_contents": "Reproduzimos o seu conteúdo de aprendizagem de ponta a ponta para ativar as suas audiências globais.",
        "eight_two_tit": "PERSONALIZAÇÃO",
        "eigth_two_contents": "Fornecemos tecnologia de ponta e conhecimentos linguísticos para nos adaptarmos às necessidades do seu projeto em constante evolução.",
        "eight_three_tit": "100% DE GARANTIA DE QUALIDADE",
        "eigth_three_contents": "A nossa equipa integrada de especialistas da indústria assegura resultados da mais alta qualidade.",
        "eight_four_tit": "ENTREGA PONTUAL",
        "eigth_four_contents": "Nunca falhámos uma data de entrada em funcionamento."
    },
    "website_translation": {
        "tit": "TRADUÇÃO DE WEBSITES",
        "main": "Localize seu site para públicos globais",
        "schedule_btn": "AGENDAR CONSULTA",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "Traduza seu site em mais de 290 idiomas",
        "sec_one": "O conteúdo do seu website está em constante evolução, assim como a necessidade dos seus consumidores de o verem no idioma que compreendem. Manter seu site atualizado com o conteúdo mais recente pode ser uma tarefa difícil por si só. Quando também é necessário traduzi-lo para vários idiomas, a manutenção do site pode se tornar um grande desafio que afeta a produtividade da equipe e a lucratividade da organização.",
        "sec_two": "Com o EMLions® Website Proxy, é possível:",
        "sec_three": "Traduzir documentos, materiais de marketing, formulários, comunicações e guias de instruções",
        "sec_four": "Localizar websites, hardware, software, conteúdo de jogos e eLearning",
        "sec_five": "Automatizar a tradução e a localização do seu conteúdo",
        "third_one": "Linguistas especializados na equipa",
        "third_two": "Taxa de precisão da tradução",
        "third_three": "Qualidade de conteúdo garantida",
        "four_tit": "CONECTORES CMS",
        "four_main": "Automatize suas transferências de arquivos",
        "four_main_exp": "A EMLions reimaginou a tradução do ponto de vista dos nossos clientes, incorporando a automatização multilingue diretamente em sistemas de conteúdos e aplicações empresariais, permitindo aos nossos clientes enviar-nos facilmente qualquer conteúdo. Escolha um dos nossos mais de 50 conectores prontos a utilizar para aumentar a eficiência da transferência de ficheiros e dos fluxos de trabalho de publicação.",
        "four_btn": "SAIBA MAIS",
        "five_tit": "TRADUÇÃO AUTOMÁTICA",
        "five_main": "É adequada para si?",
        "five_main_exp": "A tradução automática funciona melhor para traduzir grandes quantidades de conteúdo num prazo apertado. É ideal para conteúdo de baixo risco de responsabilidade, como comunicações de atendimento ao cliente e artigos de blogue. Se estiver a trabalhar com documentos jurídicos, médicos ou científicos, ou com conteúdo que exija uma análise de sentimentos mais profunda, obterá melhores resultados com os serviços de tradução tradicionais.",
        "five_btn": "SAIBA MAIS",
        "six_tit": "Tradução de websites da EMLions",
        "six_one_tit": "TECNOLOGIA AVANÇADA",
        "six_one_contents": "Fornecemos tecnologia de ponta e experiência linguística para nos adaptarmos às necessidades de evolução do seu projeto de website.",
        "six_two_tit": "PERSONALIZAÇÃO",
        "six_two_contents": "Fornecemos tecnologia de ponta e conhecimentos linguísticos para nos adaptarmos às necessidades de evolução do seu projeto de website.",
        "six_three_tit": "100% DE GARANTIA DE QUALIDADE",
        "six_three_contents": "Abordagem flexível para qualquer complexidade e orçamento de website.",
        "six_four_tit": "ENTREGA ATEMPADA",
        "six_four_contents": "A nossa equipa integrada de especialistas do sector garante resultados da mais alta qualidade.",
        "sevent_tit": "Saiba mais sobre tradução e localização",
        "sevent_main": "Saiba mais sobre como fornecer conteúdo consistente e de alta qualidade em qualquer idioma e para qualquer cultura. Pode ler a nossa brochura de Tradução e Localização ou escolher uma altura para falar com os nossos especialistas sobre como resolver os seus desafios de tradução."
    },
    "translation_languages": {
        "tit": "IDIOMAS DE TRADUÇÃO",
        "main": "Mais de 290 idiomas de tradução e localização disponíveis",
        "schedule_btn": "AGENDAR CONSULTA",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "Saiba mais sobre as ferramentas de tradução integradas",
        "sec_main": "Forneça conteúdo consistente e de alta qualidade em qualquer idioma sem sacrificar a precisão. Pode ler a nossa brochura de Tradução e Localização ou escolher uma altura para falar com os nossos especialistas sobre como resolver os seus desafios de tradução.",
        "sec_download_btn": "BAIXAR AGORA",
        "lang": {
            "Achi": "Achi",
            "Achinese": "Acehnês",
            "Acholi": "Acholi",
            "Afar": "Afar",
            "Afghani": "Afegão",
            "Afrikaans": "Afrikaans",
            "Akan": "Akan",
            "Akateko": "Akatec",
            "Albanian": "Albanês",
            "Amharic": "Amárico",
            "Anuak": "Anuak",
            "Apache": "Apache",
            "Arabic": "Árabe",
            "Arabic_Iraq": "Árabe (Iraque)",
            "Arabic_Juba": "Árabe (Juba)",
            "Arabic_Saudi_Arabia": "Árabe (Arábia Saudita)",
            "Arabic_Sudan": "Árabe (Sudão)",
            "Arabic_Yemen": "Árabe (Iêmen)",
            "Arakanese": "Arakanês",
            "Armenian": "Armênio",
            "Assamese": "Assamês",
            "Assyrian": "Assírio",
            "Azeri": "Azeri",
            "Azeri_Cyrillic": "Azeri (Cirílico)",
            "Azeri_Latin": "Azeri (Latim)",
            "Bajan": "Bajan",
            "Bajuni": "Bajuni",
            "Bambara": "Bambara",
            "Basque": "Basco",
            "Bassa": "Bassa",
            "Behdini": "Behdini",
            "Beja": "Beja",
            "Belarusian": "Bielorrusso",
            "Belgian": "Belga",
            "Bemba": "Bemba",
            "Bengali": "Bengali",
            "Berber": "Berbere",
            "Bhojpuri": "Bhojpuri",
            "Bislama": "Bislama",
            "Blackfoot_Canada": "Blackfoot (Canadá)",
            "Bosnian": "Bósnio",
            "Bosnian_Cyrillic": "Bósnio (Cirílico)",
            "Bosnian_Latin": "Bósnio (Latim)",
            "Braille": "Braille",
            "Bulgarian": "Búlgaro",
            "Burmese": "Birmanês",
            "Cambodian": "Cambojano",
            "Cambodian_Pidgin_English": "Pidgin Inglês do Camboja",
            "Cantonese": "Cantonês",
            "Cape_Verdean_Creole": "Crioulo Cabo-verdiano",
            "Catalan": "Catalão",
            "Cebuano": "Cebuano",
            "Central_Alaskan_Yupik": "Yupik Central do Alasca",
            "Chaldean": "Caldeu",
            "Chamorro": "Chamorro",
            "Chavacano": "Chavacano",
            "Cherokee": "Cherokee",
            "Chichewa": "Chichewa",
            "Chin": "Chin",
            "Chinese_Simplified": "Chinês (Simplificado)",
            "Chinese_Traditional": "Chinês (Tradicional)",
            "Chinese_Traditional_HK": "Chinês (Hong Kong)",
            "Choctaw": "Choctaw",
            "Chui_Chow": "Chui Chow",
            "Chuj": "Chuj",
            "Chuukese": "Chuukese",
            "Cree_Canada": "Cree (Canadá)",
            "Croatian": "Croata",
            "Crow": "Crow",
            "Czech": "Tcheco",
            "Dakota": "Dakota",
            "Danish": "Dinamarquês",
            "Dari": "Dari",
            "Dene": "Dene",
            "Dinka": "Dinka",
            "Dutch": "Holandês",
            "Dutch_Belgium": "Holandês (Bélgica)",
            "Dyula": "Dyula",
            "Dzongkha": "Dzongkha",
            "Eastern_Mari": "Mari Oriental",
            "Edo": "Edo",
            "English_Australia": "Inglês (Austrália)",
            "English_Belize": "Inglês (Belize)",
            "English_Canada": "Inglês (Canadá)",
            "English_Ireland": "Inglês (Irlanda)",
            "English_Jamaica": "Inglês (Jamaica)",
            "English_Liberia": "Inglês (Libéria)",
            "English_New_Zealand": "Inglês (Nova Zelândia)",
            "English_Trinidad_Tobago": "Inglês (Trinidad & Tobago)",
            "English_UK": "Inglês (Reino Unido)",
            "English_US": "Inglês (EUA)",
            "English_Zimbabwe": "Inglês (Zimbábue)",
            "Estonian": "Estoniano",
            "Ewe": "Ewe",
            "Falam": "Falam",
            "Fante": "Fante",
            "Farsi": "Farsi",
            "Fijian": "Fijiano",
            "Filipino": "Filipino",
            "Finnish": "Finlandês",
            "Flemish": "Flamengo",
            "Formosan": "Formosano",
            "French_Belgium": "Francês (Bélgica)",
            "French_Burkina_Faso": "Francês (Burquina Faso)",
            "French_Burundi": "Francês (Burundi)",
            "French_Cameroon": "Francês (Camarões)",
            "French_Canada": "Francês (Canadá)",
            "French_Congo": "Francês (Congo)",
            "French_France": "Francês (França)",
            "French_Guinea": "Francês (Guiné)",
            "French_Ivory_Coast": "Francês (Costa do Marfim)",
            "French_Madagascar": "Francês (Madagascar)",
            "French_Morocco": "Francês (Marrocos)",
            "French_Switzerland": "Francês (Suíça)",
            "French_Tunisia": "Francês (Tunísia)",
            "French_West_Africa": "África Ocidental Francesa",
            "Frisian": "Frísio",
            "Fukienese": "Fukienese",
            "Fula": "Fula",
            "Fulani": "Fulani",
            "Fulfulde": "Fulfulde",
            "Fuzhou": "Fuzhou",
            "Ga": "Ga",
            "Gaddang": "Gaddang",
            "Galician": "Galego",
            "Garifuna": "Garifuna",
            "Georgian": "Georgiano",
            "German": "Alemão",
            "German_Austria": "Alemão (Áustria)",
            "German_Belgium": "Alemão (Bélgica)",
            "German_Liechtenstein": "Alemão (Liechtenstein)",
            "German_Pennsylvania": "Alemão (Pensilvânia)",
            "German_Switzerland": "Alemão (Suíça)",
            "Greek": "Grego",
            "Guarani": "Guarani",
            "Gujarati": "Gujarati",
            "Guyanese": "Guyanês",
            "Haitian_Creole": "Crioulo Haitiano",
            "Hakka": "Hakka",
            "Hakka_China": "Hakka (Cantão)",
            "Hakka_Taiwan": "Hakka (Taiwan)",
            "Harari": "Harari",
            "Haryanvi": "Haryanvi",
            "Hausa": "Hausa",
            "Hawaiian": "Havaiano",
            "Hebrew": "Hebraico",
            "Hindi": "Hindi",
            "Hmong": "Hmong",
            "Hokkien": "Hokkien",
            "Hopi": "Hopi",
            "Hunanese": "Hunanês",
            "Hungarian": "Húngaro",
            "Ibanag": "Ibanag",
            "Ibibio": "Ibibio",
            "Ibo": "Ibo",
            "Icelandic": "Islandês",
            "Igbo": "Igbo",
            "Ilocano": "Ilocano",
            "Ilonggo": "Ilonggo",
            "Indonesian_Bahasa_Indonesia": "Indonésio (Bahasa Indonesia)",
            "Inuktitut": "Inuktitut",
            "Inupiak": "Inupiak",
            "Iraqi": "Iraquiano",
            "Irish_Gaelic": "Gaélico Irlandês",
            "Italian": "Italiano",
            "Italian_Switzerland": "Italiano (Suíça)",
            "Ixil": "Ixil",
            "Jakartanese": "Jakartense",
            "Jamaican_Patois": "Jamaicano Patois",
            "Japanese": "Japonês",
            "Jarai": "Jarai",
            "Javanese": "Javanês",
            "Jju": "Jju",
            "Kachin": "Kachin",
            "Kanjobal": "Kanjobal",
            "Kannada": "Canarês",
            "Kaqchikel": "Kaqchikel",
            "Karen": "Karen",
            "Karenni": "Karenni",
            "Karenni_Kayah_Li": "Karenni (Kayah Li)",
            "Karenni_Latin": "Karenni (Latim)",
            "Karenni_Myanmar": "Karenni (Mianmar)",
            "Kashmiri": "Caxemira",
            "Kayah": "Kayah",
            "Kayah_Latin": "Kayah (Latim)",
            "Kayah_Myanmar": "Kayah (Mianmar)",
            "Kazakh": "Cazaque",
            "Kekchi": "Kekchi",
            "Khmer": "Khmer",
            "Kibembe": "Kibembe",
            "Kifulero": "Kifulero",
            "Kikuyu": "Quicuio",
            "Kinyamulenge": "Kinyamulenge",
            "Kinyarwanda": "Quiniaruanda",
            "Kirundi": "Kirundi",
            "Kiswahili": "Kiswahili",
            "Kituba": "Kituba",
            "Kizigua": "Kizigua",
            "Konkani": "Concani",
            "Korean": "Coreano",
            "Kosovan": "Kosovar",
            "Kosraean": "Kosraenho",
            "Kpelle": "Kpelle",
            "Krahn": "Krahn",
            "Krio": "Krio",
            "Kunama": "Kunama",
            "Kurdish": "Curdo",
            "Kurdish_Arabic": "Curdo (Árabe)",
            "Kurdish_Cyrillic": "Curdo (Cirílico)",
            "Kurdish_Latin": "Curdo (Latim)",
            "Kurmanji": "Kurmanji",
            "Kwakwala": "Kwakwala",
            "Kwanyama": "Kwanyama",
            "Kyrgyz": "Quirguiz",
            "Lai_Hakha": "Lai Hakha",
            "Lakota": "Lakota",
            "Lao": "Laosiano",
            "Latin": "Latim",
            "Latvian": "Letão",
            "Lingala": "Lingala",
            "Lithuanian": "Lituano",
            "Luganda": "Luganda",
            "Luo": "Luo",
            "Luxembourgish": "Luxemburguês",
            "Maay_Maay": "Maay Maay",
            "Macedonian": "Macedônio",
            "Madi_Uganda": "Madi (Uganda)",
            "Malagasy": "Malgaxe",
            "Malay": "Malaio",
            "Malay_Jawi": "Malaio (Jawi)",
            "Malayalam": "Malaiala",
            "Maltese": "Maltês",
            "Mam": "Mam",
            "Mandarin": "Mandarim",
            "Mandingo": "Mandingo",
            "Mandinka": "Mandinka",
            "Mandinka_Arabic": "Mandinka (Árabe)",
            "Mandinka_Latin": "Mandinka (Latim)",
            "Manipuri": "Manipuri",
            "Mankon": "Mankon",
            "Maori": "Maori",
            "Marathi": "Marata",
            "Marshallese": "Marshallês",
            "Mauritian_Creole": "Crioulo Maurício",
            "Memoni": "Memoni",
            "Mende": "Mende",
            "Mi_kmaq": "Mi'kmaq",
            "Michif": "Michif",
            "Mien": "Mien",
            "Mina": "Mina",
            "Mixteco": "Mixteco",
            "Mnong": "Mnong",
            "Mon": "Mon",
            "Mongolian": "Mongol",
            "Mongolian_Cyrillic": "Mongol Cirílico",
            "Montenegrin": "Montenegrino",
            "Montenegrin_Cyrillic": "Montenegrino (Cirílico)",
            "Montenegrin_Latin": "Montenegrino (Latim)",
            "Mossi": "Mossi",
            "Nahuatl": "Náuatle",
            "Navajo": "Navajo",
            "Nepali": "Nepalês",
            "Nigerian_Pidgin_English": "Pidgin English Nigeriano",
            "Nordic": "Nórdico",
            "Norwegian": "Norueguês",
            "Nuer": "Nuer",
            "Ojibway": "Ojibway",
            "Oriya": "Oriá",
            "Oromo": "Oromo",
            "Palauan": "Palauano",
            "Pampangan": "Pampangan",
            "Papiamento": "Papiamento",
            "Pashai": "Pashai",
            "Pashto": "Pachto",
            "Patois": "Patois",
            "Persian": "Persa",
            "Pidgin_English": "Pidgin English",
            "Pima": "Pima",
            "Pinyin": "Pinyin",
            "Pohnpeian": "Pohnpeiano",
            "Polish": "Polonês",
            "Portuguese_Brazil": "Português (Brasil)",
            "Portuguese_Guinea_Bissau": "Português (Guiné-Bissau)",
            "Portuguese_Portugal": "Português (Portugal)",
            "Punjabi": "Punjabi",
            "Pushtu": "Pushtu",
            "Quechua": "Quechua",
            "Queens_English_UK": "Inglês da Rainha (Reino Unido)",
            "Quiche": "Quiché",
            "Rade": "Rade",
            "Rohingya_Arabic": "Rohingya (Árabe)",
            "Rohingya_Latin": "Rohingya (Latim)",
            "Romani": "Romani",
            "Romanian": "Romeno",
            "Romansh": "Romanche",
            "Russian": "Russo",
            "Saint_Lucian_Creole": "Crioulo de Santa Lúcia",
            "Samoan": "Samoano",
            "Sango": "Sango",
            "Sanskrit": "Sânscrito",
            "Saraiki": "Saraiki",
            "Scottish_Gaelic": "Gaélico Escocês",
            "Serbian": "Sérvio",
            "Serbian_Cyrillic": "Sérvio (Cirílico)",
            "Serbian_Latin": "Sérvio (Latim)",
            "Sesotho": "Sesotho",
            "Setswana": "Setswana",
            "Shanghainese": "Shanghainês",
            "Shona": "Shona",
            "Shuswap": "Shuswap",
            "Siberian_Yup_ik": "Iúpiques Siberianos",
            "Sicilian": "Siciliano",
            "Sidaama": "Sidaama",
            "Sindhi": "Sindi",
            "Sinhala": "Cingalês",
            "Siswati": "Siswati",
            "Slovak": "Eslovaco",
            "Slovenian": "Esloveno",
            "Somali": "Somali",
            "Soninke": "Soninquê",
            "Sorani": "Sorani",
            "Spanish_Argentina": "Espanhol (Argentina)",
            "Spanish_Chile": "Espanhol (Chile)",
            "Spanish_Colombia": "Espanhol (Colômbia)",
            "Spanish_Ecuador": "Espanhol (Equador)",
            "Spanish_International": "Espanhol (Internacional)",
            "Spanish_Latin_America": "Espanhol (América Latina)",
            "Spanish_Mexico": "Espanhol (México)",
            "Spanish_Panama": "Espanhol (Panamá)",
            "Spanish_Puerto_Rico": "Espanhol (Porto Rico)",
            "Spanish_Spain": "Espanhol (Espanha)",
            "Spanish_US": "Espanhol (EUA)",
            "Spanish_Venezuela": "Espanhol (Venezuela)",
            "Stoney_Canada": "Stoney (Canadá)",
            "Sudanese": "Sudanês",
            "Sundanese": "Sundanês",
            "Swahili": "Suaíli",
            "Swedish": "Sueco",
            "Swedish_Finland": "Sueco (Finlândia)",
            "Sylheti": "Sylheti",
            "Syriac": "Siríaco",
            "Szechuan": "Szechuan",
            "Tagalog": "Tagalo",
            "Tahitian": "Taitiano",
            "Taiwanese": "Taiwanês",
            "Tajik": "Tadjique",
            "Tamil": "Tâmil",
            "Tatar": "Tártaro",
            "Tedim_Chin": "Tedim Chin",
            "Telugu": "Telugo",
            "Temne": "Temne",
            "Tetum": "Tétum",
            "Thai": "Tailandês",
            "Tibetan": "Tibetano",
            "Tigre": "Tigre",
            "Tigrinya": "Tigrínia",
            "Tlingit": "Tlingit",
            "Toishanese": "Toishanês",
            "Tongan": "Tonganês",
            "Trique": "Trique",
            "Tsuut_ina": "Tsuut'ina",
            "Turkish": "Turco",
            "Turkmen": "Turcomeno",
            "Twi": "Twi",
            "Uighur": "Uigur",
            "Ukrainian": "Ucraniano",
            "Ulster_Scots": "Escocês da Ulster",
            "Urdu": "Urdu",
            "Uzbek": "Usbeque",
            "Uzbek_Cyrillic": "Usbeque (cirílico)",
            "Uzbek_Latin": "Usbeque (latino)",
            "Vietnamese": "Vietnamita",
            "Visayan": "Visayan",
            "Welsh": "Galês",
            "Wolof": "Wolofe",
            "Xhosa": "Xhosa",
            "Yapese": "Yapese",
            "Yaqui": "Yaqui",
            "Yavapai": "Yavapai",
            "Yiddish_other": "Iídiche (outro)",
            "Yiddish_US": "Iídiche (EUA)",
            "Yoruba": "Ioruba",
            "Yucateco": "Iucateco",
            "Yupik": "Yupik",
            "Zarma": "Zarma",
            "Zou": "Zou",
            "Zulu": "Zulu"
        },
        "third_info_one": "Poderão estar disponíveis outras línguas e dialectos. Se tiver alguma pergunta sobre a disponibilidade de línguas",
        "third_info_two": "contacte-nos em"
    },
    "document_clarification": {
        "tit": "CLARIFICAÇÃO DE DOCUMENTOS",
        "main": "Acesso ao idioma em todos os pontos de contato com o EMLions Clarity",
        "schedule_btn": "AGENDAR CONSULTA",
        "sec_one": "A EMLions é a maior empresa de acesso a idiomas do mundo. Para além de oferecer soluções de tradução em mais de 290 idiomas, a nossa equipa Clarity também garante que as comunicações são, antes de mais, simples, claras e compreensíveis em \"inglês claro\".",
        "sec_two": "Orgulhamo-nos de colmatar as lacunas na comunicação em todos os cantos do mundo. No entanto, queremos ter em conta que os falantes de inglês também precisam de um melhor acesso à informação que consomem em inglês. Tendo em conta que a pessoa média nos EUA tem as capacidades de leitura de um aluno do 7º ano, a utilização do inglês simples é imperativa para uma comunicação clara, mais acessível e concisa. É por isso que consideramos o \"inglês claro\" como uma linguagem valiosa em si mesma.",
        "third_tit": "CLAREZA DE EMLIONS",
        "third_main": "Comunicação clara. Comunicação simples.",
        "third_main_one": "Os documentos demasiado longos, mal organizados e repletos de jargão e de linguagem interna são difíceis ou impossíveis de compreender em qualquer língua, por mais bem traduzidos que sejam.",
        "third_main_two": "Por outro lado, quando os documentos digitais e impressos são expurgados de conteúdos não essenciais e escritos e concebidos de forma a serem claros para o público a que se destinam, serão fáceis de compreender em todas as línguas. O serviço Clarity é uma oportunidade para aumentar a ligação e a confiança entre si e os seus clientes.",
        "third_main_three": "Os serviços do EMLions Clarity são totalmente diferentes de outros serviços de redação em linguagem clara. Em vez de tratar de um punhado de documentos individuais num vácuo, o EMLions® faz a reengenharia de sistemas de documentos inteiros e ajuda-o a produzir conteúdos mais simples, transparentes e económicos."
    },
    "document_simplification": {
        "tit": "SIMPLIFICAÇÃO DE DOCUMENTOS",
        "main": "Alcance o seu público com linguagem simples e total clareza",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "O que é a simplificação de documentos?",
        "sec_main": "Os clientes, pacientes e constituintes apreciam e respondem a comunicações simples e directas. Quando compreendem o que está a dizer da primeira vez, é mais provável que voltem para mais. É isto que os nossos serviços de simplificação de documentos podem ajudar a conseguir. Oferecemos a Macrosimplificação e a Microsimplificação para melhorar a compreensão e reduzir os gastos com tradução.",
        "macro": "MACROSIMPLIFICAÇÃO",
        "third_one_tit": "Simplificar todo um sistema de documentos",
        "third_one_main": "Reengenharia completa do seu sistema de documentos para obter um conteúdo mais simples e eficaz que aumenta a satisfação ao melhorar a legibilidade e a compreensão em todos os pontos de contacto. O refinamento do seu sistema de documentos também pode resultar em economia de custos e melhor desempenho.",
        "third_two_tit": "Lidar com centenas de documentos de uma só vez",
        "third_two_main_one": "Simplifique os sistemas de documentos digitais e de impressão de grandes dimensões",
        "third_two_main_two": "Renovar conteúdos complexos para maior clareza e facilidade de utilização",
        "third_two_main_three": "Dimensionar corretamente as bibliotecas de documentos",
        "third_two_main_four": "Impulsionar os processos de comunicação e a eficácia",
        "third_three_tit": "Poupe dinheiro e cumpra as normas de conformidade",
        "third_three_main": "Os projectos de macrossimplificação podem pagar-se a si próprios com o aumento da qualidade e a redução dos custos de produção e impressão. Também melhoram a experiência do cliente, do paciente e do constituinte, aumentam a satisfação e ajudam a garantir o cumprimento dos requisitos de conformidade.",
        "third_four_tit": "Como saber se precisa",
        "third_four_main": "Fale com um dos nossos representantes sobre a Macrosimplificação se a sua organização tiver problemas com:",
        "third_four_main_one": "Informações desatualizadas e inconsistentes",
        "third_four_main_two": "Conteúdo redundante e excessivo",
        "third_four_main_three": "Dificuldade em atingir públicos específicos",
        "third_four_main_four": "Comunicações confusas que conduzem a chamadas dos clientes",
        "third_four_main_five": "Falta de governação eficaz e de controlo de qualidade",
        "micro": "MICROSIMPLIFICAÇÃO",
        "four_one_tit": "Simplificar documentos e comunicações individuais",
        "four_one_main": "A microsimplificação é inestimável para qualquer organização. O processo inclui:",
        "four_one_main_one": "Análise de conteúdo e recomendações de melhoria",
        "four_one_main_two": "Remoção de informação desnecessária e excessiva",
        "four_one_main_three": "Utilização de inglês claro para informações essenciais",
        "four_one_main_four": "Criação de um novo texto que seja fácil de ler e apelativo",
        "four_two_tit": "Proporcionar uma melhor experiência ao cliente",
        "four_two_main": "A microssimplificação dos materiais de marketing pode aumentar drasticamente a sua eficácia e ajudar a garantir o cumprimento das normas de conformidade da sua indústria. Materiais simples e claros têm mais hipóteses de converter potenciais clientes em clientes e de criar lealdade a longo prazo.",
        "four_three_tit": "Melhorar os resultados e reduzir os custos",
        "four_three_main": "A microsimplificação é especialmente útil para organizações que oferecem serviços complexos, como seguros, serviços financeiros ou cuidados médicos. Um formulário simplificado, uma fatura ou uma brochura bem visível podem ter um impacto enorme nos seus resultados e custos.",
        "five_tit": "Vamos falar de soluções",
        "five_main": "Estamos aqui com assistência linguística especializada concebida para satisfazer as suas necessidades e orçamento. Solicite um orçamento agora ou fale com um dos nossos representantes para analisar os seus desafios de acesso linguístico e discutir soluções.",
        "schedule_btn": "AGENDAR CONSULTA"
    },
    "audio_transcription": {
        "tit": "TRANSCRIÇÃO DE ÁUDIO",
        "main": "Transforme as suas gravações de áudio em activos valiosos",
        "schedule_btn": "AGENDAR CONSULTA",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "Utilize a transcrição de áudio para impulsionar o seu negócio",
        "sec_one": "As organizações actuais recolhem grandes quantidades de informação, não só em comunicações impressas e digitais, mas também nas suas gravações de áudio e vídeo. Estes activos contêm informações valiosas que podem melhorar o serviço ao cliente, a formação do pessoal e o alcance do marketing.",
        "sec_two": "Podemos eliminar o trabalho de transcrição das suas gravações de áudio e vídeo. Fornecemos transcrição de áudio para texto, locução e tradução em mais de 290 idiomas. Pode ter a certeza de que os nossos mais de 3.500 linguistas especializados concluirão os seus projectos de transcrição com resultados de alta qualidade, revisão minuciosa e entrega atempada.",
        "transcription": "TRANSCRIÇÃO",
        "third_one_tit": "Melhorar o desempenho e a produtividade",
        "third_one_main": "Muitas empresas gravam as chamadas de serviço ao cliente para controlo de qualidade, mas ouvi-las pode consumir muito tempo e prejudicar a produtividade. As suas gravações podem dar-lhe novas perspectivas sobre a forma como serve bem os seus clientes, pacientes e constituintes. Além disso, a transcrição pode dar aos gestores do seu centro de atendimento uma forma mais rápida de analisar o desempenho do pessoal e intervir prontamente com formação.",
        "third_two_tit": "Aumentar a acessibilidade para todos",
        "third_two_main": "A transcrição de gravações de áudio pode tornar o seu conteúdo mais acessível a clientes e funcionários surdos ou com dificuldades auditivas. Se a sua organização produz áudio para fins de marketing, apoio ao cliente ou formação de funcionários, transformar essas gravações em conteúdo impresso ou digital torná-las-á acessíveis a todos os que serve.",
        "third_three_tit": "Chegar a um público global",
        "third_three_main": "As audiências de todo o mundo estão ávidas de conteúdos eficazes. Por isso, não se limite a transformar as suas gravações em comunicações impressas e digitais. Vá mais longe, recorrendo aos nossos mais de 3.500 linguistas especializados, que podem traduzir uma única transcrição áudio para mais de 290 línguas - multiplicando radicalmente o impacto de cada gravação que efectuou.",
        "captioning": "CAPTAÇÃO",
        "four_one_tit": "Satisfazer a crescente procura de vídeo",
        "four_one_main": "Atualmente, o conteúdo de vídeo representa mais de 65% de todo o tráfego da Internet e muitos espectadores preferem vídeos com legendas. Alguns são surdos ou deficientes auditivos (DHH), enquanto outros gostam de ver vídeos em público sem som. Quando adiciona legendas ao seu conteúdo de vídeo, pode satisfazer as necessidades de milhões de pessoas.",
        "four_two_tit": "Servir melhor as diversas populações",
        "four_two_main": "A legendagem de conteúdos de vídeo ajuda-o a",
        "four_two_main_one": "Aumentar a acessibilidade para populações DHH ou com proficiência limitada em inglês",
        "four_two_main_two": "Aumentar o envolvimento, melhorando as hipóteses de os espectadores verem o seu conteúdo até ao fim",
        "four_two_main_three": "Atrair novos públicos e demografias em crescimento",
        "four_three_tit": "Conte com os nossos linguistas especializados",
        "four_three_main": "Os nossos profissionais são especialistas em legendagem. Ajudámos milhares de empresas, organizações de cuidados de saúde, agências governamentais e muito mais a expandir o seu alcance, ao mesmo tempo que nos tornámos mais inclusivos e acessíveis para as populações que servem.",
        "five_tit": "Vamos falar de soluções",
        "five_main": "Estamos prontos para fornecer serviços especializados de transcrição de áudio que satisfaçam as suas necessidades e o seu orçamento. Pode ler a nossa brochura de Tradução e Localização ou escolher uma altura para falar com os nossos especialistas sobre como resolver os seus desafios de acesso linguístico.",
        "five_download_btn": "BAIXAR AGORA"
    },
    "alternative_format": {
        "tit": "TRANSCRIÇÃO DE FORMATOS ALTERNATIVOS",
        "main": "Construa relacionamentos com populações com deficiência visual",
        "schedule_btn": "AGENDAR CONSULTA",
        "quote_btn": "SOLICITAR ORÇAMENTO",
        "sec_tit": "Garanta a igualdade de acesso aos seus produtos e serviços",
        "sec_one": "Mais de 32 milhões de adultos nos EUA relatam ter sofrido perda significativa da visão ou cegueira. Eles podem ser seus clientes, pacientes, constituintes ou funcionários. Quando satisfaz as suas necessidades apresentando materiais escritos em Braille, letras grandes ou outros formatos alternativos, garante a igualdade de acesso e permite melhores resultados.",
        "sec_two": "Podemos transcrever o seu conteúdo para obter o máximo impacto junto de pessoas cegas e com deficiência visual. A nossa tecnologia avançada permite-nos concluir os seus projectos de forma rápida e económica, mantendo os mais elevados níveis de qualidade.",
        "third_tit": "BRAILLE E LETRAS GRANDES",
        "third_main": "Satisfazer as necessidades das populações com deficiência visual",
        "third_main_exp": "Podemos ajudá-lo a fornecer materiais escritos em Braille e edições em letras grandes numa vasta gama de línguas. Além disso, a impressão em caracteres grandes pode melhorar os seus materiais digitais e o seu sítio Web. Conte com uma produção de alta qualidade apoiada pela nossa equipa de editores profissionais.",
        "four_tit": "TRANSCRIÇÃO DE ÁUDIO",
        "four_main": "Transcrever áudio para melhorar os seus serviços e negócios",
        "four_main_exp": "As suas gravações de áudio contêm provavelmente informações essenciais que podem melhorar o seu serviço ao cliente e a formação do pessoal. Além disso, funcionam como base para novos materiais de marketing. Nós eliminamos o trabalho de transcrever gravações com transcrição de áudio para texto, locução e tradução em mais de 290 idiomas.",
        "four_btn": "SAIBA MAIS",
        "five_tit": "Soluções Let's Talk",
        "five_main": "Prestamos serviços de transcrição de formatos alternativos de alta qualidade, adaptados ao seu projeto e orçamento. Fale com um dos nossos especialistas para resolver os seus desafios de acesso linguístico e garantir a acessibilidade para todos.",
        "six_tit": "Melhore a acessibilidade com tecnologia de tradução avançada",
        "six_one_tit": "TRANSCRIÇÃO DE ÁUDIO",
        "six_one_contents": "Sirva melhor as pessoas cegas e com deficiência visual com versões áudio do seu conteúdo escrito e informações.",
        "six_two_tit": "DAISY",
        "six_two_contents": "Aumente a usabilidade e acessibilidade do seu conteúdo com uma exportação em formato de sistema de informação digital acessível (DAISY).",
        "six_three_tit": "MOON",
        "six_three_contents": "Transcreva o seu conteúdo para Moon para o tornar mais utilizável e impactante para audiências com deficiência visual.",
        "six_four_tit": "E-TEXTO",
        "six_four_contents": "Converta o seu conteúdo em E-Text para permitir que o software o amplie, leia em voz alta e o reproduza num ecrã Braille atualizável.",
        "six_five_tit": "FÁCIL LEITURA",
        "six_five_contents": "Combine texto e imagens para fornecer conteúdos mais consumíveis para pessoas com dificuldades de aprendizagem."
    },
    "translate": {
        "tit": "SERVIÇOS DE TRADUÇÃO",
        "main": "Construa relações mais fortes através da compreensão total",
        "main_exp": "Obtenha a experiência de que necessita para comunicar claramente em qualquer escala.",
        "schedule_btn": "AGENDAR CONSULTA",
        "sec_tit": "Aumente a qualidade da tradução em mais de 290 idiomas",
        "sec_one": "Mais de 67 milhões de residentes nos EUA falam um idioma diferente do inglês em casa. Conversar com indivíduos com proficiência limitada em inglês (LEP) no idioma de sua escolha não é apenas uma boa prática, é a chave para o crescimento. Os estudos mostram que 92% das pessoas compram produtos vendidos na sua língua preferida e 91% fazem compras repetidas.",
        "sec_two": "A maioria dos fornecedores de tradução é limitada em termos de pessoal, especialização e qualidade, o que o obriga a procurar outro fornecedor para determinados projectos. Em vez de trabalhar com uma rede de serviços de nicho, associe-se a um único fornecedor com mais de 25 anos de experiência, mais de 3500 linguistas especializados e padrões de qualidade comprovados, apoiados por várias certificações ISO.",
        "sec_three": "Os especialistas em tradução e localização da EMLions® apoiam todos os tipos de conteúdo e tamanhos de projectos em mais de 290 idiomas. A nossa tecnologia inovadora cria eficiências no fluxo de trabalho, facilitando ao mesmo tempo o serviço mais rápido possível. A EMLions está pronta para o ajudar:",
        "sec_four": "Traduzir documentos, materiais de marketing, formulários, comunicações e guias de instruções",
        "sec_five": "Localizar websites, hardware, software, conteúdo de jogos e eLearning",
        "sec_six": "Automatizar a tradução e a localização do seu conteúdo",
        "third_one": "Linguistas profissionais à espera de servir a sua organização",
        "third_two": "Taxa de precisão da tradução de conteúdo da EMLions",
        "third_three": "Taxa de entrega pontual da EMLions para projectos de tradução",
        "four_one_tit": "Tradução de documentos",
        "four_one_contents": "Obtenha traduções de documentos de alta qualidade personalizadas para o uso pretendido, o público e o prazo de validade.",
        "four_two_tit": "Serviços de localização",
        "four_two_contents": "Localize conteúdos impressos e digitais de acordo com o idioma e as sensibilidades culturais de um local específico para que seja uma experiência natural para o seu público-alvo.",
        "four_three_tit": "Ferramentas de tradução integradas",
        "four_three_contents": "Obtenha resultados mais rápidos com a nossa tecnologia inovadora que elimina as barreiras à tradução e reduz os erros humanos.",
        "four_four_tit": "Tradução automática",
        "four_four_contents": "A tecnologia de \"melhor ajuste\" orientada por IA da EMLions fornece retornos rápidos para conteúdo em grande escala e de baixa responsabilidade, com o menor custo.",
        "four_five_tit": "Serviços de eLearning",
        "four_five_contents": "Adapte o seu conteúdo de eLearning a diferentes idiomas para aumentar a produtividade organizacional.",
        "four_six_tit": "Tradução de sites",
        "four_six_contents": "O Website Proxy fornece uma forma prática de produzir e gerir versões traduzidas do seu website em inglês, ou manter a versão em inglês e as versões traduzidas sempre sincronizadas.",
        "four_sevent_tit": "Idiomas de tradução",
        "four_sevent_contents": "Saiba mais sobre a experiência e a qualidade que nossos tradutores oferecem em mais de 290 idiomas.",
        "five_tit": "Vamos falar de soluções",
        "five_main": "Os nossos linguistas profissionais estão ansiosos por fornecer serviços de tradução e localização que se ajustem ao seu orçamento. Solicite agora um orçamento para o seu próximo projeto ou fale com um dos nossos especialistas em soluções de tradução da EMLions.",
        "quote_btn": "PEDIR ORÇAMENTO"
    },
    "other_translate": {
        "tit": "OUTROS SERVIÇOS LINGUÍSTICOS",
        "main": "Faça com que cada palavra conte",
        "main_exp": "Ajude a sua organização a comunicar de forma clara e concisa para maximizar o impacto de todos os seus documentos e conteúdos.",
        "quote_btn": "PEDIR ORÇAMENTO",
        "sec_tit": "Comunique-se de forma eficaz com linguagem clara e muito mais",
        "sec_one": "O adulto americano médio lê ao nível do sétimo ano. A maioria dos leitores perde-se quando se depara com conteúdos complexos, terminologia desconhecida e jargão da indústria. Se a sua organização utiliza uma linguagem demasiado complicada, corre o risco de criar uma má experiência para o cliente, causar confusão e aumentar os custos.",
        "sec_two": "Como principal parceiro de acesso a idiomas do mundo, oferecemos soluções de tradução em mais de 290 idiomas. Atualmente, o padrão de ouro para uma comunicação eficaz é o inglês claro, que consideramos uma língua em si. Podemos ajudá-lo a simplificar os seus documentos, para que a sua mensagem seja transmitida e atraia todos os seus públicos.",
        "third_one_tit": "Clarificação de documentos",
        "third_one_contents": "Reescrevemos os materiais destinados ao público para que sejam mais fáceis de compreender e menos dispendiosos de traduzir para outras línguas.",
        "third_two_tit": "Simplificação de documentos",
        "third_two_contents": "Identificamos o conteúdo principal que é útil para todos os públicos e simplificamo-lo para um maior impacto.",
        "third_three_tit": "Transcrição de áudio",
        "third_three_contents": "Podemos transcrever os seus vídeos e traduzi-los para a língua da sua escolha. Além disso, procuramos e gravamos artistas de locução.",
        "third_four_tit": "Transcrição de formatos alternativos",
        "third_four_contents": "Transcrevemos conteúdos para Braille, incluindo inglês e espanhol.",
        "four_tit": "Soluções Let's Talk",
        "four_main": "Estamos aqui com assistência linguística especializada concebida para satisfazer as suas necessidades e orçamento. Solicite um orçamento agora ou fale com um dos nossos representantes para analisar os seus desafios de acesso linguístico e discutir soluções.",
        "schedule_btn": "AGENDAR CONSULTA"
    },
    "footer": {
        "interpretation": "Tradução oral",
        "testing_and_training": "Testes & formação",
        "industries": "Indústrias",
        "careers": "Carreiras",
        "resources": "Recursos",
        "customer_service": "Atendimento ao cliente",
        "contact_info": "Contacte-nos em",
        "corporate_info": "© EMLions Solutions 2024. Todos os direitos reservados.",
        "sitemap": "Mapa do site",
        "privacy_policy": "Política de privacidade",
        "cookie_policy": "Política de cookies",
        "global_ethics_hotline": "Linha direta de ética global"
    }
}