<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import DevicePixelRatio from './lib/rem';
export default {
  name: 'App',
  // created(){
  //   new DevicePixelRatio().init()
  // },
}
</script>
