export default{
    "lang": "中文",
    "home": {
        "main_explain": "通过数字技术引领世界，通过行业针对性的服务创造价值通过领先的理念和灵活的执行，为我们的每个客户加速数字化转型。",
        "contact_btn": "取得联系",
        "read_me_btn": "了解更多",
        "second_tit": "赋能数字时代 共同创造价值",
        "second_main": "想象一个不再存在语言和文化障碍的世界--在这个世界里，理解是普遍的，每个人都有能力。",
        "second_expand": "我们将帮助您在社区成员需要的时候为他们提供服务，无论他们的语言、文化或能力如何。",
        "second_learn_more": "联系我们了解更多信息",
        "second_main_tips_one": "本地化和翻译内容",
        "second_main_tips_two": "管理翻译项目",
        "second_main_tips_three": "提高翻译质量",
        "second_main_tips_four": "提高团队工作效率",
        "second_main_tips_five": "人工智能助力体验",
        "second_main_tips_six": "应用程序和软件的本地化",
        "third_tit": "借助我们的翻译服务在全球市场上大显身手",
        "third_main": "我们帮助客户与受众建立联系，提供最佳的客户体验（任何语言），从而进入全球新市场。通过我们的服务、技术、咨询和行业专业知识，我们是您从规划到执行的合作伙伴。",
        "third_btn": "联系我们了解更多信息",
        "four_tit": "我们如何帮助您取得成功？",
        "four_main": "我们量身定制的解决 方案可帮助您快速、高效、有效地进入这些新市场，并为您的企业带来最大收益。",
        "four_main_one_tit": "本地化和翻译内容",
        "four_main_one_content": "在我们智能技术的帮助下，扩展您的全球内容并最大限度地扩大其本地影响。",
        "four_main_two_tit": "管理翻译项目",
        "four_main_two_content": "借助翻译管理平台，轻松实现本地化流程的扩展、简化和自动化。",
        "four_main_three_tit": "提高翻译质量",
        "four_main_three_content": "通过语言检查保护品牌声誉，确保内容清晰无误。",
        "four_main_four_tit": "本地化应用程序和软件",
        "four_main_four_content": "通过我们全套的本地化服务，确保产品在每个市场都能完美适应和完美呈现。",
        "four_main_five_tit": "提高团队工作效率",
        "four_main_five_content": "使用最值得信赖的计算机辅助翻译 (CAT) 软件，为译员和项目经理提供更智能的工作工具。",
        "four_main_six_tit": "人工智能助力体验",
        "four_main_six_content": "利用人工智能，以极小的成本和足够快的速度回答客户的问题，并将其转化为相关的及时行动。",
        "five_tit": "立即了解企业服务",
        "five_main": "让我们一起实现您最宏伟的目标",
        "six_tit": "谁正在使用 EMLIONS？",
        "six_main": "得到客户、翻译人员和行业组织认可的全球规模的内容和数据转换解决方案。",
        "six_main_find": "了解更多信息"
    },
    "contact_btn": "联系我",
    "translation": {
        "nav": "翻译",
        "menu_doc": "文件翻译",
        "menu_localization": "本地化服务",
        "menu_tools": "综合翻译工具",
        "menu_machine": "机器翻译",
        "menu_elearning": "在线学习服务",
        "menu_website": "网站翻译",
        "menu_languages": "翻译语种"
    },
    "other_language_services": {
        "nav": "其他翻译服务",
        "menu_clarification": "文档澄清说明",
        "menu_simplification": "文档摘要",
        "menu_audio": "音频转录",
        "menu_alternative": "替代格式转录"
    },
    "about": {
        "title_fir": "关于我们",
        "title_sed": "IT 服务提供商",
        "title_explain_fir": "157 家财富 500 强客户值得信赖的合作伙伴。",
        "title_explain_sec": "我们的优势在于我们的员工，我们不仅仅是一家翻译公司。我们是一个由一群全心投入的人组成的大家庭，致力于成为全球通信领域的领先推动者。",
        "title_num_one": "家财富 500 强客户",
        "title_num_two": "年的经验",
        "title_num_three": "名专业翻译",
        "second_title": "我们为您带来强大的优势，助力您实现数字化转型",
        "second_one_tit": "全球业务布局及运营服务能力",
        "second_one_main": "EMLIONS 在全球拥有 3,500 名专业人员，分布在 28 个城市的 50 个交付中心。我们致力于为全球客户提供 200 多种语言的产品、7x24  技术支持和服务交付。",
        "second_two_tit": "凭借丰富的行业经验加速创新",
        "second_two_main": "凭借25年的行业经验，Emlions为全球客户提供涵盖数字咨询、体验交互、 技术实施和运营的服务。 凭借在高科技、金融、制造、电信、互联网等领域的丰富经验，Emlions已与157家世界500强企业建立了长期合作关系。",
        "second_three_tit": "以可靠的安全和质量管理推动质量升级",
        "second_three_main": "Emlions 自 2000 年通过 ISO 9001 质量管理体系标准以来，又相继通过了 CMMI L5、ISO 20000 和 ISO 27001 认证。在设施、人员和程序的安全性和质量方面，我们始终以达到国际标准的质量控制系统满足并超越客户的期望。",
        "second_four_tit": "赋能数字时代，为客户创造价值",
        "second_four_main": "Emlions致力于通过创新以客户体验为导向的产品和服务以及整合数字营销来加速数字化能力的部署，并通过融合战略咨询、创意、体验设计等，通过 移动、物联网、云计算、大数据等技术手段，为客户提供卓越的数字化服务，助力客户数字化转型。"
    },
    "solutions": {
        "nav": "解决方案",
        "tit_fir": "行业",
        "tit_sec": "解决方案",
        "main_fir": "作为专业的沟通者，我们知道背景至关重要。因此，我们拥有在几乎所有行业领域都拥有丰富经验的专业团队。",
        "main_sec": "这种量身定制的专业知识让您放心，您的全球内容既有效又符合特定格式、术语和监管要求。",
        "get_in_touch": "取得联系",
        "read_more": "了解更多",
        "sec_tit": "我们如何帮助您取得成功？",
        "sec_main": "我们量身定制的解决方案可以帮助您快速、高效、有效地进入这些新市场，并最大程度地为您的业务带来收益。",
        "learn_more": "了解更多",
        "sec_one_tit": "生命科学",
        "sec_one_main": "我们为生命科学、医学研究和合同研究组织 (CRO) 提供专业的语言、COA 和内容解决方案，帮助您提高流程效率，降低翻译成本，加快进入市场的速度。",
        "sec_two_tit": "法律",
        "sec_two_main": "我们可以帮助您解决所有法律实践中的多语言内容挑战，以便您能够跟上快速增长的翻译工作量，无论您管理的是电子取证数据、并购 (M&A) 备案、知识产权文件、合同和协议、营销材料等等。",
        "sec_three_tit": "政府解决方案",
        "sec_three_main": "我们为公共部门、情报、国防和执法机构提 供专业的语言和内容管理解决方案，满足政府对多语言内容的要求。",
        "sec_four_tit": "金融服务",
        "sec_four_main": "我们的内容和语言解决方案支持金融服务组织的特定需求，因此您可以在全球范围内使用任何语言与客户建立最佳关系。",
        "sec_five_tit": "管理式医疗",
        "sec_five_main": "跨越语言和能力障碍自信地进行沟通。 我们的解决方 案和专业知识可帮助您全年满足翻译和合规需求，而不仅仅是在年度注册期 (AEP) 期间。",
        "sec_six_tit": "航空航天和国防",
        "sec_six_main": "利用我们的 S1000D 出版套件，满足商用飞机和国防设备的严格要求，并提高整个产品生命周期（包括交付后的维护）的生产力和效率。",
        "sec_sevent_tit": "工业制造",
        "sec_sevent_main": "我们全面的语言和内容解决方案可以帮助您通过采用成熟的敏捷软件实践来满足整个价值链的需求，同时降低成本并提高效率。",
        "sec_eight_tit": "高科技软件",
        "sec_eight_main": "我们为企业软件和硬件、IT 服务、社交媒体、游戏、在线应用、多媒体、生产力应用、云计算和移动设备提供专业的语言和内容解决方案，满足市场需求，提高产品速度。",
        "sec_nine_tit": "零售和电子商务",
        "sec_nine_main": "Emlions 语言服务和技术通过电子商务本地化战略，满足目标受众的需求，为零售商在全球取得成功奠定基础。",
        "sec_ten_tit": "旅游、休闲和酒店业",
        "sec_ten_main": "我们为供应商、分销商和零售商提供的端到端多语言内容解决方案将帮助您创建、翻译和交付大型库存内容数据库 ，同时降低成本、保持消费者满意度并遵守消费者数据保护法规。",
        "sec_elevent_tit": "汽车",
        "sec_elevent_main": "通过所有数字渠道，为潜在客户、客户、联网汽车和分布在全球的售后服务机构管理多语种营销、产品和服务内容。",
        "sec_twelve_tit": "化学全球化服务",
        "sec_twelve_main": "我们通过高度专业化的语言学家和技术专家，为化工行业客户提供笔译、口译和知识产权服务，这些语言学家和技术专家均具备行业专业知识，并了解国际标准和法规。",
        "sec_Thirteen_tit": "能源领域的多语言翻译",
        "sec_Thirteen_main": "能源行业企业面临着日益增长的全球需求和日益激烈的市场竞争中新参与者的挑战。全球 IP 支持和有效、一致的多语言沟通是关键。",
        "sec_fourteen_tit": "保险翻译服务",
        "sec_fourteen_main": "我们的专业知识保证信息能够产生效果。我们只聘用专业翻译和口译人员，其中许多人都是前从业人员、经纪人、承销商、风险管理者和特许保险学会会员。",
        "sec_fifteen_tit": "媒体本地化服务",
        "sec_fifteen_main": "在 Emlions，我们对全球内容、本地化和技术的综合方法可帮助您在数字世界中保持相关性。",
        "sec_sixteen_tit": "电信本地化服务",
        "sec_sixteen_main": "当今竞争激烈的电信行业不仅迫使企业扩大其产品规模和范围，而且还要扩大其全球影响力。"
    },
    "doc_translation": {
        "tit": "文件翻译",
        "main": "以 290 多种语言建立更牢固的客户关系",
        "quote_btn": "询价",
        "sec_tit": "让我们的专家解决您所有的翻译需求",
        "sec_one": "您的翻译项目不仅限于一种语言、渠道或时间表。获得所需的专业知识，以在任何规模下清晰地沟通，并将您的语言挑战转化为机遇——通过创造性的解决方案来提高效率并建立有 意义的关系。",
        "sec_two": "我们拥有 3,500 多名专业语言学家，可根据您的预期用途和受众提供定制的高质量、高触感翻译。他们还会对每个项目进行严格的翻译、文字编 辑和校对，以确保最终交付成果超出您的标准。",
        "trird_one": "专业语言学家",
        "third_two": "可翻译的语言",
        "third_three": "内容质量保证",
        "four_tit": "翻译 290 多种语言的任何类型内容",
        "four_one_tit": "文件翻译",
        "four_one_contents": "信件、表格、电子邮件、技术手册、在线内容、内容管理系统输出、营销资料以及人力资源政策和程序。",
        "four_two_tit": "网站",
        "four_two_contents": "HTML、ASP、脚本、基于 Java 的应用程序和数据库驱动的界面。",
        "four_three_tit": "多媒体",
        "four_three_contents": "Flash 工程、配音和具有影响的电影。",
        "four_four_tit": "培训教材",
        "four_four_contents": "教程、模板、电子学习应用程序和 ERP。",
        "four_five_tit": "软件",
        "four_five_contents": "图形用户界面、数据库内容、无线设备、网络应用程序和业务流程系统。",
        "four_six_tit": "帮助和内容管理系统",
        "four_six_contents": "WinHelp、HTMLHelp、WebHelp、JavaHelp、OracleHelp、FlashHelp、AppleHelp、HubSpot、WordPress、Drupal 和专有系统。",
        "five_tit": "跨行业翻译",
        "five_one_tit": "商业:",
        "five_one_contents": "产品信息、客户沟通和员工材料",
        "five_two_tit": "医疗健康:",
        "five_two_contents": "重要文件、出院说明、资格通知和患者表格",
        "five_three_tit": "金融服务:",
        "five_three_contents": "贷款文件、合同、抵押文件、财务申请、账户报表和信用报告",
        "five_five_tit": "保险:",
        "five_five_contents": "索赔表格、保单信息和事故报告",
        "five_six_tit": "政府:",
        "five_six_contents": "权利通知、同意书、投诉、要求回复的信件、申请和公众宣传",
        "six_tit": "EMLions 翻译的与众不同之处",
        "six_one_tit": "人工翻译",
        "six_one_contents": "我们采用人文关怀和文化理解，确保每一项工作都能传达您原始内容的真正含义。",
        "six_two_tit": "定制服务",
        "six_two_contents": "我们考虑您的需求、截止日期和工作流程，适应并定制每个项目。",
        "six_three_tit": "100% 质量保证",
        "six_three_contents": "每个项目都至少由两名专业语言学家进行翻译、校对和审校。",
        "six_four_tit": "准时交付",
        "six_four_contents": "我们的文件翻译准时交付率为99.5%。",
        "sevent_tit": "了解有关文件翻译服务的更多信息",
        "sevent_contents": "了解如何获得帮助，以任何语言提供一致、高质量的内容。您可以阅读我们的翻译和本地化手册，或向我们的专家索取报价。",
        "download_btn": "立即下载"
    },
    "local_services": {
        "tit": "本地化服务",
        "main": "通过 290 多种语言建立更牢固的关系",
        "schedule_btn": "安排咨询",
        "quote_btn": "询价",
        "sec_tit": "提高本地化质量并降低成本",
        "sec_one": "您的数字内容可以触及的人群是无限的，但大多数客户更愿意购买用他们的语言交流的产品。通过与具有专业知识和规模的合作伙伴合作，确保您在全球范围内始终如一地传递您的信息，满足您的所有内容需求和任何规模的项目。",
        "sec_two": "我们的翻译和本地化服务由 3,500 多名专业语言学家负责，随时准备以 290 多种语言提供一致、高质量的交付成果。我们将根据特定地区的语言和文化敏感性对您的印刷或数字内容进行本地化，让您的目标受众获得自然的体验。此外，为确保按时成功交付，我们对每个项目都采用全面的端到端质量控制程序。",
        "third_one": "专业语言学家",
        "third_two": "语言可供翻译",
        "third_three": "翻译准确率",
        "four_tit": "本地化服务",
        "four_main": "更好地为客户、患者和赞助商服务",
        "four_main_exp": "我们的专业人员可以本地化任何类型的印刷或数字内容，包括",
        "four_main_exp_one": "网站、应用程序和软件",
        "four_main_exp_two": "视频内容、字幕、图形和配音",
        "four_main_exp_three": "文件和市场营销材料",
        "four_main_exp_four": "在线学习和团队成员培训",
        "four_main_exp_five": "监管和合规性文件",
        "four_main_exp_six": "电子游戏及更多",
        "five_tit": "内容管理系统连接",
        "five_main": "自动化文件传输",
        "five_main_exp": "EMLions从客户的角度重新构想翻译，将多语种自动化直接嵌入内容系统和业务应用程序，使客户能够轻松地将任何内容发送给我们。从我们的 50 多个开箱即用的连接器中任选一个，提高您的文件传输效率和出版工作流程。",
        "five_btn": "了解更多",
        "six_tit": "网站代理",
        "six_main": "轻松翻译您的网站",
        "six_main_exp": "简化制作和管理英文网站或电子商务平台翻译版本的流程。使用 EMLions® Website Proxy，您网站的翻译版本始终与英文版本同步。此外，如果您缺乏托管翻译网站的资源，网站代理可帮助您镜像您的内容，并以您选择的语言显示。",
        "six_btn": "了解更多",
        "sevent_tit": "了解有关集成翻译工具的更多信息",
        "sevent_main": "以任何语言提供一致、高质量的内容，且不牺 牲准确性。您可以阅读我们的翻译和本地化手册，或选择时间与我们的专家讨论如何解决您的翻译难题。",
        "sevent_download_btn": "立即下载",
        "sevent_schedule_btn": "安排咨询",
        "eigth_tit": "EMLions 本地化服务",
        "eigth_one_tit": "人工翻译",
        "eigth_one_contents": "我们通过人性化的服务和对文化的理解，确保每一项工作都能传达您原始内容的含义。",
        "eigth_two_tit": "定制化",
        "eigth_two_contents": "我们提供最先进的技术和语言专业知识，以适应您不断变化的项目需求。",
        "eigth_three_tit": "100% 质量保证",
        "eigth_three_contents": "每个项目都由至少两名语言学家进行翻译、编辑和校对。",
        "eigth_four_tit": "准时交付",
        "eigth_four_contents": "我们从未错过任何一个上线日期。"
    },
    "integrated_translation": {
        "tit": "集成翻译工具",
        "main": "在保证准确性的情况下加快翻译速度",
        "schedule_btn": "安排咨询",
        "sec_tit": "让翻译技术触手可及",
        "sec_one": "无论您是要在全球范围内扩展营销计划、翻译机密的患者信息，还是要以新的语言提供教育材料，您都需要符合您的需求和预算的高质量翻译。使用正确的翻译技术可以简化流程中的许多步骤，使您能够在更短的时间内以更低的成本制作出准确的材料。",
        "sec_two": "我们提供的翻译技术可使整个流程尽可能直接、简便和安全。使用我们的端到端加密 EMLions® 翻译门户网站，可快速向我们的 3,500 多名语言专家提交项目。您还可以下载译文、查看项目状态并在线获取报告。最终为您的受众提供准确、一致的内容，让您和您的员工高枕无忧。",
        "third_one": "语言可供翻译",
        "third_two": "翻译准确率",
        "third_three": "按时交付翻译项目",
        "four_tit": "内容管理系统连接",
        "four_main": "自动化文件传输",
        "four_main_exp": "EMLions 从客户的角度重新构想翻译，将多语种自动化直接嵌入内容系统和业务应用程序，使我们的客户能够轻松地将任何内容发送给我们。从我们的 50 多个开箱即用的连接器中任选一个，提高您的文件传输效率和出版工作流程。",
        "four_btn": "了解更多",
        "five_tit": "网站代理",
        "five_main": "轻松翻译您的网站",
        "five_main_exp": "简化制作和管理英文网站或电子商务平台翻译版本的流程。使用 EMLions® Website Proxy，您网站的翻译版本始终与英文版本同步。此外，如果您缺乏托管翻译网站的资源，网站代理可帮助您镜像您的内容，并以您选择的语言显示。",
        "five_btn": "了解更多",
        "six_tit": "机器翻译",
        "six_main": "几分钟内即可获得翻译",
        "six_main_exp": "尽快翻译大型内容。借助 EMLions® 机器翻译，我们的人工智能驱动 \"最佳匹配 \"技术可在短时间内提供易于理解的内容。当您将内容与我们通过 ISO 认证的后期编辑流程相结合时，您可以放心，您的所有通信和交付成果都将是准确无误的。",
        "six_btn": "了解更多",
        "sevent_tit": "了解有关集成翻译工具的更多信息",
        "sevent_main": "以任何语言提供一致、高质量的内容，且不牺牲准确性。您可以阅读我们的翻译和本地化手册，或选择时间与我们的专家讨论如何解决您的翻译难题。",
        "sevent_download_btn": "立即下载",
        "sevent_schedule_btn": "安排咨询",
        "eigth_tit": "集成翻译工具",
        "eight_one_tit": "先进的技术",
        "eigth_one_contents": "我们通过开发新的本地化解决方案引领行业发展。",
        "eight_two_tit": "定制化",
        "eigth_two_contents": "我们提供尖端技术和语言专业知识，以适应您不断变化的项目需求。",
        "eight_three_tit": "100% 质量保证",
        "eigth_three_contents": "我们由行业专家组成的综合团队确保最高质量的结果。",
        "eight_four_tit": "准时交付",
        "eigth_four_contents": "我们的准时交货率为99.5%。"
    },
    "machine_translation": {
        "tit": "机器翻译",
        "main": "只需几秒钟即可翻译您的内容",
        "schedule_btn": "安排咨询",
        "quote_btn": "询价",
        "sec_tit": "通过自动化翻译节省时间和金钱",
        "sec_one": "全面控制翻译项目的时间和成本。机器翻译使用专业引擎即时翻译内容。为了以最快的速度取得成果，您可以实现流程的完全自动化，或者采用混合工作流程，由我们 3,500 多名专业语言学家为您的项目提供支持。",
        "sec_two": "我们的机器翻译使用人工智能提高翻译的准确性。您可以根据您的项目需求、预算和时间安排，定制您的后期编辑和审核流程。",
        "third_one": "可供翻译的语言",
        "third_two": "人工智能驱动的 \"最合适 \"引擎",
        "third_three": "可进行后期编辑的语言学家",
        "four_tit": "快速翻译",
        "four_main": "机器翻译适合您吗？",
        "four_main_exp": "机器翻译最适合在紧迫的时间内翻译大量内容。它非常适合低责任风险的内容，如客户服务沟通和博客文章。如果您需要处理法律、医疗或科学文件，或者需要进行更深入的情感分析，那么传统翻译服务的效果会更好。",
        "five_tit": "技术",
        "five_main": "通过强大的技术提高准确性",
        "five_main_exp": "EMLions® 机器翻译使用 40 多个领先引擎，以最大限度地提高准确性。它会自动为您的语言和内容类型寻找最佳选项，以确保您的信息获得最佳输出。利用我们的混合工作流程以及贵公司的翻译记忆库和词汇表，我们可以提高准确性并帮助您控制成本。",
        "six_tit": "EMLions机器翻译工具",
        "six_one_tit": "先进技术",
        "six_one_main": "我们基于人工智能的翻译中心为您的内容提供最适合的机器翻译。",
        "six_two_tit": "定制化",
        "six_two_main": "选择最适合您的内容需求的机器翻译工作流程。",
        "six_three_tit": "安全",
        "six_three_main": "您的宝贵和专有内容仍保留在我们的安全环境中。",
        "six_four_tit": "快速周转时间",
        "six_four_main": "使用我们的 MTPE 工作流程立即或更快地完成您的项目。",
        "sevent_tit": "了解有关翻译和本地化的更多信息",
        "sevent_main": "详细了解如何以任何语言和文化提供一致、高质量的内容 。您可以阅读我们的翻译和本地化手册，或选择时间与我们的专家交谈，了解如何解决您的翻译难题。",
        "sevent_download_btn": "立即下载",
        "sevent_schedule_btn": "安排咨询"
    },
    "elearning_translation": {
        "tit": "在线学习服务",
        "main": "提供超过 290 种语言的电子学习内容",
        "schedule_btn": "安排咨询",
        "quote_btn": "询价",
        "sec_tit": "提高您的培训投资回报",
        "sec_one": "全球各地的组织都将电子学习作为向分散各地的员工和客户提供教育和培训内容的一种高效、经济的方式。然而，未能正确翻译和本地化其电子学习内容的公司可能会限制其培训效果及其投资回报率。",
        "sec_two": "与我们的 3,500 多名专业语言学家合作，为全球受众准备您的电子学习课程。您可以信赖始终如一的高质量交付成果。此外，您还可以访问我们的端到端加密门户，上传材料、跟踪进度和检索翻译 — 确保每个项目的效率和完全安全。",
        "third_one": "专业语言学家",
        "third_two": "可供翻译的语言",
        "third_three": "翻译准确率",
        "four_tit": "在线学习服务",
        "four_main": "100% 保证内容质量",
        "four_main_exp": "作为语言服务行业的佼佼者，我们每年管理着 290 多种语言的数千个翻译和本地化项目。几十年来，我们在支持远程培训内容方面积累了丰富的经验，这些经验培养了我们在设计高效、易用的电子学习项目方面的专业知识。我们的内容质量得到 100% 的保证，99.5% 的客户将我们的翻译和本地化服务评为 \"良好或更好\"。",
        "five_tit": "技术",
        "five_main": "在线学习应用程序和支持",
        "five_main_exp": "93% 的财富 100 强公司依靠我们翻译和本地化专业内容，包括电子学习课程、培训材料、网络研讨会、电子书、入职培训以及系统和产品培训。此外，我们的专家团队还专门 为 SAP、Oracle、Salesforce 和其他平台提供 ERP 培训。",
        "six_tit": "全球专业知识",
        "six_main": "在全球范围内部署行业特定培训",
        "six_main_exp": "我们将确保您的组织的独特词汇和行业特定术语在全球范围内以 290 多种语言被理解。我们拥有 3,500 多名专业语言学家，在翻译行业中各种电子学习内容方面拥有丰富的经验，包括：",
        "six_main_one": "医疗保健",
        "six_main_two": "信息技术和软件运营服务",
        "six_main_three": "零售和电子商务",
        "six_main_four": "教育",
        "six_main_five": "金融服务",
        "six_main_six": "制药业",
        "six_main_sevent": "制造业",
        "six_main_eight": "建筑业及其他",
        "sevent_tit": "了解有关在线学习的更多信息",
        "sevent_main": "了解更多有关以 290 多种语言提供一致、高质量的在线学习内容的信息。您可以阅读我们的翻译和本地化手册，或选择一个时间与我们的专家讨论如何解决您的电子学习难题。",
        "sevent_download_btn": "立即下载",
        "sevent_schedule_btn": "安排咨询",
        "eight_tit": "在线学习服务",
        "eigth_one_tit": "全方位服务",
        "eigth_one_contents": "我们对您的学习内容进行端到端复制，以激活您的全球受众。",
        "eight_two_tit": "定制",
        "eigth_two_contents": "我们提供最先进的技术和语言专业知识，以适应您不断变化的项目需求。",
        "eight_three_tit": "100% 质量保证",
        "eigth_three_contents": "我们由行业专家组成的综合团队确保最高质量的结果。",
        "eight_four_tit": "准时交付",
        "eigth_four_contents": "我们从未错过上线日期。"
    },
    "website_translation": {
        "tit": "网站翻译",
        "main": "为全球受众本地化您的网站",
        "schedule_btn": "安排咨询",
        "quote_btn": "询价",
        "sec_tit": "将您的网站翻译成 290 多种语言",
        "sec_one": "您的网站内容在不断变化，您的消费者也需要用他们能理解的语言来浏览您的网站。不断更新网站内容本身就是一项艰巨的任务。当您还需要将其翻译成多种语言时，网站维护就会成为一项重大挑战，影响员工的工作效率和组织的盈利能力。",
        "sec_two": "使用 EMLions® 网站代理，您可以：",
        "sec_three": "翻译文档、营销材料、表格 、通讯和操作指南",
        "sec_four": "本地化网站、硬件、软件、游戏内容和在线学习",
        "sec_five": "自动翻译和本地化您的内容",
        "third_one": "专业语言学家",
        "third_two": "翻译准确率",
        "third_three": "内容质量有保证",
        "four_tit": "内容管理系统连接",
        "four_main": "自动化文件传输",
        "four_main_exp": "EMLions 从客户的角度重新构想了翻译，将多语言自动化直接嵌入内容系统和业务应用程序中，使我们的客户能够轻松地将任何内容发送给我们。从我们的 50 多个开箱即用的连接器中进行选择，以提高文件传输效率和 发布工作流程。",
        "four_btn": "了解更多",
        "five_tit": "机器翻译",
        "five_main": "它适合您吗？",
        "five_main_exp": "机器翻译最适合在紧迫的时间内翻译大量内容。它是低责任风险内容的理想选择，例如客户服务沟通和博客文章。如果您需要处理法律、医疗或科学文件，或者需要进行更深入的情感分析，您将从传统翻译服务中获得更好的结果。",
        "five_btn": "了解更多",
        "six_tit": "EMLions 网站翻译",
        "six_one_tit": "先进技术",
        "six_one_contents": "我们提供最先进的技术和语言专业知识，以适应您不断变化的网站项目需求。",
        "six_two_tit": "定制",
        "six_two_contents": "灵活应对任何网站复杂性和预算。",
        "six_three_tit": "100% 质量保证",
        "six_three_contents": "我们由行业专家组成的综合团队确保最高质量的结果。",
        "six_four_tit": "准时交付",
        "six_four_contents": "我们的综合行业专家团队可确保最高质量的成果。",
        "sevent_tit": "了解有关翻译和本地化的更多信息",
        "sevent_main": "了解更多有关以任何语言、针对任何文化提供一致的高质量网站内容的信息。您可以阅读我们的翻译和本地化手册，或选择一个时间与我们的专家讨论如何解决您的翻译难题。"
    },
    "translation_languages": {
        "tit": "翻译语言",
        "main": "提供 290 多种翻译和本地化语言",
        "schedule_btn": "安排咨询",
        "quote_btn": "询价",
        "sec_tit": "了解有关集成翻译工具的更多信息",
        "sec_main": "在不影响准确性的前提下，以任何语言提供一致的高质量内容。您可以阅读我们的翻译和本地化手册，或选择一个时间与我们的专家讨论如何解决您的翻译难题。",
        "sec_download_btn": "立即下载",
        "lang": {
            "Achi": "阿奇语",
            "Achinese": "亚齐语",
            "Acholi": "阿乔利语",
            "Afar": "阿法尔语",
            "Afghani": "阿富汗语",
            "Afrikaans": "南非荷兰语",
            "Akan": "阿肯语",
            "Akateko": "阿卡特科语",
            "Albanian": "阿尔巴尼亚语",
            "Amharic": "阿姆哈拉语",
            "Anuak": "阿努亚克语",
            "Apache": "阿帕切语",
            "Arabic": "阿拉伯语",
            "Arabic_Iraq": "阿拉伯语（伊拉克）",
            "Arabic_Juba": "阿拉伯语（朱巴）",
            "Arabic_Saudi_Arabia": "阿拉伯语（沙特阿拉伯）",
            "Arabic_Sudan": "阿拉伯语（苏丹）",
            "Arabic_Yemen": "阿拉伯语（也门）",
            "Arakanese": "阿拉干语",
            "Armenian": "亚美尼亚语",
            "Assamese": "阿萨姆语",
            "Assyrian": "亚述语",
            "Azeri": "阿塞拜疆语",
            "Azeri_Cyrillic": "阿塞拜疆语（西里尔文）",
            "Azeri_Latin": "阿塞拜疆语（拉丁文）",
            "Bajan": "巴巴多斯语",
            "Bajuni": "巴居尼语",
            "Bambara": "班巴拉语",
            "Basque": "巴斯克语",
            "Bassa": "巴萨语",
            "Behdini": "贝德尼语",
            "Beja": "贝贾语",
            "Belarusian": "白俄罗斯语",
            "Belgian": "比利时语",
            "Bemba": "贝姆巴语",
            "Bengali": "孟加拉语",
            "Berber": "柏柏尔语",
            "Bhojpuri": "博杰普尔语",
            "Bislama": "比斯拉马语",
            "Blackfoot_Canada": "黑脚语(加拿大)",
            "Bosnian": "波斯尼亚语",
            "Bosnian_Cyrillic": "波斯尼亚语（西里尔文）",
            "Bosnian_Latin": "波斯尼亚语（拉丁文）",
            "Braille": "盲文",
            "Bulgarian": "保加利亚语",
            "Burmese": "缅甸语",
            "Cambodian": "高棉语",
            "Cambodian_Pidgin_English": "柬埔寨混合英语",
            "Cantonese": "粤语",
            "Cape_Verdean_Creole": "佛得角克里奥尔语",
            "Catalan": "加泰罗尼亚语",
            "Cebuano": "宿务语",
            "Central_Alaskan_Yupik": "中阿拉斯加尤皮克语",
            "Chaldean": "卡尔迪亚语",
            "Chamorro": "查莫罗语",
            "Chavacano": "查瓦诺语",
            "Cherokee": "切诺基语",
            "Chichewa": "齐切瓦语",
            "Chin": "钦族语",
            "Chinese_Simplified": "简体中文",
            "Chinese_Traditional": "繁体中文(台湾)",
            "Chinese_Traditional_HK": "繁体中文(香港)",
            "Choctaw": "乔克托语",
            "Chui_Chow": "潮州话",
            "Chuj": "楚哈语",
            "Chuukese": "楚克语",
            "Cree_Canada": "克里语(加拿大)",
            "Croatian": "克罗地亚语",
            "Crow": "克罗族语",
            "Czech": "捷克语",
            "Dakota": "达科他语",
            "Danish": "丹麦语",
            "Dari": "达里语",
            "Dene": "德内语",
            "Dinka": "丁卡语",
            "Dutch": "荷兰语",
            "Dutch_Belgium": "荷兰语(比利时)",
            "Dyula": "朱拉语",
            "Dzongkha": "宗卡语",
            "Eastern_Mari": "东马里语",
            "Edo": "埃多语",
            "English_Australia": "英语 (澳大利亚)",
            "English_Belize": "英语 (伯利兹)",
            "English_Canada": "英语 (加拿大)",
            "English_Ireland": "英语 (爱尔兰)",
            "English_Jamaica": "英语 (牙买加)",
            "English_Liberia": "英语 (利比里亚)",
            "English_New_Zealand": "英语 (新西兰)",
            "English_Trinidad_Tobago": "英语 (特立尼达和多巴哥)",
            "English_UK": "英语 (英国)",
            "English_US": "英语 (美国)",
            "English_Zimbabwe": "英语 (津巴布韦)",
            "Estonian": "爱沙尼亚语",
            "Ewe": "埃维语",
            "Falam": "法兰语",
            "Fante": "芳特语",
            "Farsi": "波斯语",
            "Fijian": "斐济语",
            "Filipino": "菲律宾语",
            "Finnish": "芬兰语",
            "Flemish": "弗拉芒语",
            "Formosan": "高山语",
            "French_Belgium": "法语 (比利时)",
            "French_Burkina_Faso": "法语 (布基纳)",
            "French_Burundi": "法语 (布隆迪)",
            "French_Cameroon": "法语 (喀麦隆)",
            "French_Canada": "法语 (加拿大)",
            "French_Congo": "法语 (刚果)",
            "French_France": "法语 (法国)",
            "French_Guinea": "法语 (几内亚)",
            "French_Ivory_Coast": "法语 (科特迪瓦)",
            "French_Madagascar": "法语 (马达加斯加)",
            "French_Morocco": "法语 (摩洛哥)",
            "French_Switzerland": "法语 (瑞士)",
            "French_Tunisia": "法语 (突尼斯)",
            "French_West_Africa": "法语 (西非)",
            "Frisian": "弗里西亚语",
            "Fukienese": "福建话",
            "Fula": "富拉语（西非）",
            "Fulani": "富拉尼语",
            "Fulfulde": "富拉语",
            "Fuzhou": "福州话",
            "Ga": "加语",
            "Gaddang": "加当语",
            "Galician": "加利西亚语",
            "Garifuna": "加里福那语",
            "Georgian": "格鲁吉亚语",
            "German": "德语",
            "German_Austria": "德语 （奥地利）",
            "German_Belgium": "德语（比利时）",
            "German_Liechtenstein": "德语（列支敦士登）",
            "German_Pennsylvania": "德语（宾夕法尼亚）",
            "German_Switzerland": "德语（瑞士）",
            "Greek": "希腊语",
            "Guarani": "瓜拉尼语",
            "Gujarati": "古吉拉特语",
            "Guyanese": "圭亚那语",
            "Haitian_Creole": "海地克里奥尔语",
            "Hakka": "客家话",
            "Hakka_China": "客家话（广东）",
            "Hakka_Taiwan": "客家话（台湾）",
            "Harari": "哈拉里语",
            "Haryanvi": "哈里亚纳语",
            "Hausa": "豪萨语",
            "Hawaiian": "夏威夷语",
            "Hebrew": "希伯来语",
            "Hindi": "印地语",
            "Hmong": "苗语",
            "Hokkien": "福建话",
            "Hopi": "霍皮语",
            "Hunanese": "湖南话",
            "Hungarian": "匈牙利语",
            "Ibanag": "伊巴纳格语",
            "Ibibio": "伊比比奥语",
            "Ibo": "伊波语",
            "Icelandic": "冰岛语",
            "Igbo": "伊博语",
            "Ilocano": "伊洛卡诺语",
            "Ilonggo": "伊隆戈语",
            "Indonesian_Bahasa_Indonesia": "印尼语",
            "Inuktitut": "因纽特语",
            "Inupiak": "因纽皮克语",
            "Iraqi": "伊拉克阿拉伯语",
            "Irish_Gaelic": "爱尔兰盖尔语",
            "Italian": "意大利语",
            "Italian_Switzerland": "意大利语(瑞士)",
            "Ixil": "伊希尔语",
            "Jakartanese": "雅加达话",
            "Jamaican_Patois": "牙买加克里奥尔语",
            "Japanese": "日语",
            "Jarai": "加莱语",
            "Javanese": "爪哇语",
            "Jju": "朱朱语",
            "Kachin": "克钦语",
            "Kanjobal": "坎霍巴尔语",
            "Kannada": "卡纳达语",
            "Kaqchikel": "卡克切尔语",
            "Karen": "克伦语",
            "Karenni": "克伦尼语",
            "Karenni_Kayah_Li": "克伦尼语（克耶里文）",
            "Karenni_Latin": "克伦尼语（拉丁文）",
            "Karenni_Myanmar": "克伦尼语（缅甸文）",
            "Kashmiri": "克什米尔语",
            "Kayah": "克耶语",
            "Kayah_Latin": "克耶语 （拉丁文）",
            "Kayah_Myanmar": "克耶语（缅甸文）",
            "Kazakh": "哈萨克语",
            "Kekchi": "凯克奇语",
            "Khmer": "高棉语",
            "Kibembe": "基贝姆贝语",
            "Kifulero": "基富列罗语",
            "Kikuyu": "吉库尤语",
            "Kinyamulenge": "尼亚穆伦盖语",
            "Kinyarwanda": "基尼亚卢旺达语",
            "Kirundi": "基隆迪语",
            "Kiswahili": "斯瓦希里语",
            "Kituba": "基图巴语",
            "Kizigua": "吉吉瓜语",
            "Konkani": "孔卡尼语",
            "Korean": "韩语",
            "Kosovan": "科索沃语",
            "Kosraean": "科斯雷安语",
            "Kpelle": "克佩莱语",
            "Krahn": "克兰语",
            "Krio": "克里奥尔语",
            "Kunama": "库纳马语",
            "Kurdish": "库尔德语",
            "Kurdish_Arabic": "库尔德语（阿拉伯文）",
            "Kurdish_Cyrillic": "库尔德语（西里尔文）",
            "Kurdish_Latin": "库尔德语（拉丁文）",
            "Kurmanji": "库尔曼吉语",
            "Kwakwala": "夸克拉语",
            "Kwanyama": "宽亚马语",
            "Kyrgyz": "吉尔吉斯语",
            "Lai_Hakha": "赖哈卡语",
            "Lakota": "拉科塔语",
            "Lao": "老挝语",
            "Latin": "拉丁语",
            "Latvian": "拉脱维亚语",
            "Lingala": "林加拉语",
            "Lithuanian": "立陶宛语",
            "Luganda": "卢干达语",
            "Luo": "卢奥语",
            "Luxembourgish": "卢森堡语",
            "Maay_Maay": "马伊马伊语",
            "Macedonian": "马其顿语",
            "Madi_Uganda": "马迪语",
            "Malagasy": "马尔加什语",
            "Malay": "马来语",
            "Malay_Jawi": "马来语（爪夷文）",
            "Malayalam": "马拉雅拉姆语",
            "Maltese": "马耳他语",
            "Mam": "马姆语",
            "Mandarin": "普通话",
            "Mandingo": "曼丁哥语",
            "Mandinka": "曼丁卡语",
            "Mandinka_Arabic": "曼丁卡语（阿拉伯文 ）",
            "Mandinka_Latin": "曼丁卡语（拉丁文）",
            "Manipuri": "曼尼普尔语",
            "Mankon": "曼孔语",
            "Maori": "毛利语",
            "Marathi": "马拉地语",
            "Marshallese": "马绍尔语",
            "Mauritian_Creole": "毛里求斯克里奥尔语",
            "Memoni": "梅莫尼语",
            "Mende": "门德语",
            "Mi_kmaq": "米克玛克语",
            "Michif": "密希菲语",
            "Mien": "苗语",
            "Mina": "米纳语",
            "Mixteco": "密斯特克语",
            "Mnong": "木农语",
            "Mon": "梵语",
            "Mongolian": "蒙古语",
            "Mongolian_Cyrillic": "蒙古语（西里尔文）",
            "Montenegrin": "黑山语",
            "Montenegrin_Cyrillic": "黑山语（西里尔文）",
            "Montenegrin_Latin": "黑山语（拉丁文）",
            "Mossi": "莫西语",
            "Nahuatl": "纳瓦特尔语",
            "Navajo": "纳瓦霍语",
            "Nepali": "尼泊尔语",
            "Nigerian_Pidgin_English": "尼日利亚混合英语",
            "Nordic": "古冰岛语",
            "Norwegian": "挪威语",
            "Nuer": "努埃尔语",
            "Ojibway": "奥吉布瓦语",
            "Oriya": "奥里亚语",
            "Oromo": "奥罗莫语",
            "Palauan": "帕劳语",
            "Pampangan": "潘帕恩加语",
            "Papiamento": "帕皮阿门托语",
            "Pashai": "帕夏伊语",
            "Pashto": "普什图语",
            "Patois": "克里奥尔语",
            "Persian": "波斯语",
            "Pidgin_English": "洋泾浜英语",
            "Pima": "皮马语",
            "Pinyin": "拼音",
            "Pohnpeian": "波纳佩语",
            "Polish": "波兰语",
            "Portuguese_Brazil": "葡萄牙语（巴西）",
            "Portuguese_Guinea_Bissau": "葡萄牙语（几内亚比绍）",
            "Portuguese_Portugal": "葡萄牙语（葡萄牙）",
            "Punjabi": "旁遮普语",
            "Pushtu": "普什图语",
            "Quechua": "克丘亚语",
            "Queens_English_UK": "标准英语(英国)",
            "Quiche": "基切语",
            "Rade": "拉黎语",
            "Rohingya_Arabic": "罗辛亚语（阿拉伯文）",
            "Rohingya_Latin": "罗辛亚语（拉丁文）",
            "Romani": "罗姆语",
            "Romanian": "罗马尼亚语",
            "Romansh": "罗曼什语",
            "Russian": "俄语",
            "Saint_Lucian_Creole": "圣卢西亚克里奥尔语",
            "Samoan": "萨摩亚语",
            "Sango": "桑戈语",
            "Sanskrit": "梵文",
            "Saraiki": "萨拉伊基语",
            "Scottish_Gaelic": "苏格兰盖尔语",
            "Serbian": "塞尔维亚语",
            "Serbian_Cyrillic": "塞尔维亚语（西里尔文）",
            "Serbian_Latin": "塞尔维亚语（拉丁文）",
            "Sesotho": "塞索托语",
            "Setswana": "塞茨瓦纳语",
            "Shanghainese": "上海话",
            "Shona": "绍纳语",
            "Shuswap": "舒瓦普语",
            "Siberian_Yup_ik": "西 伯利亚尤皮克语",
            "Sicilian": "西西里语",
            "Sidaama": "希达玛语",
            "Sindhi": "信德语",
            "Sinhala": "僧伽罗语",
            "Siswati": "斯瓦蒂语",
            "Slovak": "斯洛伐克语",
            "Slovenian": "斯洛文尼亚语",
            "Somali": "索马里语",
            "Soninke": "索宁克语",
            "Sorani": "索拉尼库尔德语",
            "Spanish_Argentina": "西班牙语(阿根廷)",
            "Spanish_Chile": "西班牙语(智利)",
            "Spanish_Colombia": "西班牙语(哥伦比亚)",
            "Spanish_Ecuador": "西班牙语(厄瓜多尔)",
            "Spanish_International": "西班牙语(国际)",
            "Spanish_Latin_America": "西班牙 语(拉丁美洲)",
            "Spanish_Mexico": "西班牙语(墨西哥)",
            "Spanish_Panama": "西班牙语(巴拿马)",
            "Spanish_Puerto_Rico": "西班牙语(波多黎各)",
            "Spanish_Spain": "西班牙 语(西班牙)",
            "Spanish_US": "西班牙语(美国)",
            "Spanish_Venezuela": "西班牙语(委内瑞拉)",
            "Stoney_Canada": "斯托尼语(加拿大)",
            "Sudanese": "苏丹语",
            "Sundanese": "巽他语",
            "Swahili": "斯瓦希里语",
            "Swedish": "瑞典语",
            "Swedish_Finland": "瑞典语（芬兰）",
            "Sylheti": "锡尔赫蒂语",
            "Syriac": "叙利亚语",
            "Szechuan": "四川话",
            "Tagalog": "他加禄语",
            "Tahitian": "塔希提语",
            "Taiwanese": "台湾话",
            "Tajik": "塔吉克语",
            "Tamil": "泰米尔语",
            "Tatar": "鞑靼语",
            "Tedim_Chin": "特迪姆钦语",
            "Telugu": "泰卢 固语",
            "Temne": "特姆尼语",
            "Tetum": "特图姆语",
            "Thai": "泰语",
            "Tibetan": "藏语",
            "Tigre": "提格雷语",
            "Tigrinya": "提格利尼亚语",
            "Tlingit": "特林吉特语",
            "Toishanese": "台山话",
            "Tongan": "汤加语",
            "Trique": "特里克语",
            "Tsuut_ina": "苏蒂纳语",
            "Turkish": "土耳其语",
            "Turkmen": "土库曼语",
            "Twi": "特威语",
            "Uighur": "维吾尔语",
            "Ukrainian": "乌克兰语",
            "Ulster_Scots": "阿尔斯特-苏格兰语",
            "Urdu": "乌尔都语",
            "Uzbek": "乌兹别克语",
            "Uzbek_Cyrillic": "乌兹别克语（西里尔文）",
            "Uzbek_Latin": "乌兹别克语（拉丁文）",
            "Vietnamese": "越南语",
            "Visayan": "比萨亚语",
            "Welsh": "威尔士语",
            "Wolof": "沃洛夫语",
            "Xhosa": "科萨语",
            "Yapese": "雅浦语",
            "Yaqui": "亚奇语",
            "Yavapai": "亚瓦派语",
            "Yiddish_other": "意第绪语（其他）",
            "Yiddish_US": "意第绪语（美国）",
            "Yoruba": "约鲁巴语",
            "Yucateco": "尤卡特克语",
            "Yupik": "尤皮克语",
            "Zarma": "札尔马语",
            "Zou": "佐语",
            "Zulu": "祖鲁语"
        },
        "third_info_one": "可能还有其他语言和方言可供选择。如果您对语言可用性有疑问",
        "third_info_two": "请联系我们"
    },
    "document_clarification": {
        "tit": "文档澄清说明",
        "main": "通过 EMLions Clarity 的所有接触点进行语言访问",
        "schedule_btn": "安排咨询",
        "sec_one": "EMLions 是全球最大的语言接入公司。除了提供 290 多种语言的翻译解决方案外，我们的 Clarity 团队还确保通信首先是简单、清晰和可理解的 \"纯英语\"。",
        "sec_two": "我们很自豪能在世界各个角落架起沟通的桥梁。不过，我们也要考虑到，讲英语的人也需要更好地获取他们用英语消费的信息。考虑到美国人的平均阅读能力只有 7 年级学生的水平，使用纯英语进行清晰、更易懂、更简洁的交流势在必行。因此，我们认为 \"纯英语 \"本身就是一种有价值的语言。",
        "third_tit": "EMLIONS CLARITY",
        "third_main": "清晰简单的沟通",
        "third_main_one": "如果文档太长、组织混乱、充斥着行话和内部语言，那么无论翻译得多么专业，都很难甚至无法用任何语言理解。",
        "third_main_two": "另一方面，当数字和印刷文件剔除了非必要的内容，并在编写和设计上让目标受众一目了然时，它们在任何语言中都会易于理解。这本身就能提供一个机会，增加您与客户之间的联系和信任。",
        "third_main_three": "EMLions Clarity 服务与其他通俗语言写作服务完全不同。EMLions® 不会孤立地处理少数几个单独的文档，而是重新设计整个文档系统，帮助您制作更精简、更透明、更具成本效益的内容。"
    },
    "document_simplification": {
        "tit": "文档摘要",
        "main": "用通俗易懂的语言和清晰的表达方式传达给受众",
        "quote_btn": "询价",
        "sec_tit": "什么是文档摘要？",
        "sec_main": "客户、患者和选民喜欢简单、直接的沟通方式，并会做出回应。当他们第一次就明白您在说什么时，他们就更有可能再次阅读。这正是我们的文档摘要服务可以帮助您实现的目标。我们提供宏观简化和微观简化服务，以提高理解能力并降低翻译成本。",
        "macro": "宏观简化",
        "third_one_tit": "简化整个文档系统",
        "third_one_main": "全面重新设计您的文档系统，以获得更精简、更有效的内容，通过提高可读性和跨接触点的理解来提高满意度。完善您的文档系统还可以节省成本并提高性能。",
        "third_two_tit": "一次处理数百份文件",
        "third_two_main_one": "简化大字印刷和数字文档系统",
        "third_two_main_two": "在文件中修改其复杂的内容，让文件内容变得清晰且易于使用",
        "third_two_main_three": "大小合适的文档库",
        "third_two_main_four": "促进沟通流程和效率",
        "third_three_tit": "节省资 金并满足合规标准",
        "third_three_main": "宏观简化项目可以提高质量并降低生产和印刷成本，从而收回成本。它们还可以改善客户、患者和选民的体验，提高满意度，并帮 助确保您满足合规性要求。",
        "third_four_tit": "如何判断你是否需要它",
        "third_four_main": "如果您的组织遇到以下问题，请与我们的代表之一讨论宏观简化：",
        "third_four_main_one": "信息过时且不一致",
        "third_four_main_two": "内容冗余且过多",
        "third_four_main_three": "难以接触到特定受众",
        "third_four_main_four": "沟通混乱导致客户致电",
        "third_four_main_five": "缺乏有效的治理和质量控制",
        "micro": "微观简化",
        "four_one_tit": "简化个人文档和通信",
        "four_one_main": "微观简化的操作对于任何组织来说都是无价之宝。该过程包括：",
        "four_one_main_one": "内容分析及改进建议",
        "four_one_main_two": "删除不必要的和多余的信息",
        "four_one_main_three": "使用简易英语来传达核心信息",
        "four_one_main_four": "创建易于阅读且有吸引力的新文案",
        "four_two_tit": "提供更好的客户体验",
        "four_two_main": "对营销材料进行微观简化可以大大提高其有效性，并有助于确保您符合行业合规标准。简单明了的材料更有可能将潜在客户转化为客户，并建立长期忠诚度。",
        "four_three_tit": "改善成果并降低成本",
        "four_three_main": "微观简化对于提供保险、金融服务或医疗保健等复杂服务的机构尤其有用。简化的表格、账单或醒目的小册子可以对您的成果和成本产生巨大的影响。",
        "five_tit": "让我们谈谈解决方案",
        "five_main": "我们为您提供专业的语言协助，旨在满足您的需求和预算。立即索取报价或与我们的代表交谈，了解您的语言访问挑战并讨论解决方案。",
        "schedule_btn": "安排咨询"
    },
    "audio_transcription": {
        "tit": "音频转录",
        "main": "将您的录音变成宝贵的资产",
        "schedule_btn": "安排咨询",
        "quote_btn": "询价",
        "sec_tit": "使用音频转录来促进您的业务",
        "sec_one": "当今的企业不仅在印刷品和数字通信中收集大量信息，还在录音和录像中收集大量信息。这些资产包含宝贵的信息，可以改善客户服务、员工培训和营销推广。",
        "sec_two": "我们可以帮您省去转录音频和视频的工作。我们提供 290 多种语言的音频到文本转录、配音和翻译服务。请放心，我们的 3,500 多名语言专家将以高质量的输出、全面的校对和准时的交付完成您的转录项目。",
        "transcription": "转录",
        "third_one_tit": "提高性能和生产力",
        "third_one_main": "许多公司都会对客户服务电话进行录音，以便进行质量控制，但听录音可能会耗费大量时间，影响工作效率。您的录音可以为您提供新的见解，让您了解自己为客户、患者和选民提供服务的情况。此外，转录还能让呼叫中心经理更快地了解员工的工作表现，并及时进行指导。",
        "third_two_tit": "提高所有人的可及性",
        "third_two_main": "转录录音可以让聋人或重听客户和员工更容易获取您的内容。如果您的组织为营销、客户支持或员工培训目的制作音频，将这些录音转化为印刷或数字内容将使您所服务的每个人都能获得这些内容。",
        "third_three_tit": "吸引全球观众",
        "third_three_main": "世界各地的受众都渴望获得高效的内容。因此，不要止步于将录音转化为印刷品和数字通信内容。我们拥有 3,500 多名语言专家，他们可以将单个音频转录翻译成 290 多种语言，使您录制的每段录音的影响力成倍增长。",
        "captioning": "字幕制作",
        "four_one_tit": "满足日益增长的视频需求",
        "four_one_main": "目前，视频内容占互联网总流量的 65%，许多观众喜欢有字幕的视频。有些人是聋人或重听人 (DHH)，有些人则喜欢在公共场合观看无声视频。如果您在视频内容中添加字幕，就能满足数百万人的需求。",
        "four_two_tit": "更好地服务不同人群",
        "four_two_main": "为视频内容添加字幕有助于确保您",
        "four_two_main_one": "提高对残疾人士或英语水平有限的人的可及性",
        "four_two_main_two": "提高观众将内容看完的几率，从而提高参与度",
        "four_two_main_three": "吸引新的受众和不断增长的人群",
        "four_three_tit": "信赖我们的专业语言学家",
        "four_three_main": "我们的专业人员都是字幕方面的专家。我们已经帮助数以千计的企业、医疗保健组织、政府机构等扩大了他们的影响力，同时使他们所服务的人群更具包容性和可及性。",
        "five_tit": "让我们谈谈解决方案",
        "five_main": "我们随时准备为您提供专业的音频转录服务，以满足您的需求和预算。您可以阅读我们的翻译和本地化手册，或选择一个时间与我们的专家讨论如何解决您的语言访问挑战。",
        "five_download_btn": "立刻下载"
    },
    "alternative_format": {
        "tit": "替代格式转录",
        "main": "与视障人群建立联系",
        "schedule_btn": "安排咨询",
        "quote_btn": "询价",
        "sec_tit": "确保平等地使用您的产品和服务",
        "sec_one": "有 3200 多万美国成年人表示他们曾经历过严重的视力丧失或失明。他们可能是您的客户、患者、选民或员工。当您以盲文、大字体或其他替代格式提供书面材料来满足他们的需求时，您就确保了平等获取的机会，并取得了更好的效果。",
        "sec_two": "我们可以转录您的内容，以便对盲人和视障人士产生最大的影响。我们的先进技术使我们能够快速、经济高效地完成您的项目，同时保持最高水平的质量。",
        "third_tit": "盲文和大字印刷版",
        "third_main": "满足视障人士的需求",
        "third_main_exp": "我们可以帮助您提供多种语言的盲文和大字印刷版书面材料。此外，大字印刷版可以增强您的数字材料和网站。我们的专业编辑团队将为您提供高质 量的输出。",
        "four_tit": "音频转录",
        "four_main": "转录音频以改善您的服务和业务",
        "four_main_exp": "您的录音可能包含一些重要信息，可以改善您的客户服务和员工培训。此外，它们还是新营销材料的基础。我们提供音频到文本的转录、配音和 290 多种语言的翻译服务，让您不再为转录录音而烦恼。",
        "four_btn": "了解更多",
        "five_tit": "让我们谈谈解决方案",
        "five_main": "我们根据您的项目和预算提供高质量的替代格式转录服务。请与我们的专家联系，了解如何解决您在语言无障碍方面遇到的挑战，并确保所有人都能无障碍地使用。",
        "six_tit": "利用先进的翻译技术提高可访问性",
        "six_one_tit": "音频转录",
        "six_one_contents": "使用书面内容和信息的音频版本更好地服务盲人和视障人士。",
        "six_two_tit": "数字无障碍信息系统",
        "six_two_contents": "通过导出数字无障碍信息系统 (DAISY) 格式，提高内容的可用性和无障碍性。",
        "six_three_tit": "MOON",
        "six_three_contents": "将您的内容转录到 Moon 中，使其对视障受众更有用、更有影响力。",
        "six_four_tit": "电子文本",
        "six_four_contents": "将您的内容转换为电子文本，使软件能够放大、大声朗读并在可刷新的盲文显示器上重现。",
        "six_five_tit": "轻松阅读",
        "six_five_contents": "将文本和图像相结合，为有学习障碍的人提供更易于阅读的内容。"
    },
    "translate": {
        "tit": "翻译服务",
        "main": "通过全面理解建立更牢固的关系",
        "main_exp": "获得所需的专业知识，以便在任何规模下清晰地进行沟通。",
        "schedule_btn": "安排咨询",
        "sec_tit": "提高 290 多种语言的翻译质量",
        "sec_one": "6700 多万美国居民在家中使用英语以外的语言。用英语能力有限 (LEP) 的人所选择的语言与他们对话不仅是良好的做法，也是发展的关键。研究表明，92% 的人会购买以其首选语言销售的产品，91% 的人会重复购买。",
        "sec_two": "大多数翻译供应商在员工数量、专业知识和质量方面都存在限制，因此您不得不另寻他处来完成某些项目。与其与小众服务网络合作，不如与拥有 25 年以上经验、3500 多名专业语言学家和经过多项 ISO 认证的优质标准提供商合作。",
        "sec_three": "EMLions® 翻译和本地化专家支持 290 多种语言的各种内容和规模的项目。我们的创新技术可提高工作流程效率，同时提供最快的服务。EMLions 随时准备帮 助您：",
        "sec_four": "翻译文件、营销材料、表格、通讯和操作指南",
        "sec_five": "本地化网站、硬件、软件、游戏内容和在线学习",
        "sec_six": "自动翻译和本地化您的内容",
        "third_one": "专业语言学家等待为您的组织提供服务",
        "third_two": "EMLions 的内容翻译准确率",
        "third_three": "EMLions 翻译项目的准时交付率",
        "four_one_tit": "文件翻译",
        "four_one_contents": "获得根据您的预期用途、受众和保质期定制的高质量文档翻译。",
        "four_two_tit": "本地化服务",
        "four_two_contents": "根据特定地区的语言和文化敏感性对印刷和数字内容进行本地化，以便您的目标受众获得自然的体验。",
        "four_three_tit": "集成翻译工具",
        "four_three_contents": "利用我们的创新技术消除翻译障碍并减少人为错误，从而更快地获得结果。",
        "four_four_tit": "机器翻译",
        "four_four_contents": "EMLions 的人工智能“最佳拟合”技术以最低的成本快速交付大规模、低责任的内容。",
        "four_five_tit": "在线学习服务",
        "four_five_contents": "将您的电子学习内容重新转换为不同的语言，以提高组织生产力。",
        "four_six_tit": "网站翻译",
        "four_six_contents": "网站代理提供一种无需干预的方式来制作和管理您的英文网站的翻译版本，或者使英文版本和翻译版本始终保持同步。",
        "four_sevent_tit": "翻译语言",
        "four_sevent_contents": "详细了解我们的翻译人员对 290 多种语言所提供的专业知识和质量。",
        "five_tit": "让我们谈谈解决方案",
        "five_main": "我们的专业语言学家热忱为您提供符合您预算的翻译和本地化服务。现在就为您的下一个项目申请估价，或与我们的 EMLions 翻译解决方案专家交流。",
        "quote_btn": "询价"
    },
    "other_translate": {
        "tit": "其他语言服务",
        "main": "让每一个字都有价值",
        "main_exp": "帮助您的组织清晰简洁地沟通，以最大限度地发挥所有文档和内容的影响力。",
        "quote_btn": "询价",
        "sec_tit": "使用通俗易懂的语言进行有效沟通",
        "sec_one": "美国成年人的平均阅读水平为七年级。大多数读者在面对复杂的内容、不熟悉的术语和行业术语时会不知所措。如果您的组织使用过于复杂的语言，您将面临造成不良客户体验、造成混乱和增加成本的风险。",
        "sec_two": "作为全球领先的语言访问合作伙伴，我们提供 290 多种语言的翻译解决方案。如今，有效沟通的黄金标准是简明英语，我们认为它本身就是一种语言。我们可以帮助您简化文档，以便您传达信息并吸引所有受众。",
        "third_one_tit": "文档澄清说明",
        "third_one_contents": "我们重写面向公众的材料，以便它们更容易理解，并且翻译成其他语言的成本更低。",
        "third_two_tit": "文档摘要",
        "third_two_contents": "我们确定对所有受众有用的核心内容，并简化它以产生更大的影响。",
        "third_three_tit": "音频转录",
        "third_three_contents": "我们可以转录您的视频并将其翻译成您选择的语言。此外，我们还能寻找和录制配音演员。",
        "third_four_tit": "替代格式转录",
        "third_four_contents": "我们将内容转录为盲文，包括英语和西班牙语。",
        "four_tit": "让我们谈谈解决方案",
        "four_main": "我们为您提供专业的语言协助，以满足您的需求和预算。现在就索取报价，或与我们的代表联系，了解您在语言访问方面面临的挑战并讨论解决方案。",
        "schedule_btn": "安排咨询"
    },
    "footer": {
        "interpretation": "口译",
        "testing_and_training": "测试与培训",
        "industries": "行业",
        "careers": "招贤纳士",
        "resources": "网站资源",
        "customer_service": "客户服务",
        "contact_info": "联系我们",
        "corporate_info": "© EMLions Solutions 2024. 保留所有权利。",
        "sitemap": "网站地图",
        "privacy_policy": "隐私政策",
        "cookie_policy": "Cookie 政策",
        "global_ethics_hotline": "举报中心"
    }
}